import React, { useState } from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import { GroupInvitedMember, SessionClient } from '@shout-sdk/client-sdk';
import MoreVertIcon from '@material-ui/icons/MoreVert';

interface IProps {
    invites: GroupInvitedMember[],
    groupId: number
}

export const InvitesList: React.FC<IProps> = props => {

    const [showOptions, setShowOptions] = useState('');
    const { invites, groupId } = props;

    const revokeInvite = async (email: string) => {
        const uasClient = await SessionClient.getUasClient();
        const res = await uasClient.delete(`/api/1/groups/${groupId}/invites/${email}`);
        if (res.ok()) { window.location.reload(); }
    }

    return (
        <List style={{ maxHeight: 400, overflow: 'auto' }}>
            {invites.map((invite, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <Divider />}
                    <ListItem>
                        <ListItemText primary={invite.emailAddress} />
                        <ListItemSecondaryAction>
                            <IconButton id={`${invite.emailAddress}`} onClick={() => setShowOptions(invite.emailAddress)}>
                                <MoreVertIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Menu
                        anchorEl={document.getElementById(`${invite.emailAddress}`)}
                        open={showOptions === invite.emailAddress}
                        onClose={() => setShowOptions('')}
                    >
                        <MenuItem onClick={() => revokeInvite(invite.emailAddress)}>
                            Revoke Invite
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            ))}
        </List>
    )
}