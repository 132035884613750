import React, { Dispatch, SetStateAction } from 'react';
import { Grid, TextField, MenuItem } from '@material-ui/core';
import { OrganizationAddress, RegisterOrganizationRequest } from '@shout-sdk/client-sdk';
import countries from '../../../app/countries.json';

interface IProps {
    user: RegisterOrganizationRequest,
    setUser: Dispatch<SetStateAction<RegisterOrganizationRequest>>
}

export const AddressForm: React.FC<IProps> = props => {
    const { user, setUser } = props;
    const { organizationAddress } = user;

    return (
        <div>
            <TextField
                label="Address Line 1"
                margin="normal"
                variant="outlined"
                value={organizationAddress.addressLineOne}
                onChange={ev => setUser({ ...user, organizationAddress: { ...organizationAddress, addressLineOne: ev.target.value } })}
                fullWidth
                required
            />
            <TextField
                label="Address Line 2 (optional)"
                margin="normal"
                variant="outlined"
                value={organizationAddress.addressLineTwo ? organizationAddress.addressLineTwo : undefined}
                onChange={ev => setUser({ ...user, organizationAddress: { ...organizationAddress, addressLineTwo: ev.target.value } })}
                fullWidth
            />
            <Grid container spacing={2}>
                <Grid md={6} xs={12} item>
                    <TextField
                        label="City"
                        margin="normal"
                        variant="outlined"
                        value={organizationAddress.city ? organizationAddress.city : undefined}
                        onChange={ev => setUser({ ...user, organizationAddress: { ...organizationAddress, city: ev.target.value } })}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid md={6} xs={12} item>
                    <TextField
                        label="Post Code"
                        margin="normal"
                        variant="outlined"
                        value={organizationAddress.postCode}
                        onChange={ev => setUser({ ...user, organizationAddress: { ...organizationAddress, postCode: ev.target.value } })}
                        fullWidth
                        required
                    />
                </Grid>
            </Grid>
            <TextField
                select
                label="Country"
                margin="normal"
                variant="outlined"
                value={organizationAddress.countryCode}
                onChange={ev => setUser({ ...user, organizationAddress: { ...organizationAddress, countryCode: ev.target.value } })}
                fullWidth
                required
            >
                {countries.map(country => {
                    if (country.CountryCode !== '')
                        return <MenuItem key={country.CountryCode} value={country.CountryCode}>
                            {country.Name}
                        </MenuItem>
                    return undefined;
                })}
            </TextField>
        </div>
    )
}