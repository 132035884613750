import { FileUploadDetails, SessionClient, CdnServiceUrls } from '@shout-sdk/client-sdk';
import { Store } from 'auth';

export const uploadImage = async (image: File, imageUploadDetails: FileUploadDetails, identifier: string, onBehalf?: string) => {
    const cdnClient = await SessionClient.getCdnClient();
    const formData = new FormData();
    formData.append("Identifier", identifier);
    formData.append("TicketId", imageUploadDetails.ticketId.toString());
    formData.append(image.name, image);
    const { userId, sessionId, deviceId } = Store.get().session!;
    let header: any = {
        'Authorization': `ShoutV1 ${userId}:${sessionId}:${deviceId}`
    }
    if (onBehalf) {
        header["X-Shout-On-Behalf-Of"] = onBehalf;
    }
    const imgResponse = await cdnClient.uploadFile(CdnServiceUrls.File.Upload,
        header,
        formData);
    return imgResponse.ok();
}

export const areDatesOnSameDay = (start: Date, end: Date) => {
    return start.getFullYear() === end.getFullYear() &&
        start.getMonth() === end.getMonth() &&
        start.getDate() === end.getDate();
}