import React, { useState } from 'react';
import { ReturnedStatus, UserProfileBasicInformation, SessionClient } from '@shout-sdk/client-sdk';
import { Card, CardHeader, Avatar, IconButton, CardMedia, CardContent, Typography, CardActions, Menu, MenuItem, Grow, ButtonBase } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CommentIcon from '@material-ui/icons/Comment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PersonIcon from '@material-ui/icons/Person';
import { CurrentUser } from 'auth';
import clsx from 'clsx';
import { useRef } from 'react';
import { useStyles } from './StatusCardStyle';
import { StatusCardCarousel } from './StatusCardCarousel';
import { withRouter } from 'react-router';

interface IProps {
    status: ReturnedStatus,
    user?: UserProfileBasicInformation | null,
    disabled?: boolean,
    history: any
}

const StatusCardComponent: React.FC<any> = props => {
    const classes = useStyles();
    const [showOptions, setShowOptions] = useState(false);
    const dotRef = useRef(null);

    const { status, user, disabled } = props;
    const isUserDefined = typeof user !== 'undefined';

    const getRemainingTime = (): string => {
        const seconds = status.expirationTimeInSeconds;
        if (seconds === 0) { return 'Expired'; }
        if (status.ageInSeconds === 0) {
            const date = new Date(status.publicationTime ?? "");
            return date.toLocaleString();
        }

        const d = Math.floor(seconds / (3600 * 24));
        if (d > 0) { return d + (d === 1 ? " day " : " days ") + 'left' }
        const h = Math.floor(seconds % (3600 * 24) / 3600);
        if (h > 0) { return h + (h === 1 ? " hour " : " hours ") + 'left' }
        const m = Math.floor(seconds % 3600 / 60);
        if (m > 0) { return m + (m === 1 ? " minute " : " minutes ") + 'left' }

        return seconds + ' seconds left';
    }

    const deleteStatus = async () => {
        const lcsClient = await SessionClient.getLcsClient();
        const res = await lcsClient.post<any>('/api/1/status/delete', { statusId: status.statusId });
        if (res.ok()) { window.location.reload() }
    }

    const profilePic = isUserDefined ? user!.thumbnailUrl! : CurrentUser.getProfile().thumbnailUrl!;

    return (
        <Grow in>
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar className={classes.avatar}
                            src={profilePic}>
                            {!profilePic && <PersonIcon />}
                        </Avatar>
                    }
                    action={
                        <IconButton ref={dotRef} onClick={() => setShowOptions(true)} disabled={disabled}>
                            <MoreVertIcon />
                        </IconButton>
                    }
                    classes={{
                        subheader: clsx(status.expirationTimeInSeconds === 0 && classes.expired,
                            status.ageInSeconds === 0 && classes.scheduled, classes.active)
                    }}
                    title={isUserDefined ? user!.name : CurrentUser.getProfile().name}
                    subheader={getRemainingTime()}
                />
                <Menu
                    anchorEl={dotRef.current}
                    open={showOptions}
                    onClose={() => setShowOptions(false)}
                >
                    <MenuItem onClick={deleteStatus}>
                        Delete Status
                    </MenuItem>
                </Menu>
                <ButtonBase style={{ display: 'block' }} className={classes.card} disabled={disabled}
                    onClick={() => !disabled && props.history.push({ pathname: '/statuses/detail', state: { status } })}>
                    {status.images?.length === 1 &&
                        <CardMedia
                            className={classes.media}
                            image={status.images[0]}
                        />
                    }
                    {(status.images?.length ?? 0) > 1 &&
                        <StatusCardCarousel imgs={status.images} />
                    }
                    <CardContent className={classes.contentText}>
                        <Typography variant="body2" color="textSecondary">
                            {status.translations ? status.translations[0].message : ""}
                        </Typography>
                    </CardContent>
                    <CardActions className={classes.stats}>
                        <FavoriteIcon className={classes.statIcon} /> {status.registeredAccountImpressions?.numberOfLikes}
                        <CommentIcon className={classes.statIcon} /> {status.registeredAccountImpressions?.numberOfComments}
                    </CardActions>
                </ButtonBase>
            </Card>
        </Grow>
    )
}

// @ts-ignore
export const StatusCard = withRouter(StatusCardComponent);