import React, { useState, useEffect, useRef } from 'react';
import { Container, Box, Typography, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core';
import { Map, LoadingButton, ErrorSnackbar, useUploadImage } from '../common';
import { AddGeofenceRequest, GeofenceSpread, GeoLocation, GeofencesResponse, Geofence, SessionClient, GeofenceServiceUrls, SelectedContentCategoryDetails, GeofenceTranslation } from '@shout-sdk/client-sdk';
import { LngLat } from 'mapbox-gl';
import { EventCategorySelect } from '../events/create/EventCategorySelect';
import { uploadImage } from 'components/app/helpers';
import { withRouter } from 'react-router';

const initialState = new AddGeofenceRequest();
initialState.translations = [new GeofenceTranslation()];
initialState.translations[0].language = 'eng';
initialState.startDate = new Date().toISOString();

const CreateGeofence: React.FC<any> = props => {

    const [geofence, setGeofence] = useState(initialState);
    const [parents, setParents] = useState<Geofence[]>([]);
    const [image, setImage, imgErr] = useUploadImage(500);
    const [loading, setLoading] = useState(false);
    const imgRef = useRef<any>(null);

    useEffect(() => {
        if (geofence.defaultCoordinates.latitude !== 0 || geofence.defaultCoordinates.longitude !== 0) {
            const fetchParents = async () => {
                const gfsClient = await SessionClient.getGfsClient();
                const res = await gfsClient.post<GeofencesResponse>(GeofenceServiceUrls.Geofence.Inside, { location: geofence.defaultCoordinates })
                if (res.ok()) {
                    setParents(res.data!.results!);
                }
            }
            fetchParents();
        }
    }, [geofence.defaultCoordinates]);

    const handleTitle = (txt: string) => {
        const translations = [...geofence.translations];
        translations[0].title = txt;
        setGeofence({ ...geofence, translations });
    }

    const saveImage = (files: any) => {
        if (files) {
            setImage(files[0]);
        }
    }

    const handleGeofence = (coords: []) => {
        const data: GeoLocation[] = [];
        coords.forEach(point => {
            data.push(new GeoLocation(point[1], point[0]));
        })
        setGeofence({ ...geofence, boundaryPoints: data });
    }

    const handleMarker = (lnglat: LngLat) => {
        setGeofence({ ...geofence, defaultCoordinates: new GeoLocation(lnglat.lat, lnglat.lng) });
    }

    const handleBoundaries = (boundaries: string) => {
        console.log(boundaries);
        if (boundaries.startsWith('[')) {
            const coords = JSON.parse(boundaries);
            console.log(coords);
            const data: GeoLocation[] = [];
            coords[0].forEach((point: any) => {
                data.push(new GeoLocation(point[1], point[0]));
            });
            setGeofence({ ...geofence, boundaryPoints: data });
            Map.displayPolygon(coords, geofence.startDate);
        }
    }

    const createGeofence = async () => {
        const gfsClient = await SessionClient.getGfsClient();
        const res = await gfsClient.post<any>('/api/1/geofence/add', geofence);
        setLoading(true);
        if (res.ok()) {
            // if image set, create ticket and upload image
            if (image !== null) {
                const imgResponse = await gfsClient.post<any>('api/1/geofence/change_image', { id: res.data.geofenceId });
                if (imgResponse.ok()) {
                    await uploadImage(image, imgResponse.data.imageUploadDetails, res.data.geofenceId.toString());
                }
            }
            if (props.location.state) {
                props.history.push('/pending');
            }
            props.history.push('/geofences');
        }
    }

    const isBtnDisabled = (): boolean => {
        if (geofence.defaultCoordinates.latitude === 0 && geofence.defaultCoordinates.longitude === 0) { return true }
        else if (geofence.boundaryPoints.length < 4) { return true }
        return geofence.translations[0]?.title?.trim() === '';
    }

    return (
        <Container maxWidth="lg">
            <Box marginY={4}>
                <Typography color='primary' variant='h2'>
                    Create New Geofence
                </Typography>
                <Box marginY={4}>
                    <Map height={400}
                        lnglat={[geofence.defaultCoordinates.longitude, geofence.defaultCoordinates.latitude]}
                        moveMarker={handleMarker}
                        addGeofence={handleGeofence}
                        enableDraw dblClick />
                    <Grid container>
                        <Grid md={6} xs={12} item>
                            <TextField
                                label='Geofence Name'
                                variant='outlined'
                                margin='normal'
                                value={geofence.translations[0].title}
                                onChange={ev => handleTitle(ev.target.value)}
                                fullWidth />
                            <FormControl variant="outlined" margin="normal" fullWidth>
                                <InputLabel>
                                    Select a Spread Option
                                </InputLabel>
                                <Select labelWidth={130} value={geofence.geofenceSpread} onChange={(ev: any) => setGeofence({ ...geofence, geofenceSpread: ev.target.value })}>
                                    {Object.values(GeofenceSpread).filter(spread => typeof spread === 'number').map((spread: any) => {
                                        return <MenuItem key={spread} value={spread}>{GeofenceSpread[spread]}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            {parents.length > 0 &&
                                <FormControl variant="outlined" margin="normal" fullWidth>
                                    <InputLabel>
                                        Select a Parent Geofence
                                    </InputLabel>
                                    <Select labelWidth={130} value={geofence.parentGeofenceId} onChange={(ev: any) => setGeofence({ ...geofence, parentGeofenceId: ev.target.value })}>
                                        {parents.map(geofence => (
                                            <MenuItem key={geofence.geofenceNumberId} value={geofence.geofenceNumberId}>{geofence.translations![0].title}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            }
                            <Box textAlign='center' marginY={4}>
                                {image && <img style={{ height: 200, width: '100%', objectFit: 'cover', marginBottom: 16 }} src={URL.createObjectURL(image)} />}
                                <Button variant='outlined' color='primary' onClick={() => imgRef.current!.click()}>ADD IMAGE</Button>
                                <input type="file" accept="image/png, image/jpeg" ref={imgRef} style={{ display: 'none' }}
                                    onChange={ev => saveImage(ev.target.files)} />
                            </Box>
                        </Grid>
                        <Grid md={6} xs={12} item>
                            <EventCategorySelect onChange={(restrictedContentCategories: SelectedContentCategoryDetails[] | null) => setGeofence({ ...geofence, restrictedContentCategories })} />
                        </Grid>
                    </Grid>
                    <TextField label='Import coordinates (optional)' variant='outlined' margin='normal' placeholder="[[[0.210307, 51.485877], [0.211056, 51.485871..."
                        onChange={ev => handleBoundaries(ev.target.value)} fullWidth />
                    <Box textAlign='center' marginY={2}>
                        <LoadingButton variant='contained' color='primary'
                            loading={loading}
                            onClick={createGeofence}
                            disabled={isBtnDisabled()}>
                            Create Geofence
                        </LoadingButton>
                    </Box>
                </Box>
                <ErrorSnackbar open={imgErr.length > 0} err={imgErr} />
            </Box>
        </Container>
    )
}

export const CreateGeofenceView = withRouter(CreateGeofence);