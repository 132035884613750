import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'center'
    },
    phoneCode: {
        '&:before': {
            borderBottom: 'none'
        }
    },
    phoneInput: {
        margin: theme.spacing(4, 0),
    },
    codeInputBox: {
        margin: theme.spacing(2, 0),
        '& input': {
            textAlign: 'center',
        }
    },
    codeInput: {
        fontSize: 24,
        fontWeight: 300
    },
    loader: {
        margin: theme.spacing(4)
    }
}))