import React, { useEffect } from 'react';
import { makeStyles, Box, TextField, Typography, Button } from '@material-ui/core';
import { PasswordInput } from '../PasswordInput';
import { RegisterGuestForIndividualRequest, StringUtils, SessionClient, CryptographyUtilities, UserAccountServiceUrls, SessionCredentials } from '@shout-sdk/client-sdk';
import { DatePicker } from '@material-ui/pickers';
import { Auth, Store } from 'auth';
import { MultiChipSelect } from './MultiChipSelect';
import { GenderSelect } from './GenderSelect';

const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'left'
    },
    button: {
        margin: theme.spacing(4, 0, 4),
        float: 'right'
    }
}))

const minDateOfBirth = new Date();
minDateOfBirth.setFullYear(minDateOfBirth.getFullYear() - 13);
const initialGuestData = new RegisterGuestForIndividualRequest();
initialGuestData.dateOfBirth = minDateOfBirth.toISOString();
initialGuestData.deviceId = StringUtils.generateUUID();
initialGuestData.languages = ['eng'];

export const IndividualForm: React.FC<any> = props => {
    const classes = useStyles();
    const [guestData, setGuestData] = React.useState<RegisterGuestForIndividualRequest>(initialGuestData);
    const { upgradeData, setUpgradeData } = props;

    // set Email token
    useEffect(() => {
        const { registrationInfo } = Store.get();
        if (registrationInfo) {
            setGuestData({ ...guestData, emailVerificationToken: registrationInfo.emailToken });
        }
    }, [])

    const next = async (event: any) => {
        event.preventDefault();
        const uasHttpClient = await SessionClient.getUasHttpClient();
        // hash password
        const passwordHash = CryptographyUtilities.hash(guestData.password);

        // create guest account
        const guestResponse = await uasHttpClient.post<any>(UserAccountServiceUrls.Registration.CreateIndividualGuestAccount,
            { ...guestData, password: passwordHash }
        );
        if (guestResponse.ok()) {
            // store name if user refreshes page
            Store.update({ registrationInfo: { ...Store.get().registrationInfo, fullName: props.upgradeData.fullName } });
            // login user
            const authResponse = await Auth.authorize(new SessionCredentials(passwordHash, null, guestData.password, guestResponse.data.userId));
            if (authResponse.success) {
                props.next();
            }
        }
    }

    return (
        <Box className={classes.container}>
            <Typography variant="h6">Create Account</Typography>
            <form onSubmit={next}>
                <TextField
                    label="Full Name"
                    margin="normal"
                    variant="outlined"
                    value={upgradeData.fullName}
                    onChange={ev => setUpgradeData({ ...upgradeData, fullName: ev.target.value })}
                    fullWidth
                    required
                />
                <TextField
                    label="Email"
                    margin="normal"
                    variant="outlined"
                    value={upgradeData.emailAddress}
                    onChange={ev => setUpgradeData({ ...upgradeData, emailAddress: ev.target.value })}
                    fullWidth
                    disabled
                />
                <PasswordInput value={guestData.password} onChange={(ev: any) => setGuestData({ ...guestData, password: ev.target.value })} />
                <GenderSelect value={guestData.gender} onChange={(gender: number) => setGuestData({ ...guestData, gender })} />
                <MultiChipSelect langs={guestData.languages} setLangs={(languages: any[]) => setGuestData({ ...guestData, languages })} />
                <DatePicker
                    margin="normal"
                    label="Date of birth"
                    inputVariant="outlined"
                    format="DD/MM/YYYY"
                    value={new Date(guestData.dateOfBirth)}
                    onChange={date => setGuestData({ ...guestData, dateOfBirth: date!.toISOString() })}
                    maxDate={minDateOfBirth}
                    fullWidth
                />
                <Button className={classes.button} variant="contained" color="primary" type="submit">
                    Next
                </Button>
            </form>
        </Box>
    )
}