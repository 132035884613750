import React from 'react';
import { makeStyles, Card, CardMedia, CardContent, Typography, CardActions, Button, ButtonBase, Grow } from '@material-ui/core';
import { withRouter } from 'react-router';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
        width: '100%',
        textAlign: 'left'
    },
    media: {
        height: 150,
    },
    button: {
        maxWidth: 345,
        width: '100%'
    },
    bottom: {
        padding: theme.spacing(0, 2, 2, 2)
    }
}));

export const GeofenceCardComponent: React.FC<any> = props => {
    const classes = useStyles();
    const { geofence } = props;

    const navigateToEvent = () => {
        props.history.push({ pathname: '/events/detail', state: { event: geofence } });
    }

    return (
        <Grow in>
            <ButtonBase className={classes.button} onClick={navigateToEvent} disabled={!geofence.endDate}>
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.media}
                        image={geofence.imageUrl}
                    />
                    <CardContent>
                        <Typography color="secondary" gutterBottom variant="h5" noWrap>
                            {geofence.translations![0].title}
                        </Typography>
                        {geofence.endDate &&
                            <React.Fragment>
                                <Typography color='textSecondary'>
                                    {new Date(geofence.startDate).toLocaleString([], { weekday: 'long', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                </Typography>
                            </React.Fragment>
                        }
                    </CardContent>
                    <CardActions className={classes.bottom}>
                        <Typography variant="button" color="primary">
                            {geofence.activeStatusCount} Statuses
                    </Typography>
                    </CardActions>
                </Card>
            </ButtonBase>
        </Grow>

    )
}

export const GeofenceCard = withRouter(GeofenceCardComponent);