import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Chip, Avatar, Typography } from '@material-ui/core';
import { ContentCategory, SessionClient, SelectedContentCategorySubcategories } from '@shout-sdk/client-sdk';
import { categoryImgs } from './CategoryImages';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    chipImg: {
        backgroundColor: theme.palette.primary.main,
        height: 32,
        width: 32,
        marginLeft: '0px !important',
        padding: 4
    },
    chip: {
        margin: theme.spacing(1, 1),
        border: '1px solid white',
        borderColor: theme.palette.primary.main
    }
}))

export const EventCategorySelect: React.FC<any> = props => {
    const classes = useStyles();
    const [categories, setCategories] = useState<ContentCategory[]>([]);
    const [selected, setSelected] = useState<ContentCategory[]>([]);

    useEffect(() => {
        const fetchAllCategories = async () => {
            const lcsClient = await SessionClient.getLcsClient();
            const res = await lcsClient.get<any>('/api/1/content_category/get_content_categories');
            if (res.ok()) {
                setCategories(res.data.data);
                if (props.preSelected) {
                    setSelected(res.data.data.filter((cat: any) => props.preSelected.includes(cat.id)));
                } else {
                    setSelected(res.data.data);
                }
            }
        }
        fetchAllCategories();
    }, [])

    useEffect(() => {
        if (selected.length < categories.length) {
            const eventCategories: SelectedContentCategorySubcategories[] = selected.map(cat => ({
                booleanOrOptionsSetOne: cat.booleanOrOptionsSetOne ? cat.booleanOrOptionsSetOne.map(opt => opt.id) : [],
                booleanOrOptionsSetTwo: cat.booleanOrOptionsSetTwo ? cat.booleanOrOptionsSetTwo.map(opt => opt.id) : [],
                booleanAndOptions: cat.booleanAndOptions ? cat.booleanAndOptions.map(opt => opt.id) : [],
                id: cat.id,
                subcategories: cat.subcategories?.map(cat => cat.id) ?? null
            }));
            props.onChange(eventCategories);
        } else {
            props.onChange(null);
        }

    }, [selected])

    const handleClick = (cat: ContentCategory) => {
        const arrCopy = [...selected];
        if (selected.includes(cat)) {
            // return if only one category left to remove, prevents no categories selected
            if (arrCopy.length === 1) { return }
            const index = arrCopy.indexOf(cat);
            arrCopy.splice(index, 1);
        } else {
            arrCopy.push(cat);
        }
        setSelected(arrCopy);
    }

    return (
        <Box marginY={2}>
            <Box textAlign="center">
                <Typography color="textSecondary" gutterBottom>Click to deselect status categories you want to be excluded.</Typography>
            </Box>
            <Box className={classes.container}>
                {categories.map((cat, index) => (
                    <Chip key={index}
                        label={cat.name}
                        color='primary'
                        className={classes.chip}
                        avatar={<Avatar className={classes.chipImg} src={categoryImgs[index]} />}
                        variant={selected.includes(cat) ? 'default' : 'outlined'}
                        onClick={() => handleClick(cat)}
                        clickable />
                ))}
            </Box>
        </Box>
    )
}