import React from 'react';
import { Box, Button } from '@material-ui/core';
import CSVReader from 'react-csv-reader';
import { emailRegex } from 'components/app/constants';

export const ImportCsv: React.FC<any> = props => {

    const { emails, setEmails } = props;

    const handleCSV = (csvData: string[][]) => {
        // look for email header in csv
        const emailIndex = csvData[0].findIndex((elem => elem === 'email' || elem === 'e-mail'));
        // if found only validate email column
        if (emailIndex !== -1) {
            const emailList = csvData.map(elem => elem[emailIndex]);
            validateAndSaveEmails(emailList.concat(emails));
        } else {
            // else filter all items that include an @ symbol and then call validation function
            let itemList: string[] = [];
            for (const row of csvData) {
                const email = row.filter((elem: string) => elem.includes('@'));
                itemList = itemList.concat(email);
            }
            validateAndSaveEmails(itemList.concat(emails));
        }
    }

    const validateAndSaveEmails = (values: string[]) => {
        // check against regex
        const filteredValues = values.filter(value => {
            return emailRegex.test(value.trim());
        });
        // transform concatenated array to unique set, so there are no duplicates
        // @ts-ignore
        const uniqueList = [...new Set(filteredValues)];
        setEmails(uniqueList);
    }

    return (
        <Box marginY={2}>
            <Button variant='outlined' color='primary' onClick={() => document.getElementById('csv-input')!.click()}>
                import .csv file
            </Button>
            <div style={{ display: 'none' }}>
                <CSVReader
                    inputId='csv-input'
                    onFileLoaded={handleCSV}
                />
            </div>
        </Box>

    )
}