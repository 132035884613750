import React, { useState, useEffect } from 'react';
import { Grid, Typography, TextField, makeStyles, Popper, List, ListItem, ListItemAvatar, Avatar, ListItemText, Card, Divider, Box, Link } from '@material-ui/core';
import { FormSection } from './FormSection';
import SearchIcon from '@material-ui/icons/Search';
import { OrganizationInformation, SessionClient, ShoutClient, GeoLocation } from '@shout-sdk/client-sdk';
import { EventOwnerCard } from './EventOwnerCard';
import BusinessIcon from '@material-ui/icons/Business';
import { CurrentUser } from 'auth';

const useStyles = makeStyles(theme => ({
    searchIcon: {
        color: theme.palette.grey[400],
        marginRight: theme.spacing(1)
    },
    orgImage: {
        backgroundColor: theme.palette.primary.main
    },
    list: {
        maxHeight: 300,
        overflow: 'auto'
    },
    listItem: {
        padding: theme.spacing(1, 2),
        '& span': {
            fontSize: 16
        }
    }
}))

let lcsClient: ShoutClient;
const location = new GeoLocation();

export const EventOwnerSearch: React.FC<any> = props => {
    const classes = useStyles();
    const [searchText, setText] = useState<string>('');
    const [organizations, setOrgs] = useState<OrganizationInformation[]>([]);
    const [selected, setSelected] = useState<OrganizationInformation | null>(null);

    useEffect(() => {
        SessionClient.getLcsClient().then(client => lcsClient = client);
    }, [])

    useEffect(() => {
        if (lcsClient && searchText.length > 0) {
            fetchOrganizations();
        } else {
            setOrgs([]);
        }
    }, [searchText]);

    useEffect(() => {
        if (selected) {
            props.onChange(selected.userId);
        } else {
            props.onChange(CurrentUser.getProfile().userId);
        }
    }, [selected])

    const fetchOrganizations = async () => {
        const res = await lcsClient.post<any>('/api/1/search/organizations', {
            searchTerm: searchText,
            location
        })
        if (res.ok()) {
            setOrgs(res.data.organizations.splice(0, 10));
        }
    }

    const handleClick = (org: OrganizationInformation) => {
        setText('');
        setOrgs([]);
        setSelected(org);
    }

    return (
        <FormSection title='Geofence Owner (optional)'>
            <Grid spacing={4} container>
                <Grid md={6} xs={12} item>
                    <Typography color='textSecondary'>
                        You can choose another account to manage this event.
                        That account will have full ownership over the event geofence.
                    </Typography>
                    <TextField
                        id='org_search'
                        variant='outlined'
                        label='Search Organizations'
                        margin='normal'
                        InputProps={{
                            startAdornment: <SearchIcon className={classes.searchIcon} />
                        }}
                        value={searchText}
                        onChange={ev => setText(ev.target.value)}
                        fullWidth
                    />
                    {selected &&
                        <Typography>
                            <Link href='#' onClick={() => setSelected(null)}>Reset Owner</Link>
                        </Typography>
                    }
                    <Popper open={organizations.length > 0} anchorEl={document.getElementById('org_search')} placement='bottom-start'>
                        <Card>
                            <List className={classes.list} dense>
                                {organizations.map((org, index) => (
                                    <Box key={org.organizationId}>
                                        {index > 0 && <Divider />}
                                        <ListItem className={classes.listItem} onClick={() => handleClick(org)} button>
                                            <ListItemAvatar>
                                                <Avatar className={classes.orgImage} src={org.thumbnailUrl!}>
                                                    {org.thumbnailUrl === null && <BusinessIcon />}
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText primary={org.name} />
                                        </ListItem>
                                    </Box>
                                ))}
                            </List>
                        </Card>
                    </Popper>
                </Grid>
                <Grid md={6} xs={12} item>
                    <EventOwnerCard org={selected} />
                </Grid>
            </Grid>
        </FormSection>
    )
}