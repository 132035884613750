import React, { useState, useEffect } from 'react';
import { Box, Card, makeStyles, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { categoryImgs } from './CategoryImages';
import { AddEventGeofenceRequest, Geofence, SessionClient, GeofenceServiceUrls, ShoutClient, UserAccountServiceUrls } from '@shout-sdk/client-sdk';
import clsx from 'clsx';
import { CurrentUser } from 'auth';
import BusinessIcon from '@material-ui/icons/Business';
import { FormSection } from './FormSection';

const useStyles = makeStyles(theme => ({
    card: {
        width: 400,
        maxWidth: '100%',
        position: 'relative',
        margin: '0 auto'
    },
    header: {
        height: 70,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.grey[200],
        color: theme.palette.grey[500]
    },
    checkbox: {
        '& > span': {
            fontSize: 12
        }
    },
    catContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    category: {
        backgroundColor: theme.palette.primary.main,
        height: 32,
        width: 32,
        borderRadius: '50%',
        display: 'inline',
        marginRight: theme.spacing(1)
    },
    catImg: {
        margin: 6
    },
    deselected: {
        backgroundColor: theme.palette.grey[500]
    },
    profileImg: {
        position: 'absolute',
        width: 50,
        height: 50,
        backgroundColor: theme.palette.secondary.main,
        left: 'calc(50% - 25px)',
        top: 30,
        borderRadius: '50%',
        boxShadow: '0 0.5px 4px 0px #33393b',
        backgroundSize: 'contain',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.contrastText
    }
}));

let uasClient: ShoutClient;

export const EventCardPreview: React.FC<any> = props => {
    const classes = useStyles();
    const [profileImg, setProfileImg] = useState<string | null>(CurrentUser.getProfile().thumbnailUrl);
    const event: AddEventGeofenceRequest = props.event;
    const { image } = props;

    useEffect(() => {
        SessionClient.getUasClient().then(client => uasClient = client);
    }, [])

    // fetch and update profile img everytime owner changes
    useEffect(() => {
        if (uasClient && event.ownerId !== null) {
            setProfileImgById(event.ownerId);
        }
    }, [event.ownerId])

    const isSelected = (id: number) => {
        return event.contentCategories ? event.contentCategories.some(cat => cat.id === id) : true;
    }

    const setProfileImgById = async (userId: number) => {
        const res = await uasClient.post<any>(UserAccountServiceUrls.UserProfile.GetPublicInformation, { userId, emailAddress: null });
        if (res.ok()) {
            setProfileImg(res.data.profileInformation.thumbnailUrl);
        }
    }

    return (
        <FormSection title="Event Card Preview">
            <Card className={classes.card}>
                <Box style={{ backgroundImage: `url(${image})` }} className={classes.header} />
                <Box className={classes.profileImg} style={{ backgroundImage: `url(${profileImg})` }}>
                    {profileImg === null && <BusinessIcon />}
                </Box>
                <Box padding={2}>
                    <Typography variant="subtitle1" gutterBottom>Welcome to {event.translations[0].title ? event.translations[0].title : 'Event Name'}</Typography>
                    <FormControlLabel className={classes.checkbox} control={<Checkbox color="primary" disabled />} label="Show content from outside" />
                    <Box className={classes.catContainer}>
                        {categoryImgs.map((cat, index) => (
                            <Box key={cat} className={clsx(classes.category, !isSelected(index + 1) && classes.deselected)}>
                                <img className={classes.catImg} src={cat} alt="" />
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Card>
        </FormSection>

    )
}