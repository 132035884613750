import React from 'react';
import clsx from 'clsx';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, useMediaQuery, Avatar, Typography, ButtonBase, Divider } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logo from '../../../../assets/logo.svg';
import { NavItems, StaffItems } from './SidebarItems';
import { withRouter } from 'react-router';
import { useStyles } from './SidebarStyle';
import { CurrentUser, Auth } from 'auth';
import PersonIcon from '@material-ui/icons/Person';

const SideNav: React.FC<any> = props => {
    const classes = useStyles();
    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('md'));

    const logout = () => {
        Auth.terminate().then(() => props.history.push('/'));
    }

    return (
        <Drawer
            variant={isMobile ? "temporary" : "permanent"}
            classes={{
                paper: clsx(classes.drawerPaper, !props.open && classes.drawerPaperClose),
            }}
            onClose={props.close}
            open={props.open}
        >
            <img src={logo} className={classes.logo} alt="" />
            <List>
                {NavItems.map(item => {
                    const isActive = props.location.pathname.startsWith(item.path);

                    return (
                        <ListItem button key={item.title} onClick={() => props.history.push(item.path)} className={clsx(isActive && classes.active)}>
                            <ListItemIcon className={clsx(classes.listIcon, isActive && classes.active)}>
                                <item.icon />
                            </ListItemIcon>
                            <ListItemText primary={item.title} />
                        </ListItem>
                    )
                })}
            </List>
            {CurrentUser.isStaff() &&
                <List>
                    <Divider className={classes.divider} />
                    {StaffItems.map(item => {
                        const isActive = props.location.pathname.startsWith(item.path);

                        return (
                            <ListItem button key={item.title} onClick={() => props.history.push(item.path)} className={clsx(isActive && classes.active)}>
                                <ListItemIcon className={clsx(classes.listIcon, isActive && classes.active)}>
                                    <item.icon />
                                </ListItemIcon>
                                <ListItemText primary={item.title} />
                            </ListItem>
                        )
                    })}
                </List>
            }

            <ButtonBase className={classes.userContainer} onClick={() => props.history.push({ pathname: '/profile', state: { userId: CurrentUser.getProfile().userId } })}>
                <Avatar src={CurrentUser.getProfile().profileImageUrl!} className={classes.avatar}>
                    {!CurrentUser.getProfile().profileImageUrl && <PersonIcon fontSize='large' />}
                </Avatar>
                <Typography align="center">{CurrentUser.getProfile().name}</Typography>
            </ButtonBase>
            <ListItem button className={classes.logout} onClick={logout}>
                <ListItemIcon className={classes.listIcon}>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Log out" />
            </ListItem>
        </Drawer>
    )
}

export const Sidebar = withRouter(SideNav)