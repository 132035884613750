import React, { useState, useEffect } from 'react';
import { makeStyles, Tabs, Tab, InputLabel, Box, Typography } from '@material-ui/core';
import { ExclusiveSelect } from '../StatusCategory/ExclusiveSelect';
import { AddStatusRequest, StatusTargetAudience } from '@shout-sdk/client-sdk';
import { ExpirationPicker } from './ExpirationPicker';
import { TargetAudiencePicker } from './TargetAudiencePicker';
import { SchedulePicker } from './SchedulePicker';
import { dateOptions } from 'components/app/constants';

interface IProps {
    status: AddStatusRequest,
    setStatus: React.Dispatch<React.SetStateAction<AddStatusRequest>>,
    eventDates: Date[]
}

const useStyles = makeStyles(theme => ({
    expirationContainer: {
        border: '1px solid grey',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.grey[400],
    }
}));

const expirationPresets = [
    { name: '1 week', id: 604800 },
    { name: '1 day', id: 86400 },
    { name: '1 hour', id: 3600 },
    { name: '5 minutes', id: 300 }
];

export const StatusOptions: React.FC<IProps> = props => {
    const classes = useStyles();
    const { status, setStatus, eventDates } = props;
    const isFutureEvent = eventDates.length > 0
    const [tab, setTab] = useState(isFutureEvent ? 1 : 0);

    // reset when switching to post now
    useEffect(() => {
        if (tab === 0) {
            setStatus({
                ...status,
                targetAudience: { ...status.targetAudience, males: true, females: true },
                expirationTimeInSeconds: 3600,
                start: null
            });
        } else if (tab === 1) {
            setStatus({ ...status, 
                targetAudience: { ...status.targetAudience, males: true, females: true }, expirationTimeInSeconds: 0 })
        }
    }, [tab])

    return (
        <div>
            <Tabs
                value={tab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={(ev, value) => setTab(value)}>
                <Tab label="Post Now" disabled={isFutureEvent} />
                <Tab label="Schedule Status" />
            </Tabs>
            {tab === 0 &&
                <Box textAlign="left" marginY={4}>
                    <InputLabel>Expiration Time</InputLabel>
                    <ExclusiveSelect options={expirationPresets} selected={status.expirationTimeInSeconds}
                        onChange={(value: any) => setStatus({ ...status, expirationTimeInSeconds: value })} />
                    <Box className={classes.expirationContainer} marginY={1}>
                        <ExpirationPicker expirationTime={status.expirationTimeInSeconds}
                            onChange={(seconds: number) => setStatus({ ...status, expirationTimeInSeconds: seconds })} />
                    </Box>
                </Box>
            }
            {tab === 1 &&
                <Box textAlign="left" marginY={4}>
                    {isFutureEvent &&
                        <Typography>Event Start/End: {eventDates[0].toLocaleString([], dateOptions)} - {eventDates[1].toLocaleString([], dateOptions)}</Typography>
                    }
                    <SchedulePicker status={status} setStatus={setStatus} />
                </Box>
            }
            <Box textAlign="left" marginY={4}>
                <InputLabel>Target Audience</InputLabel>
                <TargetAudiencePicker onChange={(targetAudience: StatusTargetAudience) => setStatus({ ...status, targetAudience })} />
            </Box>
        </div>
    )
}