import { Store } from './store';
import { CurrentUser } from './currentUser';
import { BooleanResponse, SessionClient, SessionCredentials } from '@shout-sdk/client-sdk';


export class Auth {

	public static isAuthorized() {
		return Store.get().session !== undefined;
	}

	public static async authorize(session: SessionCredentials): Promise<BooleanResponse> {

		const sClient = SessionClient.getInstance();
		const sessionResult: string = await sClient.startNew(session);
		if (session.emailAddress !== null && session.emailAddress!.trim() === '') {
			return new BooleanResponse(false, ['Please enter credentials!']);
		}
		if (sessionResult !== '') {
			if(sessionResult.startsWith('EmailAddress')) { 
				return new BooleanResponse(false, ['Invalid email address!']);
			 }
			return new BooleanResponse(false, [sessionResult]);
		}

		Store.update({ session: sClient, serviceInfo: SessionClient.serviceInfo });

		if (!await CurrentUser.set()) {
			return new BooleanResponse(false, ['Failed to set CurrentUser']);
		}

		return new BooleanResponse(true, null);
	}

	public static async terminate(): Promise<boolean> {
		let success = true;

		try {
			await SessionClient.getInstance().terminate();
		}
		catch (err) {
			success = false;
		}

		Store.clear();

		return success;
	}

}
