import React from 'react';
import { makeStyles, Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        border: '1px solid grey',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.grey[400],
        padding: theme.spacing(2, 0),
    },
    chip: {
        margin: theme.spacing(1)
    }
}))

export const PillSelect: React.FC<any> = (props) => {
    const classes = useStyles();
    const { options } = props;
    const [selected, select] = React.useState<any>([]);

    React.useEffect(() => {
        // callback for parent, if array is empty return null
        selected.length === 0 ? props.onChange(null) : props.onChange(selected)
    }, [selected]);

    const handleClick = (id: number) => {
        const arrCopy = [...selected];
        // add or remove item
        selected.includes(id) ? arrCopy.splice(selected.indexOf(id), 1) : arrCopy.push(id);
        select(arrCopy)
    }

    return (
        <div>
            <div className={classes.container}>
                {options.map((option: any) => (
                    <Chip color={selected.includes(option.id) ? 'primary' : 'default'} key={option.id} className={classes.chip} 
                        label={option.name} onClick={() => handleClick(option.id)} />
                ))}
            </div>
        </div>
    )
}