import makeStyles from '@material-ui/styles/makeStyles';
import { theme } from '../../../app/Theme';

const drawerWidth = 240;
export const useStyles = makeStyles({
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        color: theme.palette.primary.contrastText
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    listIcon: {
        color: theme.palette.primary.contrastText
    },
    logo: {
        height: 80,
        margin: '16px 0'
    },
    active: {
        color: theme.palette.primary.main
    },
    userContainer: {
        position: 'absolute', 
        bottom: 96, 
        width: drawerWidth,
        cursor: 'pointer',
        flexDirection: 'column',
        padding: '16px 0'
    },
    avatar: {
        margin: 'auto',
        marginBottom: 8,
        height: 72,
        width: 72
    },
    logout: {
        position: 'absolute',
        bottom: 48,
    },
    divider: {
        backgroundColor: theme.palette.secondary.contrastText
    }
});