import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router';
import { useStyles } from './LoginViewStyle';
import { SessionCredentials, CryptographyUtilities } from '@shout-sdk/client-sdk';
import { Auth } from '../../../auth';
import { ErrorSnackbar } from '../../dashboard/common/ErrorSnackbar';
import { Layout } from '../Layout';

const Login: React.FC<any> = props => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [err, setErr] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        // check if email is in query params
        const url = new URL(window.location.href);
        const emailBase64 = url.searchParams.get('ea');
        if (emailBase64) { setEmail(atob(emailBase64!)); }
    }, [])

    const login = async (event: any) => {
        event.preventDefault();
        const user = new SessionCredentials(CryptographyUtilities.hash(password), email);

        const authResult = await Auth.authorize(user);

        if (authResult.success) {
            setSuccess(true);
        } else {
            setErr(authResult.errors![0]);
        }
    }

    if (success) {
        props.history.push('/');
    }

    return (
        <Layout>
            <div>
                <form onSubmit={login}>
                    <TextField
                        value={email}
                        onChange={ev => setEmail(ev.target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Email Address"
                        autoComplete="email"
                        type="email"
                        autoFocus
                    />
                    <TextField
                        value={password}
                        onChange={ev => setPassword(ev.target.value)}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                    >
                        Sign In
                        </Button>
                    <Grid container direction="row-reverse">
                        <Grid item>
                            <Link href="/register" variant="body2">
                                Don't have an account? Register your business!
                            </Link>
                        </Grid>
                    </Grid>
                </form>
                <ErrorSnackbar open={err.length > 0} err={err} />
            </div>
        </Layout>
    );
}

export const LoginView = withRouter(Login);