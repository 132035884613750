import React from 'react';
import { Container, Box, Typography, Grid, ButtonBase } from '@material-ui/core';
import { useMyGeofences, EmptyPlaceholder, Loader } from '../common';
import { GeofenceCard } from '../events/all/GeofenceCard';
import { withRouter } from 'react-router';

const AllGeofences: React.FC<any> = props => {
    const { geofences, isLoading } = useMyGeofences();

    if (isLoading) return <Loader />;

    const permanent = geofences.filter(gf => gf.endDate === null);

    return (
        <Container maxWidth='lg'>
            <Box marginY={4}>
                <Typography variant='h2' color='primary' gutterBottom>Your Locations</Typography>
                {geofences.length === 0 &&
                    <EmptyPlaceholder text="You currently don't have any locations." />
                }
            </Box>
            <Grid spacing={2} container>
                {permanent.map(geofence => (
                    <Grid key={geofence.geofenceNumberId} style={{ marginTop: 16 }} md={4} xs={12} item>
                        <ButtonBase style={{ width: '100%' }} onClick={() => props.history.push({ pathname: '/statuses', state: { geofenceId: geofence.geofenceNumberId } })}>
                            <GeofenceCard geofence={geofence} />
                        </ButtonBase>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}

export const AllGeofencesView = withRouter(AllGeofences);