import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

const myTheme = createMuiTheme({
    palette: {
        primary: {
            light: '#6bcbbe',
            main: '#4dada0',
            dark: '#6bcbbe',
            contrastText: '#f5f5f5'
        },
        secondary: {
            main: '#33393b',
            light: '#a7abad'
        },
        error: {
            main: '#f95b5b',
            light: '#f97d7d'
        },
        background: {
            default: '#FFF'
        }
    },
    overrides: {
        MuiDrawer: {
            paper: {
                backgroundColor: '#33393b',
            }
        }
    }
});

export const theme = responsiveFontSizes(myTheme);