import React, { useRef, useState } from 'react';
import { TextField, makeStyles, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { AddStatusRequest, StatusTranslation } from '@shout-sdk/client-sdk';
import clsx from 'clsx';

interface IProps {
    status: AddStatusRequest,
    setStatus: React.Dispatch<React.SetStateAction<AddStatusRequest>>,
    setImages: React.Dispatch<React.SetStateAction<File[]>>
}

const useStyles = makeStyles(theme => ({
    btn: {
        margin: theme.spacing(4, 0),
    },
    input: {
        '& textarea': {
            minHeight: 0,
            transition: 'all 0.2s ease',
        }
    },
    expanded: {
        '& textarea': {
            minHeight: 100,
        }
    },

}))

const translation = new StatusTranslation();
translation.language = 'eng';

export const StatusContent: React.FC<IProps> = props => {
    const classes = useStyles();
    const { status, setStatus, setImages } = props;
    const [expanded, setExpanded] = useState(false);
    const imgRef = useRef<HTMLInputElement>(null);

    const handleText = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        translation.message = ev.target.value;
        setStatus({ ...status, translation });
    }

    const saveImages = (files: FileList | null) => {
        if (files) {
            // get first 4 images
            setImages(Array.from(files).slice(0, 4));
        }
    }

    return (
        <div>
            <TextField
                required
                label="Status Text"
                margin="normal"
                variant="outlined"
                helperText={`${status.translation.message?.length ?? 0}/280`}
                error={(status.translation.message?.length ?? 0) > 280}
                value={status.translation.message}
                onChange={handleText}
                InputProps={{
                    className: clsx(expanded && classes.expanded, classes.input)
                }}
                onClick={() => setExpanded(true)}
                fullWidth
                multiline
            />
            <Button
                color="primary"
                variant="outlined"
                startIcon={<CloudUploadIcon />}
                size="large"
                className={classes.btn}
                onClick={() => imgRef.current!.click()}
            >
                {status.numberOfAttachedImages > 0 ? 'CHANGE IMAGES' : 'ADD IMAGES'}
            </Button>
            <input type="file" accept="image/png, image/jpeg" ref={imgRef} style={{ display: 'none' }}
                onChange={ev => saveImages(ev.target.files)} multiple />
        </div>
    )
}