import React from 'react';
import { Container, Box, Button } from '@material-ui/core';
import { useEffect } from 'react';
import { Group, SessionClient, ShoutClient } from '@shout-sdk/client-sdk';
import { withRouter } from 'react-router';
import { GroupHeader } from './GroupHeader';
import { GroupMemberGrid } from './GroupMemberGrid';
import { AddGroupMembers } from './AddGroupMembers';
import { GroupActions } from './GroupActions';

let uasClient: ShoutClient;

export const GroupDetail: React.FC<any> = props => {

    const [group, setGroup] = React.useState<Group>(new Group());
    const { groupId } = props.location.state;

    useEffect(() => {
        const fetchGroup = async () => {
            uasClient = await SessionClient.getUasClient();
            const res = await uasClient.get<any>(`/api/1/groups/${groupId}`);
            if (res.ok()) {
                setGroup(res.data.groups[0]);
            }
        }
        fetchGroup();
    }, [])

    return (
        <Container maxWidth='lg'>
            <Box marginY={4}>
                <GroupHeader group={group} />
                <GroupMemberGrid invites={group.invitedMembers} groupId={group.groupId} />
                <AddGroupMembers groupId={group.groupId} />
                <GroupActions group={group} />
            </Box>
        </Container>
    )
}

export const GroupDetailView = withRouter(GroupDetail);