import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import { IOrgProps } from './UserProfileProps';

export const UserOpeningTimes: React.FC<IOrgProps> = props => {

    const { org, edit } = props;

    const renderOpeningTimes = () => {
        return Object.keys(org.openingTimes).map((day: string) => {
            // @ts-ignore
            const start = org.openingTimes[day][0];
            if (start === -1) {
                return <Typography style={{ textTransform: 'capitalize' }} color='textSecondary'>{day}: closed</Typography>
            }

            // @ts-ignore
            const end = org.openingTimes[day][1];
            // toFixed(0) rounds up, so calculate hour with two floating digits and remove them with slice
            // modulo division must be adjusted if only 1 digit is returned (> 9)
            return <Typography style={{ textTransform: 'capitalize' }} key={day} color='textSecondary'>
                {day}: {(start / 60).toFixed(2).slice(0, -3)}:{start % 60 > 9 ? start % 60 : '0' + (start % 60)} - {(end / 60).toFixed(2).slice(0, -3)}:{end % 60 > 9 ? end % 60 : '0' + (end % 60)}
            </Typography>
        })
    }

    return (
        <Box>
            <Typography variant='h5' gutterBottom>Opening Times
                {/* {edit && <Button color='primary'>EDIT</Button>} */}
            </Typography>
            {renderOpeningTimes()}
        </Box>
    )
}