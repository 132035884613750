import React, { useState, useEffect } from 'react';
import { Box, Link, Typography, ButtonBase } from '@material-ui/core';
import { StatusWithUserProfileInformation, SessionClient } from '@shout-sdk/client-sdk';
import { StatusGrid } from 'components/dashboard/statuses/all/StatusGrid';
import { StatusCard } from 'components/dashboard/statuses/all/StatusCard';
import { withRouter } from 'react-router';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export const EventStatusesGrid: React.FC<any> = props => {
    const [statuses, setStatuses] = useState<StatusWithUserProfileInformation[]>([]);

    useEffect(() => {
        const fetchStatuses = async () => {
            const lcsClient = await SessionClient.getLcsClient();
            const res = await lcsClient.post<any>('/api/1/status/geofence', {
                geofenceIds: [props.geofence],
                contentCategories: null,
                includeContentFromChildGeofences: false,
                allowAdultContent: true
            });
            if (res.ok()) {
                // only display first 3 statuses
                const returnedStatuses = res.data.statuses.filter((status: StatusWithUserProfileInformation) => status.statusInformation?.contentCategory !== 7);
                setStatuses(returnedStatuses.slice(0, 3));
            }
        }
        fetchStatuses();
    }, []);

    const navigateToStatusView = () => {
        props.history.push({ pathname: '/statuses', state: { geofenceId: props.geofence } });
    }

    return (
        <Box>
            {statuses.length > 0 &&
                <Box marginY={4}>
                    <StatusGrid title='Recent Statuses' active>
                        {statuses.map(status => (
                            <StatusCard key={status.statusInformation!.statusId} status={status.statusInformation} user={status.profileInformation!} />
                        ))}
                    </StatusGrid>
                    <ButtonBase onClick={navigateToStatusView}>
                        <Typography color='textSecondary' component='span' variant='h6'>
                            <Box display='flex' alignItems='center'>
                                See all Statuses<NavigateNextIcon />
                            </Box>
                        </Typography>
                    </ButtonBase>
                </Box>
            }
        </Box>
    )
}

export const EventStatuses = withRouter(EventStatusesGrid);