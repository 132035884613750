import React, { useState } from 'react';
import { Box, TextField, InputAdornment, MenuItem, Select, Typography, Button, LinearProgress } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import countries from '../../../app/countries.json';
import { PhoneNumberDetails, SessionClient, ValidatePhoneNumberResponse, UserAccountServiceUrls, AccountType, ValidatePhoneNumberRequest, RequestPhoneVerificationResponse, CryptographyUtilities, BooleanResponse } from '@shout-sdk/client-sdk';
import { ErrorSnackbar } from 'components/dashboard/common';
import { CurrentUser, Auth } from 'auth';
import { withRouter } from 'react-router';
import { useStyles } from './VerifySmsStyle';
import { useEffect } from 'react';
import clsx from 'clsx';


let verificationId: string;
const initialPhoneDetails = new PhoneNumberDetails();
initialPhoneDetails.countryCode = 44;
initialPhoneDetails.number = 0;

const Verify: React.FC<any> = props => {
    const classes = useStyles();
    const [phoneDetails, setPhoneDetails] = useState<PhoneNumberDetails>(initialPhoneDetails);
    const [smsCode, setSmsCode] = useState('');
    const [isSent, setIsSent] = useState(false);
    const [err, setErr] = useState('');

    useEffect(() => {
        if (isSent) { setIsSent(false) }
    }, [phoneDetails.number])

    const sendVerification = async () => {
        // create valid request object, by adding the country code to the phone number
        const phoneCopy = Object.assign({}, phoneDetails);
        phoneCopy.number = Number('' + phoneCopy.countryCode + Number(phoneCopy.number));
        const uasClient = await SessionClient.getUasHttpClient();
        // validate phone number
        const validationRes = await uasClient.post<ValidatePhoneNumberResponse>(UserAccountServiceUrls.PhoneNumber.Validate, new ValidatePhoneNumberRequest(
            AccountType.Organization,
            phoneCopy
        ));
        if (!validationRes.ok() || !validationRes.data!.isValid) {
            setErr('Phone number invalid! Please try again.');
            return;
        }
        // send sms verification
        const verificationResponse = await uasClient.post<RequestPhoneVerificationResponse>(UserAccountServiceUrls.PhoneVerification.RequestVerification, phoneCopy);
        if (!verificationResponse.ok()) { setErr('Could not send phone verification! ' + verificationResponse.data!.errors![0]); return }
        verificationId = verificationResponse.data!.verificationId!.toString();
        setIsSent(true);
    }

    const verify = async () => {
        if (smsCode.length !== 5) { setErr('Invalid code! 5 numbers expected.'); return }
        const concatenatedTokenHash = props.upgradeData ?
            CryptographyUtilities.hash(CurrentUser.getProfile().userId + smsCode).toUpperCase() :
            CryptographyUtilities.hash(CurrentUser.getProfile().accountEmailAddress!.toLowerCase() + smsCode).toUpperCase();

        // verify sms token
        const uasHttpClient = await SessionClient.getUasHttpClient();
        const res = await uasHttpClient.post<BooleanResponse>(UserAccountServiceUrls.PhoneVerification.Verify,
            { verificationId, concatenatedTokenHash });
        if (!res.ok()) { setErr('Incorrect code! We have sent you a new one.'); sendVerification(); return }

        // if upgradeData is passed as a prop it is an individual registration
        // can only be upgraded after their phone number is verified
        if (props.upgradeData) {
            const uasClient = await SessionClient.getUasClient();
            const upgradeResponse = await uasClient.post<any>(UserAccountServiceUrls.Upgrade.Individual, props.upgradeData);
            if (!upgradeResponse.ok()) { setErr('Could not upgrade your account!'); return }
            Auth.terminate();
            props.history.push('/download');
            return;
        }

        setErr('');
        setIsSent(false);
        CurrentUser.set().then(() => props.history.push('/'));
    }

    return (
        <Box className={classes.container}>
            <Typography variant="h5">
                Enter your phone number.
            </Typography>
            <Typography>
                We will send you a code to confirm your identity.
            </Typography>
            <TextField
                label="Phone Number"
                type="number"
                variant="outlined"
                className={classes.phoneInput}
                value={phoneDetails.number}
                onChange={(ev: any) => setPhoneDetails({ ...phoneDetails, number: ev.target.value, equals: PhoneNumberDetails.prototype.equals })}
                required
                InputProps={{
                    startAdornment:
                        <InputAdornment position="start">
                            <Select
                                renderValue={value => `+${value}`}
                                className={clsx(classes.phoneCode, classes.codeInput)}
                                value={phoneDetails.countryCode}
                                onChange={(ev: any) => setPhoneDetails({ ...phoneDetails, countryCode: ev.target.value, equals: PhoneNumberDetails.prototype.equals })}
                            >
                                {countries.map(country => {
                                    return <MenuItem key={country.Name} value={country.PhoneCode}>
                                        {country.Name} +{country.PhoneCode}
                                    </MenuItem>
                                })}
                            </Select>
                        </InputAdornment>,
                    classes: {
                        input: classes.codeInput
                    }
                }}
            />
            {!isSent &&
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        endIcon={<SendIcon />}
                        onClick={sendVerification}
                    >
                        Send SMS
                    </Button>
                </div>
            }
            {isSent &&
                <Box marginTop={4}>
                    <Typography variant="h5">Please enter the code we just sent you! This may take a few seconds.</Typography>
                    <LinearProgress className={classes.loader} />
                    <div>
                        <TextField
                            label="Verification Code"
                            placeholder="XXXXX"
                            type="number"
                            variant="outlined"
                            className={classes.codeInputBox}
                            value={smsCode}
                            onChange={(ev: any) => { if (ev.target.value.length < 6) setSmsCode(ev.target.value) }}
                            InputProps={{
                                classes: {
                                    input: classes.codeInput,
                                }
                            }}
                            InputLabelProps={{
                                shrink: true
                            }}
                            required
                        />
                    </div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={verify}
                    >
                        Confirm Phone number
                    </Button>
                </Box>
            }
            <ErrorSnackbar open={err.length > 0} err={err} />
        </Box>
    )
}

export const VerifySms = withRouter(Verify);