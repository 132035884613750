import React, { useState } from 'react';
import { Box, Typography, Divider, Grid, ButtonBase, makeStyles, Collapse, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import { ScheduleCard } from './ScheduleCard';
import { ScheduleTable } from './ScheduleTable';

const useStyles = makeStyles(theme => ({
    fullWidth: {
        width: '100%',
        textAlign: 'left'
    }
}))

export const ScheduleGrid: React.FC<any> = props => {
    const classes = useStyles();
    const [isOpen, setOpen] = useState(true);
    const { day, sessions, defaultImage, display } = props;
    const date = new Date(day);

    return (
        <Box>
            <Box marginTop={4} marginBottom={2}>
                <ButtonBase className={classes.fullWidth} onClick={() => setOpen(!isOpen)}>
                    <Box className={classes.fullWidth}>
                        <Typography variant="h5" component="span">{date.toLocaleString([], { weekday: 'long' })}</Typography>
                        <Typography variant="h5" component="span" color="primary"> {date.toLocaleString([], { day: '2-digit', month: 'short' })}</Typography>
                        <Divider />
                    </Box>
                </ButtonBase>
            </Box>
            <Collapse in={isOpen}>
                {display === 0 ?
                    <Grid spacing={2} container>
                        {sessions.map((session: any) => (
                            <Grid key={session.statusInformation.statusId} md={4} xs={12} item>
                                <ScheduleCard session={session.statusInformation} defaultImage={defaultImage} />
                            </Grid>
                        ))}
                    </Grid> :
                    <ScheduleTable sessions={sessions} />}
            </Collapse>
        </Box>
    )
}