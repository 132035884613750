import React from 'react';
import { makeStyles, Box, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    wrapper: {
        height: '50vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

export const EmptyPlaceholder: React.FC<any> = props => {
    const classes = useStyles();

    return (
        <Box className={classes.wrapper}>
            <Typography color='textSecondary' variant="h3">{props.text}</Typography>
        </Box>
    )
}