import React from 'react';
import { Box, Grid, Hidden, Container } from '@material-ui/core';
import { StepHeader } from './StepHeader';
import { SessionCardPreview } from './SessionCardPreview';

export const Layout: React.FC<any> = props => {

    const { step, session, imgs } = props;

    return (
        <Box>
            <StepHeader step={step} />
            <Box marginTop={6}>
                <Grid container>
                    <Grid md={6}>
                        <Container>
                            {props.children}
                        </Container>
                    </Grid>
                    <Hidden smDown>
                        <Grid md={6}>
                            <SessionCardPreview session={session} imgs={imgs} />
                        </Grid>
                    </Hidden>
                </Grid>
            </Box>
        </Box>
    )
}