import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    container: {
        textAlign: 'left'
    },
    button: {
        margin: theme.spacing(4, 0, 4),
        float: 'right'
    },
    header: {
        marginTop: theme.spacing(4)
    }
}))