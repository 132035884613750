import React, { useState, useEffect } from 'react';
import { Box, List, Typography, Divider, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { SessionClient, LocalContentServiceUrls, ImpressionItemWithUserProfileInformation } from '@shout-sdk/client-sdk';
import { useStyles } from './styles';
import { getTime } from './common';

export const LikeList: React.FC<any> = props => {
    const classes = useStyles();
    const [likes, setLikes] = useState<ImpressionItemWithUserProfileInformation[]>([]);
    const { statusId } = props;

    useEffect(() => {
        const fetchLikes = async () => {
            const lcsClient = await SessionClient.getLcsClient();
            const res = await lcsClient.get<any>(LocalContentServiceUrls.Status.GetLikes(statusId));
            if (res.ok()) {
                setLikes(res.data.likes);
            }
        }
        fetchLikes();
    }, [])

    return (
        <Box>
            <Typography variant='h5'>Likes</Typography>
            <List className={classes.container}>
                {likes.map((like, index) => (
                    <React.Fragment>
                        {index > 0 && <Divider />}
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar src={like.userProfileBasicInformation.thumbnailUrl!} />
                            </ListItemAvatar>
                            <ListItemText primary={
                                <React.Fragment>
                                    <Typography color='primary' component='span'>
                                        {like.userProfileBasicInformation.name}
                                    </Typography>
                                    <Typography color='textSecondary' variant='body2' component='span'>
                                        {` (${getTime(like)})`}
                                    </Typography>
                                </React.Fragment>
                            } />
                        </ListItem>
                    </React.Fragment>
                ))}
            </List>
            {likes.length === 0 &&
                <Typography color='textSecondary'>
                    No likes found.
                </Typography>
            }
        </Box>
    )
}