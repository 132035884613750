import React, { useState, useRef } from 'react';
import { Card, CardHeader, Avatar, Typography, IconButton, Box, Menu, MenuItem } from '@material-ui/core';
import { GroupCoreInformation, GroupMemberRole, SessionClient } from '@shout-sdk/client-sdk';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { CurrentUser } from 'auth';
import { withRouter } from 'react-router';
import GroupIcon from '@material-ui/icons/Group';

const GroupCardComponent: React.FC<any> = props => {

    const [showOptions, setShowOptions] = useState(false);
    const group: GroupCoreInformation = props.group;
    const optRef = useRef(null);

    const leaveGroup = async () => {
        const uasClient = await SessionClient.getUasClient();
        const res = await uasClient.delete(`/api/1/groups/${group.groupId}/members/${CurrentUser.getProfile().userId}`);
        if (res.ok()) {
            window.location.reload();
        }
    }

    const navigateToGroup = () => {
        props.history.push({ pathname: '/groups/detail', state: { groupId: group.groupId } });
    }

    return (
        <Card style={{ maxWidth: 500, marginBottom: 16 }}>
            <CardHeader
                avatar={
                    <Avatar src={group.thumbnailUrl!}>
                        {!group.thumbnailUrl && <GroupIcon />}
                    </Avatar>
                }
                title={<Typography color='primary'>{group.translations[0].title}</Typography>}
                subheader={group.translations[0].description}
                action={
                    <Box marginTop={1}>
                        <IconButton ref={optRef} onClick={() => setShowOptions(true)}>
                            <MoreVertIcon />
                        </IconButton>
                    </Box>
                }
            />
            <Menu
                anchorEl={optRef.current}
                keepMounted
                open={showOptions}
                onClose={() => setShowOptions(false)}
            >
                {group.requesterRole === GroupMemberRole.Admin &&
                    <MenuItem onClick={navigateToGroup}>
                        Manage Group
                    </MenuItem>
                }
                <MenuItem onClick={leaveGroup}>
                    Leave Group
                </MenuItem>
            </Menu>
        </Card>
    )
}

export const GroupCard = withRouter(GroupCardComponent);