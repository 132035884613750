import React, { useState, useEffect } from 'react';
import { Card, CardHeader, IconButton, CardMedia, CardContent, Typography } from '@material-ui/core';
import { AddStatusRequest } from '@shout-sdk/client-sdk';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { StatusCardCarousel } from '../../statuses/all/StatusCardCarousel';
import clsx from 'clsx';
import { useStyles } from '../../statuses/all/StatusCardStyle';
import { dateOptions, timeOptions } from 'components/app/constants';
import { areDatesOnSameDay } from 'components/app/helpers';

interface IProps {
    session: AddStatusRequest,
    imgs: File[]
}

export const SessionCardPreview: React.FC<IProps> = props => {
    const classes = useStyles();
    const [images, setImages] = useState<string[]>([]);
    const { session, imgs } = props;

    useEffect(() => {
        setImages(imgs.map(img => URL.createObjectURL(img)));
    }, [imgs]);

    const renderDate = () => {
        const start = new Date(session.start!);
        const end = new Date(start);
        end.setSeconds(end.getSeconds() + session.expirationTimeInSeconds);
        const isSameDay = areDatesOnSameDay(start, end);
        return `${start.toLocaleString([], isSameDay ? timeOptions : dateOptions)} - ${end.toLocaleTimeString([], isSameDay ? timeOptions : dateOptions)}`
    }

    return (
        <Card style={{ width: 345 }} className={classes.card}>
            <CardHeader
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                classes={{
                    subheader: clsx(session.start && classes.scheduled)
                }}
                title={<Typography variant='body1'>{session.translation.title ? session.translation.title : 'Session Title'}</Typography>}
                subheader={(session.roomName ? session.roomName : '') + ' ' + (session.start && session.expirationTimeInSeconds > 0 ? renderDate() : '')}
            />
            {images.length === 1 &&
                <CardMedia
                    className={classes.media}
                    image={images[0]}
                />
            }
            {images.length > 1 &&
                <StatusCardCarousel imgs={images} />
            }
            <CardContent className={classes.contentText}>
                <Typography variant="body2" color="textSecondary">
                    {session.translation.message ? session.translation.message : 'Session Description'}
                </Typography>
            </CardContent>
        </Card>
    )
}