import React, { useEffect } from 'react';
import { ContentCategory } from '@shout-sdk/client-sdk';
import { FormControl, InputLabel, Select, MenuItem, ButtonGroup, Button, CssBaseline, Box } from '@material-ui/core';
import { ExclusiveSelect } from './ExclusiveSelect';
import { PillSelect } from './PillSelect';

interface IProps {
    category: ContentCategory,
    onChange: ({ }) => void
}

export const SubCategoryPicker: React.FC<IProps> = props => {
    const [selectedSub, selectSub] = React.useState<any>(null);
    const [booleanOrOne, setBooleanOrOne] = React.useState<number | null>(null);
    const [booleanOrTwo, setBooleanOrTwo] = React.useState<number | null>(null);
    const [booleanAnd, setBooleanAnd] = React.useState<number[] | null>(null);

    const { category, onChange } = props;

    // reset everytime category changes
    useEffect(() => {
        selectSub(null);
        setBooleanOrOne(null);
        setBooleanOrTwo(null);
        setBooleanAnd(null);
        onChange({
            contentSubcategory: null,
            booleanOrOptionsSetOne: null,
            booleanOrOptionsSetTwo: null,
            booleanAndOptions: null
        })
    }, [category])

    useEffect(() => {
        // only return object to parent if all required fields are selected
        if (selectSub === null) { return }
        else if (category.booleanOrOptionsSetOne && !booleanOrOne) { return }
        else if (category.booleanOrOptionsSetTwo && !booleanOrTwo) { return }
        onChange({
            contentSubcategory: selectedSub,
            booleanOrOptionsSetOne: booleanOrOne,
            booleanOrOptionsSetTwo: booleanOrTwo,
            booleanAndOptions: booleanAnd
        })
    }, [selectedSub, booleanOrOne, booleanOrTwo, booleanAnd]);

    return (
        <div>
            <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel>
                    Select a Sub category
                </InputLabel>
                <Select labelWidth={155} value={selectedSub} onChange={ev => selectSub(ev.target.value)}>
                    {category.subcategories?.map(sub => (
                        <MenuItem key={sub.id} value={sub.id}>{sub.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            {category.booleanOrOptionsSetOne &&
                <Box marginY={2}>
                    <InputLabel style={{ textAlign: 'left' }}>
                        {category.booleanOrOptionsSetOneName}
                    </InputLabel>
                    <ExclusiveSelect options={category.booleanOrOptionsSetOne}
                        selected={booleanOrOne} onChange={setBooleanOrOne} />
                </Box>
            }
            {category.booleanOrOptionsSetTwo &&
                <Box marginY={2}>
                    <InputLabel style={{ textAlign: 'left' }}>
                        {category.booleanOrOptionsSetTwoName}
                    </InputLabel>
                    <ExclusiveSelect options={category.booleanOrOptionsSetTwo}
                        selected={booleanOrTwo} onChange={setBooleanOrTwo} />
                </Box>
            }
            {category.booleanAndOptions &&
                <Box marginY={4}>
                    <InputLabel style={{ textAlign: 'left' }}>
                        {category.booleanAndOptionsName} (optional)
                    </InputLabel>
                    <PillSelect options={category.booleanAndOptions} onChange={setBooleanAnd} />
                </Box>
            }
        </div>
    )
}