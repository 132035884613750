import React from 'react';
import { Layout } from '../Layout';
import { Button, Box, makeStyles, Typography } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import { Auth, Store } from 'auth';

const useStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 300
    }
}));

let emailAddress: string | null = null;
let emailToken: string | null = null;

export const InvitationView: React.FC<any> = props => {
    const classes = useStyles();

    const readInviteData = async () => {
        if (Auth.isAuthorized()) {
            await Auth.terminate();
        }

        // read query params and store them in cookies
        const url = new URL(window.location.href);
        const emailBase64 = url.searchParams.get('ea');
        emailToken = url.searchParams.get('et');
        if (emailBase64) { emailAddress = atob(emailBase64!); }

        Store.update({ registrationInfo: { emailAddress, emailToken } });
    }

    readInviteData();

    return (
        <Layout>
            <Box className={classes.container}>
                <Typography variant="h4">
                    Welcome to Shout!
                </Typography>
                <Typography variant="subtitle1">
                    Register as...
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<PersonIcon />}
                    onClick={() => props.history.push('/signup')}
                    fullWidth
                >
                    Individual
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<BusinessIcon />}
                    onClick={() => props.history.push('/register')}
                    fullWidth
                >
                    Business
                </Button>
            </Box>
        </Layout>
    )
}