import React, { useState, useEffect, useRef } from 'react';
import { Container, Box, Typography, TextField, Grid, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { Geofence, SessionClient, GeofencesResponse, TvPreferences, SelectedContentCategorySubcategories } from '@shout-sdk/client-sdk';
import { CurrentUser } from 'auth';
import { EventCategorySelect } from 'components/dashboard/events/create/EventCategorySelect';
import { LoadingButton, useUploadImage, ErrorSnackbar } from 'components/dashboard/common';
import { uploadImage } from 'components/app/helpers';
import { withRouter } from 'react-router';

const CreatePreference: React.FC<any> = props => {

    const [geofences, setGeofences] = useState<Geofence[] | null>([]);
    const [preferences, setPreferences] = useState<TvPreferences>(new TvPreferences());
    const [image, setImage, imgErr] = useUploadImage(720);
    const [err, setErr] = useState('');
    const [isLoading, setLoading] = useState(false);
    const imgRef = useRef<any>(null);

    useEffect(() => {
        const fetchGeofences = async () => {
            const gfsClient = await SessionClient.getGfsClient();
            const res = await gfsClient.post<GeofencesResponse>('/api/1/geofence/nearby', { location: CurrentUser.getOrganizationInfo().location });
            if (res.ok()) {
                setGeofences(res.data!.results);
            }
        }
        fetchGeofences();
    }, []);

    const saveImage = (files: any) => {
        if (files) {
            setImage(files[0]);
        }
    }

    const handleCategories = (categories: SelectedContentCategorySubcategories[] | null) => {
        setPreferences({ ...preferences, contentCategories: categories });
    }

    const create = async () => {
        setLoading(true);
        const tvsClient = await SessionClient.getTvPreferenceServiceClient();
        const res = await tvsClient.post<any>('/api/1/preferences', { preferences });
        if (!res.ok()) { setErr(res.data.errors[0]); return; }
        if (image) {
            const imgRes = await tvsClient.get<any>(`/api/1/preferences/${res.data.preferenceId}/image_upload_ticket`);
            if (imgRes.ok()) {
                await uploadImage(image, imgRes.data.fileUploadDetails, res.data.preferenceId.toString());
            }
        }
        props.history.push('/devices');
    }

    return (
        <Container maxWidth='lg'>
            <Box marginY={4}>
                <Typography variant='h2' color='primary' gutterBottom>Create New TV Preference</Typography>
                <Box marginY={4}>
                    <Grid spacing={4} container>
                        <Grid md={6} xs={12} item>
                            <Box marginY={2}>
                                <TextField label='Preference Name' variant='outlined'
                                    value={preferences.name} onChange={ev => setPreferences({ ...preferences, name: ev.target.value })} fullWidth />
                                <FormControl variant='outlined' margin='normal' fullWidth>
                                    <InputLabel>Select Geofences</InputLabel>
                                    <Select
                                        multiple
                                        labelWidth={125}
                                        value={preferences.geofenceIds}
                                        onChange={(ev: any) => setPreferences({ ...preferences, geofenceIds: ev.target.value })}
                                    >
                                        {geofences && geofences.map(geofence => (
                                            <MenuItem key={geofence.geofenceNumberId} value={geofence.geofenceNumberId}>
                                                {geofence.translations![0].title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={preferences.includeContentFromChildGeofences}
                                            onChange={ev => setPreferences({ ...preferences, includeContentFromChildGeofences: ev.target.checked })}
                                            color="primary"
                                        />
                                    }
                                    label="Include content from child geofences"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={preferences.allowAdultContent}
                                            onChange={ev => setPreferences({ ...preferences, allowAdultContent: ev.target.checked })}
                                            color="primary"
                                        />
                                    }
                                    label="Allow adult content"
                                />
                                <Box marginY={2}>
                                    {image && <img style={{ height: 200, width: '100%', objectFit: 'cover', marginBottom: 16 }} src={URL.createObjectURL(image)} />}
                                    <Button variant='outlined' color='primary' onClick={() => imgRef.current!.click()}>{image ? 'CHANGE IMAGE' : 'ADD IMAGE'}</Button>
                                    <input type="file" accept="image/png, image/jpeg" ref={imgRef} style={{ display: 'none' }}
                                        onChange={ev => saveImage(ev.target.files)} />

                                </Box>
                            </Box>
                        </Grid>
                        <Grid md={6} xs={12} item>
                            <EventCategorySelect onChange={handleCategories} />
                        </Grid>
                    </Grid>
                    <Box textAlign='center' marginY={4}>
                        <LoadingButton color='primary' variant='contained' loading={isLoading} onClick={create}>Create Preference</LoadingButton>
                    </Box>
                </Box>
                <ErrorSnackbar open={err.length > 0} err={err} />
                <ErrorSnackbar open={imgErr.length > 0} err={imgErr} />
            </Box>
        </Container>
    )
}

export const CreatePreferenceView = withRouter(CreatePreference);