import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions, MenuItem } from '@material-ui/core';
import { IOrgProps } from './UserProfileProps';
import { OrganizationAddress, SessionClient } from '@shout-sdk/client-sdk';
import countries from '../../../app/countries.json';
import { ErrorSnackbar } from 'components/dashboard/common';

export const UserAddress: React.FC<IOrgProps> = props => {

    const [showEdit, setShowEdit] = useState(false);
    const { org, edit } = props;
    const { address } = org;
    const [addressInfo, setAddress] = useState<OrganizationAddress>(address);
    const [error, setError] = useState('');

    useEffect(() => {
        setAddress(address);
    }, [address]);

    const saveChanges = async () => {
        const lcsClient = await SessionClient.getLcsClient();
        const res = await lcsClient.post<any>('/api/1/organization/change_details', { organizationId: org.organizationId, address: addressInfo });
        if (res.ok()) {
            window.location.reload();
        } else {
            setError(res.data.errors[0]);
        }
    }

    return (
        <Box>
            <Typography variant='h5' gutterBottom>Address
                {edit && <Button color='primary' onClick={() => setShowEdit(true)}>EDIT</Button>}
            </Typography>
            <Typography variant='body1' color='textSecondary'>{address.addressLineOne}</Typography>
            <Typography variant='body1' color='textSecondary'>{address.addressLineTwo}</Typography>
            <Typography variant='body1' color='textSecondary'>{address.postCode}</Typography>
            <Typography variant='body1' color='textSecondary'>{address.city}, {address.countryCode}</Typography>
            <Dialog
                open={showEdit}
                onClose={() => setShowEdit(false)}
                fullWidth
            >
                <DialogTitle>Edit Address</DialogTitle>
                <DialogContent>
                    <TextField label='Address Line 1' margin='normal' variant='outlined'
                        value={addressInfo.addressLineOne} onChange={ev => setAddress({ ...addressInfo, addressLineOne: ev.target.value })} fullWidth />
                    <TextField label='Address Line 2' margin='normal' variant='outlined' value={addressInfo.addressLineTwo} 
                        onChange={ev => setAddress({ ...addressInfo, addressLineTwo: ev.target.value.length > 0 ? ev.target.value : null })} fullWidth />
                    <TextField label='Post Code' margin='normal' variant='outlined'
                        value={addressInfo.postCode} onChange={ev => setAddress({ ...addressInfo, postCode: ev.target.value })} fullWidth />
                    <TextField label='City' margin='normal' variant='outlined'
                        value={addressInfo.city} onChange={ev => setAddress({ ...addressInfo, city: ev.target.value })} fullWidth />
                    <TextField
                        select
                        label="Country"
                        margin="normal"
                        variant="outlined"
                        value={addressInfo.countryCode}
                        onChange={ev => setAddress({ ...addressInfo, countryCode: ev.target.value })}
                        fullWidth
                        required
                    >
                        {countries.map(country => {
                            if (country.CountryCode !== '')
                                return <MenuItem key={country.CountryCode} value={country.CountryCode}>
                                    {country.Name}
                                </MenuItem>
                            return undefined;
                        })}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowEdit(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={saveChanges} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <ErrorSnackbar open={error.length > 0} err={error} />
        </Box>
    )
}