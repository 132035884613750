import React, { useState, useEffect } from 'react';
import { Box, Button, FormControl, InputLabel, Select, MenuItem, Typography } from '@material-ui/core';
import { SessionClient, AccountSubscription } from '@shout-sdk/client-sdk';
import { withRouter } from 'react-router';
import { async } from 'q';

const UserAdminComponent: React.FC<any> = props => {

    const [devices, setDevices] = useState([]);
    const { user, lastInvite } = props;

    useEffect(() => {
        const fetchDevices = async () => {
            const tvsClient = await SessionClient.getTvPreferenceServiceClient();
            const res = await tvsClient.get<any>('/api/1/devices?ownerId=' + user.userId);
            if (res.ok()) {
                setDevices(res.data.devices);
            }
        }
        if (user.userId > 0) fetchDevices();
    }, [user])

    const sendEmail = async () => {
        const uasClient = await SessionClient.getUasClient();
        const res = await uasClient.post(`/api/1/registrations/organization/${user.userId}/invite`, {});
        if (res.ok()) {
            props.history.push('/pending');
        }
    }

    const updateSubscription = async (subscription: number) => {
        const uasClient = await SessionClient.getUasClient();
        const res = await uasClient.post(`/api/1/profile/${user.userId}/subscription`, { subscription });
        if (res.ok()) {
            window.location.reload();
        }
    }

    const addDevice = async () => {
        const tvsClient = await SessionClient.getTvPreferenceServiceClient();
        const res = await tvsClient.post<any>('/api/1/devices', { userId: user.userId });
        if (res.ok()) {
            window.location.reload();
        }
    }

    if (user.userId === 0) {
        return <div />
    }

    return (
        <Box marginY={4}>
            <FormControl style={{ width: 500, display: 'flex' }} variant="outlined" margin="normal">
                <InputLabel>
                    Change Subscription Plan
                </InputLabel>
                <Select labelWidth={190} value={user.features.accountSubscription} onChange={(ev: any) => updateSubscription(ev.target.value)}>
                    {Object.values(AccountSubscription).filter(spread => typeof spread === 'number').map((value: any) => {
                        return <MenuItem key={value} value={value}>{AccountSubscription[value]}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <Typography gutterBottom>{lastInvite ? `Last invite was sent: ${new Date(lastInvite).toLocaleString()}` : 'No invite sent yet.'} </Typography>
            <Button variant='contained' color='primary' onClick={sendEmail}>Send email</Button>
            <Box marginY={2}>
                <Typography gutterBottom>Number of devices: ({devices.length})</Typography>
                <Button color='primary' variant='contained' onClick={addDevice}>Add New Device</Button>
            </Box>
        </Box>
    )
}

export const UserAdminSection = withRouter(UserAdminComponent);