import React, { useState } from 'react';
import { Box, Typography, Container } from '@material-ui/core';
import { AddEventGeofenceRequest, GeofenceTranslation, SessionClient, Geofence } from '@shout-sdk/client-sdk';
import { EventGeneralForm } from './EventGeneralForm';
import { EventLocationPicker } from './EventLocationPicker';
import { EventOwnerSearch } from './EventOwnerSearch';
import { ErrorSnackbar, LoadingButton, useUploadImage } from 'components/dashboard/common';
import { uploadImage } from 'components/app/helpers';
import { CurrentUser } from 'auth';
import { withRouter } from 'react-router';
import { EventCardPreview } from './EventCardPreview';

const initialState = new AddEventGeofenceRequest();

const CreateEvent: React.FC<any> = props => {
    initialState.translations = [new GeofenceTranslation()];
    initialState.ownerId = CurrentUser.getProfile().userId;
    const [event, setEvent] = useState<AddEventGeofenceRequest>(initialState);
    const [image, setImage, imgError] = useUploadImage(500);
    const [selectedGeofence, setGeofence] = useState<Geofence>(new Geofence());
    const [err, setErr] = useState('');
    const [loading, setLoading] = useState<boolean>(false);

    const createEvent = async () => {
        setLoading(true);
        const gfsClient = await SessionClient.getGfsClient();
        const res = await gfsClient.post<any>(`/api/1/geofence/${selectedGeofence.geofenceNumberId}/events`, event);
        if (res.ok()) {
            // if image set, create ticket and upload image
            if (image !== null) {
                const { imageUploadDetails } = res.data;
                await uploadImage(image, imageUploadDetails, selectedGeofence.geofenceNumberId.toString());
            }
            await CurrentUser.set();
            props.history.push('/events');
        } else {
            setErr(res.data.errors[0]);
        }
    }

    const getGeofenceImage = () => {
        return image ? URL.createObjectURL(image) : selectedGeofence.imageUrl;
    }

    const isBtnDisabled = (): boolean => {
        const titleLength = event.translations[0].title?.length ?? 0;
        if (titleLength < 4 || titleLength > 80) { return true }
        else if (event.startDate === '' || event.endDate === '') { return true }
        return new Date(event.startDate) > new Date(event.endDate);
    }

    return (
        <Container maxWidth="lg">
            <Box marginY={4}>
                <Typography variant="h2" color="primary" gutterBottom>Create New Event</Typography>
                <EventGeneralForm image={getGeofenceImage()} setImage={setImage} event={event} setEvent={setEvent} />
                <EventLocationPicker onChange={(geofence: Geofence) => setGeofence(geofence)} />
                <EventOwnerSearch onChange={(ownerId: number) => setEvent({ ...event, ownerId })} />
                <EventCardPreview event={event} image={getGeofenceImage()} />
                <Box textAlign='center' marginY={6}>
                    <LoadingButton
                        size='large'
                        variant='contained'
                        color='primary'
                        disabled={isBtnDisabled()}
                        loading={loading}
                        onClick={createEvent}>
                        CREATE EVENT
                    </LoadingButton>
                </Box>
            </Box>
            <ErrorSnackbar open={imgError.length > 0} err={imgError} />
            <ErrorSnackbar open={err.length > 0} err={err} />
        </Container>
    )
}

export const CreateEventView = withRouter(CreateEvent);