import React, { useState } from 'react';
import { Typography, Button, Box, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import { SessionClient } from '@shout-sdk/client-sdk';
import { withRouter } from 'react-router';

const DeleteEventButton: React.FC<any> = props => {

    const [showDialog, setShowDialog] = useState(false);
    const { eventId } = props;

    const deleteEvent = async () => {
        const gfsClient = await SessionClient.getGfsClient();
        const res = await gfsClient.delete(`/api/1/geofence/${eventId}`);
        if (res.ok()) {
            props.history.push('/events');
        }
    }

    return (
        <Box>
            <Typography color='error'>
                <Button variant='outlined' color='inherit' onClick={() => setShowDialog(true)}>
                    Delete Event
                </Button>
            </Typography>
            <Dialog
                open={showDialog}
                onClose={() => setShowDialog(false)}
                fullWidth
            >
                <DialogTitle>Are you sure you want to delete this event?</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setShowDialog(false)}>
                        Cancel
                    </Button>
                    <Button onClick={deleteEvent} color='primary' autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>

    )
}

export const DeleteEventBtn = withRouter(DeleteEventButton);