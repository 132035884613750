import React, { useState } from 'react';
import { Box, Button, makeStyles, Dialog, DialogTitle, DialogActions } from '@material-ui/core';
import { SessionClient } from '@shout-sdk/client-sdk';
import { CurrentUser } from 'auth';
import { withRouter } from 'react-router';

enum GroupAction {
    Leave = 1,
    Delete = 2
}

const useStyles = makeStyles(theme => ({
    leave: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main
    },
    delete: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
        margin: theme.spacing(0, 2),
        '&:hover': {
            backgroundColor: theme.palette.error.light
        }
    }
}))

const GroupActionsComponent: React.FC<any> = props => {
    const classes = useStyles();
    const [action, setAction] = useState<GroupAction>(0);

    const { group } = props;
    const currUser = CurrentUser.getProfile().userId;

    const leaveGroup = async () => {
        const uasClient = await SessionClient.getUasClient();
        const res = await uasClient.delete(`/api/1/groups/${group.groupId}/members/${currUser}`);
        if (res.ok()) { props.history.goBack(); }
    }

    const deleteGroup = async () => {
        const uasClient = await SessionClient.getUasClient();
        const res = await uasClient.delete(`/api/1/groups/${group.groupId}`);
        if (res.ok()) { props.history.goBack(); }
    }

    return (
        <Box marginY={2} display='flex'>
            <Button variant='outlined' className={classes.leave} onClick={() => setAction(GroupAction.Leave)}>
                Leave Group
            </Button>
            {group.ownerUserId === currUser &&
                <Button variant='contained' className={classes.delete} onClick={() => setAction(GroupAction.Delete)}>
                    Delete Group
                </Button>
            }
            <Dialog open={action > 0}>
                <DialogTitle>
                    {action === GroupAction.Leave ? 'Are you sure you want to leave this Group?' : 'Are you sure you want to delete this Group?'}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={() => setAction(0)}>
                        Cancel
                    </Button>
                    <Button onClick={action === GroupAction.Leave ? leaveGroup : deleteGroup} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export const GroupActions = withRouter(GroupActionsComponent);