import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    geofenceImage: {
        position: 'relative',
        width: '100%',
        height: 0,
        paddingBottom: 'calc(100% / 2)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        paddingTop: '25%',
        borderRadius: theme.shape.borderRadius
    },
    geofenceItemOverlay: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        height: 54,
        width: '100%',
        bottom: 0,
        pointerEvents: 'none',
        borderRadius: '0 0 4px 4px'
    },
    geofenceTitle: {
        position: 'absolute',
        fontSize: 22,
        lineHeight: '22px',
        color: 'white',
        textAlign: 'right',
        top: 8,
        right: 8,
        overflow: 'hidden',
        height: 22,
        margin: 0
    },
    geofenceSubtitle: {
        position: 'absolute',
        color: 'rgba(255, 255, 255, 0.9)',
        zIndex: 1,
        right: 8,
        bottom: 8,
        fontSize: 12,
        lineHeight: '12px',
        overflow: 'hidden',
        height: 12,
        margin: 0
    }
}))

export const EventPreview: React.FC<any> = props => {
    const classes = useStyles();

    return (
        <div className="position-relative">
            <div style={{ backgroundImage: `url('${props.img}')` }} className={classes.geofenceImage} />
            <div className={classes.geofenceItemOverlay}>
                <p className={classes.geofenceTitle}>{props.name}</p>
                <p className={classes.geofenceSubtitle}>You're in this area</p>
            </div>
        </div>
    )
}