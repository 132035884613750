import React, { useState, useRef, useEffect } from 'react';
import { Box, Typography, Grid, TextField, Button, makeStyles } from '@material-ui/core';
import { Geofence, SessionClient, GeofenceTranslation, GeofenceServiceUrls } from '@shout-sdk/client-sdk';
import { EventPreview } from './EventPreview';
import { ErrorSnackbar, LoadingButton, useUploadImage } from 'components/dashboard/common';
import { uploadImage } from 'components/app/helpers';

const useStyles = makeStyles(theme => ({
    header: {
        marginBottom: theme.spacing(2)
    },
    textField: {
        marginBottom: theme.spacing(2)
    }
}))

export const EditEvent: React.FC<any> = props => {
    const classes = useStyles();
    const event: Geofence = props.event;
    const [name, setName] = useState<string>(event.translations![0].title ?? "");
    const [img, setImage, error] = useUploadImage(500);
    const [isLoading, setLoading] = useState<boolean>(false)
    const imgRef = useRef<HTMLInputElement>(null);

    // get current name of event
    useEffect(() => {
        setName(event.translations![0].title ?? "");
    }, [event])

    const saveImage = (files: any) => {
        if (files) {
            setImage(files[0]);
        }
    }

    const isTitleInvalid = () => {
        return name.length < 4 || name.length > 80;
    }

    const saveChanges = async () => {
        setLoading(true);
        const gfsClient = await SessionClient.getGfsClient();
        const translation = new GeofenceTranslation();
        translation.language = 'eng';
        translation.title = name;
        if (name !== event.translations![0].title) {
            await gfsClient.post<any>(GeofenceServiceUrls.Geofence.ChangeTranslation, {
                geofenceId: event.geofenceNumberId,
                translation
            });
        }
        if (img) {
            const imageResponse = await gfsClient.post<any>('/api/1/geofence/change_image', { id: event.geofenceNumberId });
            if (imageResponse.ok()) {
                const { imageUploadDetails } = imageResponse.data;
                await uploadImage(img, imageUploadDetails, event.geofenceNumberId.toString());
            }
        }
        window.location.reload();
    }

    return (
        <Box marginY={2}>
            <Grid spacing={4} justify='center' container>
                {img &&
                    <Grid md={8} xs={12} item>
                        <EventPreview name={name} img={URL.createObjectURL(img)} />
                    </Grid>
                }
                <Grid md={12} xs={12} item>
                    <TextField
                        label="Change Event Name"
                        variant="outlined"
                        className={classes.textField}
                        error={isTitleInvalid()}
                        helperText={isTitleInvalid() && 'Between 4 and 80 characters'}
                        value={name}
                        onChange={ev => setName(ev.target.value)}
                        fullWidth
                    />
                    <Grid spacing={2} justify='flex-end' container>
                        <Grid item>
                            <Button variant="outlined" color="primary" onClick={() => imgRef.current!.click()}>
                                Change Image
                            </Button>
                        </Grid>
                        <Grid item>
                            <LoadingButton variant="contained" color="primary" loading={isLoading} onClick={() => saveChanges()} disabled={isTitleInvalid()}>
                                Save Changes
                            </LoadingButton>
                        </Grid>
                    </Grid>
                    <input type="file" accept="image/png, image/jpeg" ref={imgRef} style={{ display: 'none' }}
                        onChange={ev => saveImage(ev.target.files)} />
                </Grid>
            </Grid>
            <ErrorSnackbar open={error.length > 0} err={error} />
        </Box>
    )
}