import React from 'react';
import { Box, Typography, Divider, Container } from '@material-ui/core';

export const FormSection: React.FC<any> = props => {

    return (
        <Box>
            <Box marginY={5}>
                <Typography variant="h5">{props.title}</Typography>
                <Divider />
            </Box>
            <Container maxWidth="lg">
                {props.children}
            </Container>
        </Box>
    )
}