import { LoginView, RegisterView, RegisterIndividualView, InvitationView, DownloadAppView } from '../public';
import { Switch, Route } from 'react-router';
import React from 'react';
import { PrivateRoute } from './PrivateRoute';
import { DefaultRoute } from './DefaultRoute';
import { Home } from 'components/dashboard/home/Home';
import { AllStatusesView, CreateStatusView, StatusDetailView } from 'components/dashboard/statuses';
import { AllEventsView, EventDetailView, CreateEventView, CreateSessionView } from 'components/dashboard/events';
import { AllGeofencesView } from 'components/dashboard/locations';
import { AllGroupsView, GroupDetailView, CreateGroupView } from 'components/dashboard/groups';
import { UserProfileView, UserSearchView } from 'components/dashboard/user';
import { SearchGeofenceView, CreateGeofenceView, EditGeofenceView } from 'components/dashboard/geofences';
import { AllPendingView, ApproveBusinessView } from 'components/dashboard/pending';
import { AllDevicesView, CreatePreferenceView, EditPreferenceView } from 'components/dashboard/devices';

export const Routes = (
    <Switch>
        <Route path='/login' component={LoginView} />
        <Route path='/register' component={RegisterView} />
        <Route path='/signup' component={RegisterIndividualView} />
        <Route path='/invite' component={InvitationView} />
        <Route path='/download' component={DownloadAppView} />
        {/* <PrivateRoute exact path='/home' component={<Home />} /> */}
        <PrivateRoute exact path='/statuses' component={<AllStatusesView />} />
        <PrivateRoute exact path='/statuses/detail' component={<StatusDetailView />} />
        <PrivateRoute exact path='/statuses/new' component={<CreateStatusView />} />
        <PrivateRoute exact path='/events' component={<AllEventsView />} />
        <PrivateRoute exact path='/events/detail' component={<EventDetailView />} />
        <PrivateRoute exact path='/events/create' component={<CreateEventView />} />
        <PrivateRoute exact path='/events/session' component={<CreateSessionView />} />
        <PrivateRoute exact path='/locations' component={<AllGeofencesView />} />
        <PrivateRoute exact path='/groups' component={<AllGroupsView />} />
        <PrivateRoute exact path='/groups/detail' component={<GroupDetailView />} />
        <PrivateRoute exact path='/groups/create' component={<CreateGroupView />} />
        <PrivateRoute exact path='/profile' component={<UserProfileView />} />
        <PrivateRoute exact path='/search' component={<UserSearchView />} />
        <PrivateRoute exact path='/geofences' component={<SearchGeofenceView />} />
        <PrivateRoute exact path='/geofences/new' component={<CreateGeofenceView />} />
        <PrivateRoute exact path='/geofences/edit' component={<EditGeofenceView />} />
        <PrivateRoute exact path='/pending' component={<AllPendingView />} />
        <PrivateRoute exact path='/pending/approve' component={<ApproveBusinessView />} />
        <PrivateRoute exact path='/devices' component={<AllDevicesView />} />
        <PrivateRoute exact path='/devices/create' component={<CreatePreferenceView />} />
        <PrivateRoute exact path='/devices/edit' component={<EditPreferenceView />} />
        <Route exact path='/' component={DefaultRoute} />
    </Switch>
)