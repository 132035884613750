import { Geofence, SessionClient, GeofenceServiceUrls } from '@shout-sdk/client-sdk';
import React, { useState, useEffect } from 'react';
import { CurrentUser } from 'auth';

export const useUploadImage = (min: number): [File | null, React.Dispatch<React.SetStateAction<File | null>>, string] => {
    const [img, setImg] = useState<File | null>(null);
    const [errorMsg, setError] = useState('');
    // image file to check dimensions
    const imgFile = new Image();

    useEffect(() => {
        if (img) {
            if (img.size > 5242880) {
                setImg(null);
                setError('Your file is too big. Maximum file size is 5MB.');
            } else {
                setError('');
                imgFile.src = URL.createObjectURL(img);
            }
        }
    }, [img])

    imgFile.onload = () => {
        if (imgFile.width < min || imgFile.height < min) {
            setImg(null);
            setError(`Your image is too small. Minimum dimensions are ${min}x${min} pixels`);
        } else {
            setError('');
        }
    }

    return [img, setImg, errorMsg];
}

export const useMyGeofences = (): { geofences: Geofence[], isLoading: boolean } => {
    const [geofences, setGeofences] = useState<Geofence[]>([]);
    const [isLoading, setLoading] = useState(false);
    const { geofencesOwned } = CurrentUser.getProfile();

    useEffect(() => {
        const fetchMyGeofences = async () => {
            setLoading(true);
            const gfsClient = await SessionClient.getGfsClient();
            const res = await gfsClient.post<any>(GeofenceServiceUrls.Geofence.GetMultiple, { ids: geofencesOwned });
            if (res.ok()) {
                setGeofences(res.data.results);
                setLoading(false);
            }
        }
        if (geofencesOwned.length > 0) {
            fetchMyGeofences();
        }
    }, []);

    return { geofences, isLoading };
}