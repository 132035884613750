import React, { useState } from 'react';
import { Container, Box, Typography, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { BusinessInfo } from './BusinessInfo';
import { GeofenceSelect } from './GeofenceSelect';
import { LoadingButton } from 'components/dashboard/common';
import { GeoLocation, SessionClient, StatusPostingPermissions } from '@shout-sdk/client-sdk';

interface ApproveData {
    marker: GeoLocation,
    ownerGeofence: number | null,
    defaultGeofence: number | null
}

const ApproveBusiness: React.FC<any> = props => {

    const [data, setData] = useState<ApproveData>({ marker: new GeoLocation(), ownerGeofence: null, defaultGeofence: null });
    const [isLoading, setLoading] = useState(false);
    const { org } = props.location.state;

    const isBtnDisabled = (): boolean => {
        return data.marker.latitude === 0 && data.marker.longitude === 0;
    }

    const navigateToProfile = () => {
        props.history.push({ pathname: '/profile', state: { userId: org.userId, invite: org.invitationLastSent } });
    }

    const handleClick = async () => {
        setLoading(true);
        const uasClient = await SessionClient.getUasClient();
        // set business location
        const res = await uasClient.post<any>(`/api/1/registrations/organization/${org.userId}/location`, { ...data.marker });
        if (!res.ok()) { return; }
        // set default geofence, if selected
        if (data.defaultGeofence) {
            const res = await uasClient.post<any>('/api/1/profile/change_default_geofence', { userId: org.userId, geofenceId: data.defaultGeofence });
            if (!res.ok()) { return; }
        }
        // set geofence owner, if selected
        if (data.ownerGeofence) {
            const gfsClient = await SessionClient.getGfsClient();
            const res = await gfsClient.post(`/api/1/geofence/${data.ownerGeofence}/owner/${org.userId}`, {});
            if (!res.ok()) { return; }
        }
        // set posting permissions
        const permissions = new StatusPostingPermissions();
        const permissionsRes = await uasClient.post(`/api/1/profile/${org.userId}/status_posting_permissions`, permissions);
        if (!permissionsRes.ok()) { return; }
        navigateToProfile();
    }

    return (
        <Container maxWidth='lg'>
            <Box marginY={4}>
                <Typography variant='h2' color='primary' gutterBottom>
                    {org.organizationName}
                </Typography>
                <BusinessInfo org={org} />
                <GeofenceSelect onChange={(values: ApproveData) => setData(values)} />
                <Box textAlign='center' marginY={4}>
                    {org.progress.locationSet && org.progress.statusPostingPermissionsSet ?
                        <Button color='primary' variant='contained' onClick={navigateToProfile}>Go To Profile</Button> :
                        <LoadingButton color='primary' variant='contained' onClick={handleClick} loading={isLoading} disabled={isBtnDisabled()}>Create Business</LoadingButton>
                    }
                </Box>
            </Box>
        </Container>
    )
}

export const ApproveBusinessView = withRouter(ApproveBusiness);