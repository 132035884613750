import React, { useState, useEffect } from 'react';
import { Box, List, Typography, Divider, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, Menu, MenuItem } from '@material-ui/core';
import { SessionClient, LocalContentServiceUrls, CommentWithUserProfileInformation } from '@shout-sdk/client-sdk';
import { useStyles } from './styles';
import { getTime } from './common';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const CommentList: React.FC<any> = props => {
    const classes = useStyles();
    const [comments, setComments] = useState<CommentWithUserProfileInformation[]>([]);
    const [showOptions, setShowOptions] = useState(0);
    const { statusId } = props;

    useEffect(() => {
        const fetchComments = async () => {
            const lcsClient = await SessionClient.getLcsClient();
            const res = await lcsClient.get<any>(LocalContentServiceUrls.Status.GetComments(statusId));
            if (res.ok()) {
                setComments(res.data.comments);
            }
        }
        fetchComments();
    }, [])

    const deleteComment = async (comment: CommentWithUserProfileInformation) => {
        const lcsClient = await SessionClient.getLcsClient();
        const res = await lcsClient.delete<any>(LocalContentServiceUrls.Status.RemoveComment(statusId, comment.id));
        if(res.ok()) {
            window.location.reload();
        }
    }

    return (
        <Box>
            <Typography variant='h5'>Comments</Typography>
            <List className={classes.container}>
                {comments.map((comment, index) => (
                    <React.Fragment>
                        {index > 0 && <Divider />}
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar src={comment.userProfileBasicInformation.thumbnailUrl!} />
                            </ListItemAvatar>
                            <ListItemText primary={
                                <React.Fragment>
                                    <Typography color='primary' component='span'>
                                        {comment.userProfileBasicInformation.name}
                                    </Typography>
                                    <Typography color='textSecondary' variant='body2' component='span'>
                                        {` (${getTime(comment)})`}
                                    </Typography>
                                </React.Fragment>
                            } secondary={comment.message} />
                            <ListItemSecondaryAction>
                                <IconButton id={`${comment.id}`} onClick={() => setShowOptions(comment.id)}>
                                    <MoreVertIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Menu
                            anchorEl={document.getElementById(`${comment.id}`)}
                            open={showOptions === comment.id}
                            onClose={() => setShowOptions(0)}
                        >
                            <MenuItem onClick={() => deleteComment(comment)}>
                                Delete Comment
                            </MenuItem>
                        </Menu>
                    </React.Fragment>
                ))}
            </List>
            {comments.length === 0 &&
                <Typography color='textSecondary'>
                    No comments found.
                </Typography>
            }
        </Box>
    )
}