import React, { useState } from 'react';
import { Layout } from '../Layout';
import { RegisterForm } from './business/RegisterForm';
import { Auth } from 'auth';
import { RegisterSuccess } from './business/RegisterSuccess';

enum Steps {
    Initial = 0,
    Success = 1
}

export const RegisterView: React.FC<any> = props => {
    const [step, setStep] = useState(Auth.isAuthorized() ? Steps.Success : Steps.Initial);

    return (
        <Layout>
            {step === Steps.Initial &&
                <RegisterForm next={() => setStep(step + 1)} />
            }
            {step === Steps.Success &&
                <RegisterSuccess />
            }
        </Layout>
    )
}