import React from 'react';
import { makeStyles, Fab } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    fab: {
        position: 'fixed',
        bottom: 32,
        right: 32
    }
}))

export const FloatingFab: React.FC<any> = props => {
    const classes = useStyles();

    return (
        <Fab className={classes.fab} {...props}>
            {props.children}
        </Fab>
    )
}