import React from 'react';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, makeStyles } from '@material-ui/core';
import { GenderType } from '@shout-sdk/client-sdk';

const useStyles = makeStyles(theme => ({
    radioButtons: {
        justifyContent: 'space-around'
    },
    outlined: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 4,
        padding: theme.spacing(2)
    }
}))

export const GenderSelect: React.FC<any> = props => {
    const classes = useStyles();
    const { value, onChange } = props;

    return (
        <FormControl className={classes.outlined} margin="normal" fullWidth required>
            <FormLabel>Gender</FormLabel>
            <RadioGroup className={classes.radioButtons}
                value={value}
                onChange={ev => onChange(Number(ev.target.value))}
                row>
                <FormControlLabel
                    value={GenderType.Male}
                    control={<Radio color="primary" />}
                    label="Male"
                    labelPlacement="bottom"
                />
                <FormControlLabel
                    value={GenderType.Female}
                    control={<Radio color="primary" />}
                    label="Female"
                    labelPlacement="bottom"
                />
                <FormControlLabel
                    value={GenderType.Other}
                    control={<Radio color="primary" />}
                    label="Other"
                    labelPlacement="bottom"
                />
            </RadioGroup>
        </FormControl>
    )
}