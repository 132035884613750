import React, { useState, useEffect } from 'react';
import { Card, CardHeader, Avatar, IconButton, CardMedia, CardContent, Typography, CardActions } from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import CommentIcon from '@material-ui/icons/Comment';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { CurrentUser } from 'auth';
import clsx from 'clsx';
import { StatusCardCarousel } from '../all/StatusCardCarousel';
import { AddStatusRequest } from '@shout-sdk/client-sdk';
import { useStyles } from '../all/StatusCardStyle';

interface IProps {
    status: AddStatusRequest,
    imgs: File[]
}

export const StatusPreview: React.FC<IProps> = props => {
    const classes = useStyles();
    const [images, setImages] = useState<string[]>([]);
    const { status, imgs } = props;

    useEffect(() => {
        setImages(imgs.map(img => URL.createObjectURL(img)));
    }, [imgs])

    const getRemainingTime = (): string => {
        if (status.start) {
            return new Date(status.start).toLocaleString();
        }
        const seconds = status.expirationTimeInSeconds;

        const d = Math.floor(seconds / (3600 * 24));
        if (d > 0) { return d + (d === 1 ? " day " : " days ") + 'left' }
        const h = Math.floor(seconds % (3600 * 24) / 3600);
        if (h > 0) { return h + (h === 1 ? " hour " : " hours ") + 'left' }
        const m = Math.floor(seconds % 3600 / 60);
        if (m > 0) { return m + (m === 1 ? " minute " : " minutes ") + 'left' }

        return seconds + ' seconds left';
    }

    return (
        <Card style={{ width: 345 }} className={classes.card}>
            <CardHeader
                avatar={
                    <Avatar className={classes.avatar} src={CurrentUser.getProfile().thumbnailUrl!} />
                }
                action={
                    <IconButton aria-label="settings">
                        <MoreVertIcon />
                    </IconButton>
                }
                classes={{
                    subheader: clsx(status.start && classes.scheduled, classes.active)
                }}
                title={CurrentUser.getProfile().name}
                subheader={getRemainingTime()}
            />
            {images.length === 1 &&
                <CardMedia
                    className={classes.media}
                    image={images[0]}
                />
            }
            {images.length > 1 &&
                <StatusCardCarousel imgs={images} />
            }
            <CardContent className={classes.contentText}>
                <Typography variant="body2" color="textSecondary">
                    {status.translation.message}
                </Typography>
            </CardContent>
            <CardActions className={classes.stats}>
                <FavoriteIcon className={classes.statIcon} /> 0
                <CommentIcon className={classes.statIcon} /> 0
            </CardActions>
        </Card>
    )
}