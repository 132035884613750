import React from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import { AddStatusRequest } from '@shout-sdk/client-sdk';
import { useState } from 'react';
import { useEffect } from 'react';

interface IProps {
    status: AddStatusRequest,
    setStatus: React.Dispatch<React.SetStateAction<AddStatusRequest>>,
}

export const SchedulePicker: React.FC<IProps> = props => {

    const [endDate, setEndDate] = useState(null);
    const { status, setStatus } = props;

    useEffect(() => {
        if (status.start && endDate) {
            // @ts-ignore
            const diffTime = Math.floor((endDate - new Date(status.start)) / 1000);
            setStatus({ ...status, expirationTimeInSeconds: diffTime });
        }
    }, [status.start, endDate]);

    const isInValidDate = (): boolean => {
        if (status.start && endDate) {
            return new Date(status.start) > endDate!;
        } else {
            return false;
        }
    }

    return (
        <div>
            <DateTimePicker
                label="Start Date/Time"
                inputVariant="outlined"
                value={status.start ? new Date(status.start) : null}
                onChange={date => setStatus({ ...status, start: date!.toISOString() })}
                margin="normal"
                ampm={false}
                minDate={new Date()}
                error={isInValidDate()}
                helperText={isInValidDate() && "Start date can't be after end date!"}
                fullWidth
            />
            <DateTimePicker
                label="End Date/Time"
                inputVariant="outlined"
                value={endDate}
                onChange={(date: any) => setEndDate(date!)}
                helperText={isInValidDate() && "End date can't be before start date!"}
                margin="normal"
                ampm={false}
                minDate={new Date()}
                error={isInValidDate()}
                fullWidth
            />
        </div>
    )
}