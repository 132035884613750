import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Container, Typography, Box, Grid, TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { Geofence, TvPreferences, SessionClient, GeofencesResponse, SelectedContentCategorySubcategories } from '@shout-sdk/client-sdk';
import { useUploadImage, LoadingButton, ErrorSnackbar } from 'components/dashboard/common';
import { CurrentUser } from 'auth';
import { uploadImage } from 'components/app/helpers';
import { EventCategorySelect } from 'components/dashboard/events/create/EventCategorySelect';

const EditPreference: React.FC<any> = props => {

    const { preference } = props.location.state;
    const [geofences, setGeofences] = useState<Geofence[] | null>([]);
    const [preferences, setPreferences] = useState<TvPreferences>(new TvPreferences());
    const [image, setImage, imgErr] = useUploadImage(720);
    const [isLoading, setLoading] = useState(false);
    const [err, setErr] = useState('');
    const imgRef = useRef<any>(null);

    useEffect(() => {
        const fetchGeofences = async () => {
            const gfsClient = await SessionClient.getGfsClient();
            const res = await gfsClient.post<GeofencesResponse>('/api/1/geofence/nearby', { location: CurrentUser.getOrganizationInfo().location });
            if (res.ok()) {
                setGeofences(res.data!.results);
            }
        }
        fetchGeofences();
        // @ts-ignore
        setPreferences({ ...preference.preferences });
    }, []);

    const saveImage = (files: any) => {
        if (files) {
            setImage(files[0]);
        }
    }

    const handleCategories = (categories: SelectedContentCategorySubcategories[] | null) => {
        setPreferences({ ...preferences, contentCategories: categories });
    }

    const deletePref = async () => {
        const tvsClient = await SessionClient.getTvPreferenceServiceClient();
        const res = await tvsClient.delete(`/api/1/preferences/${preference.id}`);
        if (res.ok()) {
            props.history.push('/devices');
        }
    }

    const saveChanges = async () => {
        setLoading(true);
        const tvsClient = await SessionClient.getTvPreferenceServiceClient();
        const res = await tvsClient.post<any>(`/api/1/preferences/${preference.id}`, { preferences });
        if (!res.ok()) { setErr(res.data.errors[0]); return; }
        if (image) {
            const imgRes = await tvsClient.get<any>(`/api/1/preferences/${preference.id}/image_upload_ticket`);
            if (imgRes.ok()) {
                await uploadImage(image, imgRes.data.fileUploadDetails, preference.id);
            }
        }
        props.history.push('/devices');
    }

    return (
        <Container maxWidth='lg'>
            <Box marginY={4}>
                <Typography variant='h2' color='primary' gutterBottom>Edit TV Preference</Typography>
                <Box marginY={4}>
                    <Grid spacing={4} container>
                        <Grid md={6} xs={12} item>
                            <Box marginY={2}>
                                <TextField label='Preference Name' variant='outlined'
                                    value={preferences.name} onChange={ev => setPreferences({ ...preferences, name: ev.target.value })} fullWidth />
                                <FormControl variant='outlined' margin='normal' fullWidth>
                                    <InputLabel>Select Geofences</InputLabel>
                                    <Select
                                        multiple
                                        labelWidth={125}
                                        value={preferences.geofenceIds}
                                        onChange={(ev: any) => setPreferences({ ...preferences, geofenceIds: ev.target.value })}
                                    >
                                        {geofences && geofences.map(geofence => (
                                            <MenuItem key={geofence.geofenceNumberId} value={geofence.geofenceNumberId}>
                                                {geofence.translations![0].title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={preferences.includeContentFromChildGeofences}
                                            onChange={ev => setPreferences({ ...preferences, includeContentFromChildGeofences: ev.target.checked })}
                                            color="primary"
                                        />
                                    }
                                    label="Include content from child geofences"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={preferences.allowAdultContent}
                                            onChange={ev => setPreferences({ ...preferences, allowAdultContent: ev.target.checked })}
                                            color="primary"
                                        />
                                    }
                                    label="Allow adult content"
                                />
                                <Box marginY={2}>
                                    {(image || preference.backgroundImageUrl) &&
                                        <img style={{ height: 200, width: '100%', objectFit: 'cover', marginBottom: 16 }}
                                            src={image ? URL.createObjectURL(image) : preference.backgroundImageUrl} />}
                                    <Box display='flex'>
                                        <Button variant='outlined' color='primary' onClick={() => imgRef.current!.click()}>{preference.backgroundImageUrl ? 'CHANGE IMAGE' : 'ADD IMAGE'}</Button>
                                        <Box marginX={2}>
                                            <Typography color='error'>
                                                <Button variant='outlined' color='inherit' onClick={deletePref}>Delete Preference</Button>
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <input type="file" accept="image/png, image/jpeg" ref={imgRef} style={{ display: 'none' }}
                                        onChange={ev => saveImage(ev.target.files)} />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid md={6} xs={12} item>
                            <EventCategorySelect onChange={handleCategories}
                                preSelected={preference.preferences.contentCategories ? preference.preferences.contentCategories.map((cat: any) => cat.id) : null} />
                        </Grid>
                    </Grid>
                    <Box textAlign='center' marginY={4}>
                        <LoadingButton color='primary' variant='contained' loading={isLoading} onClick={saveChanges}>Save Changes</LoadingButton>
                    </Box>
                </Box>
                <ErrorSnackbar open={err.length > 0} err={err} />
                <ErrorSnackbar open={imgErr.length > 0} err={imgErr} />
            </Box>
        </Container>
    )
}

export const EditPreferenceView = withRouter(EditPreference);