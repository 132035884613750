import React, { useState, useEffect } from 'react';
import { FormSection } from './FormSection';
import { Map } from 'components/dashboard/common';
import { Box, makeStyles, Chip, Typography, Avatar } from '@material-ui/core';
import { Geofence, SessionClient, GeofencesResponse, GeofenceServiceUrls } from '@shout-sdk/client-sdk';
import { CurrentUser } from 'auth';

const useStyles = makeStyles(theme => ({
    picker: {
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        overflow: 'auto'
    },
    chip: {
        margin: theme.spacing(0.5, 0)
    }
}))

export const EventLocationPicker: React.FC<any> = props => {
    const classes = useStyles();
    const [postable, setPostable] = useState<Geofence[]>([]);
    const [selected, setSelected] = useState<number>(0);

    useEffect(() => {
        const { geofencesOwned } = CurrentUser.getProfile();
        const fetchOwnGeofences = async () => {
            const gfsClient = await SessionClient.getGfsClient();
            const res = await gfsClient.post<GeofencesResponse>(GeofenceServiceUrls.Geofence.GetMultiple,
                { ids: geofencesOwned });
            if (res.ok()) {
                // filter only permanent geofences
                setPostable(res.data!.results!.filter((geofence: Geofence) => {
                    return geofence.endDate === null;
                }));
            }
        }
        if (geofencesOwned.length > 0) {
            fetchOwnGeofences();
        }
    }, [])

    useEffect(() => {
        if (postable.length > 0) setSelected(postable[0].geofenceNumberId);
    }, [postable])

    useEffect(() => {
        if (selected > 0) {
            // return geofence to parent component
            const geofence = postable.find(geofence => geofence.geofenceNumberId === selected);
            Map.displayGeofence(geofence!);
            props.onChange(geofence);
        }
    }, [selected])

    return (
        <FormSection title="Location">
            <Typography color="textSecondary" gutterBottom>Select a Geofence for the Event</Typography>
            <Box position="relative">
                <Map readonly height={250} />
                <Box className={classes.picker}>
                    {postable.map(geofence => (
                        <Chip key={geofence.geofenceNumberId}
                            label={geofence.translations![0].title}
                            className={classes.chip}
                            color="primary"
                            variant={geofence.geofenceNumberId === selected ? 'default' : 'outlined'}
                            avatar={<Avatar src={geofence.thumbnailImageUrl ?? ""} />}
                            onClick={() => setSelected(geofence.geofenceNumberId)}
                            clickable />
                    ))}
                </Box>
            </Box>
        </FormSection>
    )
}