import React from 'react';
import { Grid, makeStyles, Container, CssBaseline, Paper, Box, Typography, Hidden, Slide } from '@material-ui/core';
import { Steps } from './Steps';
import { Map } from 'components/dashboard/common';
import { StatusPreview } from './StatusPreview';
import { StepHeader } from './StepHeader';

const useStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(4, 0),
        overflow: 'auto',
        height: '80vh',
        textAlign: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    rightContainer: {
        margin: theme.spacing(4, 0),
        height: '80vh',
        paddingTop: theme.spacing(2),
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    paper: {
        height: '100%',
        width: '100%'
    },
    statusContainer: {
        position: 'absolute'
    },
    previewTitle: {
        marginBottom: theme.spacing(4)
    }
}));

export const Layout: React.FC<any> = props => {
    const classes = useStyles();
    const { step, status, imgs } = props;

    return (
        <Box>
            <StepHeader step={step} />
            <Grid container component="main">
                <CssBaseline />
                <Grid item xs={12} md={6}>
                    <Container className={classes.container}>
                        {props.children}
                    </Container>
                </Grid>
                <Hidden smDown>
                    <Grid item md={6}>
                        <Slide in={step !== Steps.Category} direction='up' timeout={{ enter: 700, exit: 400 }} unmountOnExit>
                            <Container className={classes.rightContainer} style={{ height: 'auto' }}>
                                <Box className={classes.statusContainer}>
                                    <Typography className={classes.previewTitle} variant="h4">Status Preview</Typography>
                                    <StatusPreview status={status} imgs={imgs} />
                                </Box>
                            </Container>
                        </Slide>
                        <Slide in={step === Steps.Category} direction='up' timeout={{ enter: 700, exit: 400 }} unmountOnExit>
                            <Container className={classes.rightContainer}>
                                <Paper elevation={6} className={classes.paper}>
                                    <Map readonly height={'100%'} />
                                </Paper>
                            </Container>
                        </Slide>
                    </Grid>
                </Hidden>
            </Grid>
        </Box>
    )
}