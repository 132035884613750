import { OrganizationInformation, SessionClient, UserProfilePrivateInformation, InitialServiceInformationResponse } from '@shout-sdk/client-sdk';

// Stores the app's global objects in localStorage
export class Store {

  public static readonly KEY = 'appStore';

  public static get(): Store {
    const appStore = localStorage.getItem(this.KEY);
    if (appStore !== null) {
      return JSON.parse(appStore);
    }
    return new Store();
  }

  public static set(appStore: Store): void {
    localStorage.setItem(this.KEY, JSON.stringify(appStore));
  }

  public static clear() {
    localStorage.removeItem(this.KEY);
  }

  public static update(updateDefinition: any) {
    const appStore: any = this.get();
    for (const update of Object.keys(updateDefinition)) {
      appStore[update] = updateDefinition[update];
    }
    this.set(appStore);
  }

  public serviceInfo: InitialServiceInformationResponse | undefined;

  public currentUser?: UserProfilePrivateInformation;

  public currentOrg?: OrganizationInformation;

  public session: SessionClient | undefined;

  public registrationInfo?: {
    emailAddress: string,
    emailToken: string,
    fullName: string,
  };

  private constructor() { }

}
