import * as React from 'react';
import { Redirect } from 'react-router';
import { Store } from '../../auth';

export const DefaultRoute: React.FC<any> = props => {
    return (
        Store.get().session === undefined ?
            <Redirect to='/login' /> :
            <Redirect to='/statuses' />
    );
}	
