import React, { useState } from 'react';
import { Box, makeStyles, Typography, Grow, IconButton, Menu, MenuItem } from '@material-ui/core';
import { ReturnedStatus, SessionClient } from '@shout-sdk/client-sdk';
import { StatusCardCarousel } from 'components/dashboard/statuses/all/StatusCardCarousel';
import { dateOptions, timeOptions } from 'components/app/constants';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useRef } from 'react';
import { areDatesOnSameDay } from 'components/app/helpers';

const imageStyle = {
    height: 150,
    borderRadius: 4,
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    button: {
        position: 'absolute',
        zIndex: 2,
        right: 0,
        top: '-8px'
    }
}))

export const ScheduleCard: React.FC<any> = props => {
    const classes = useStyles();
    const session: ReturnedStatus = props.session;
    const [showOptions, setShowOptions] = useState(false);
    const optionRef = useRef(null);
    const { defaultImage } = props;

    const renderTime = () => {
        const start = new Date(session.publicationTime ?? "");
        // @ts-ignore
        const end = new Date(session.expirationTime);
        const isSameDay = areDatesOnSameDay(start, end);
        return isSameDay ?
            `${start.toLocaleTimeString([], timeOptions)} - ${end.toLocaleTimeString([], timeOptions)}` :
            `${start.toLocaleString([], dateOptions)} - ${end.toLocaleString([], dateOptions)}`;
    }

    const deleteSession = async () => {
        const lcsClient = await SessionClient.getLcsClient();
        const res = await lcsClient.post<any>('/api/1/status/delete', { statusId: session.statusId });
        if (res.ok()) { window.location.reload() }
    }

    return (
        <Grow in>
            <Box className={classes.container}>
                {(session.images?.length ?? 0) > 0 ?
                    <StatusCardCarousel imgs={session.images} style={imageStyle} /> :
                    <StatusCardCarousel imgs={[defaultImage]} style={{ ...imageStyle, opacity: 0.5 }} />
                }
                <Box marginTop={1} position='relative'>
                    <IconButton ref={optionRef} className={classes.button} onClick={() => setShowOptions(true)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={optionRef.current}
                        open={showOptions}
                        onClose={() => setShowOptions(false)}
                    >
                        <MenuItem onClick={deleteSession}>
                            Delete Session
                        </MenuItem>
                    </Menu>
                    <Typography variant="h6" color="primary">{renderTime()} {session.roomName && ` (${session.roomName})`}</Typography>
                    <Typography color="secondary">{session.translations![0].title}</Typography>
                    <Typography color="secondary" style={{ fontWeight: 'lighter' }}>{session.translations![0].message}</Typography>
                </Box>
            </Box>
        </Grow>
    )
}