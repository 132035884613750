import React, { useState } from 'react';
import { Box, Typography, Paper, InputBase, makeStyles, IconButton, Button, List, ListItem, ListItemText, Divider, ListItemSecondaryAction } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import DeleteIcon from '@material-ui/icons/Delete';
import { SessionClient, GroupMemberRole } from '@shout-sdk/client-sdk';
import { emailRegex } from 'components/app/constants';
import { ImportCsv } from './ImportCsv';

const useStyles = makeStyles(theme => ({
    inputContainer: {
        width: 300,
        padding: theme.spacing(0.5, 2),
        display: 'flex',
        marginRight: theme.spacing(4)
    },
    input: {
        width: '100%'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    emailList: {
        maxHeight: 300,
        maxWidth: 300,
        overflow: 'auto'
    },
    csvInput: {
        display: 'none'
    }
}));

export const AddGroupMembers: React.FC<any> = props => {
    const classes = useStyles();
    const [emails, setEmails] = useState<string[]>([]);
    const [text, setText] = useState('');
    const [emailError, setError] = useState(false);

    const addEmail = (event: any) => {
        event.preventDefault();
        if (emailRegex.test(text.trim())) {
            const arrCopy = [...emails];
            arrCopy.push(text.trim());
            setEmails(arrCopy);
            setText('');
            setError(false);
        } else {
            setError(true);
        }
    }

    const removeEmail = (index: number) => {
        const arrCopy = [...emails];
        arrCopy.splice(index, 1);
        setEmails(arrCopy);
    }

    const sendInvites = async () => {
        const uasClient = await SessionClient.getUasClient();
        const members = emails.map((email: string) => ({ emailAddress: email, role: GroupMemberRole.Normal }));
        const res = await uasClient.post<any>(`/api/1/groups/${props.groupId}/members`, { members });
        if (res.ok()) { window.location.reload(); }
    }

    return (
        <Box marginY={6}>
            <Typography variant='h6' gutterBottom>Add New Members</Typography>
            <Typography color='textSecondary'>Enter a list of emails or import a .csv file. People who are not registered will receive an invitation email.</Typography>
            <Box marginY={2}>
                <Box className={classes.headerContainer}>
                    <form onSubmit={addEmail}>
                        <Paper className={classes.inputContainer}>
                            <InputBase className={classes.input} value={text} onChange={ev => setText(ev.target.value)} placeholder='Enter an email' />
                            <IconButton type='submit'>
                                <SendIcon color='primary' />
                            </IconButton>
                        </Paper>
                    </form>
                    <ImportCsv emails={emails} setEmails={setEmails} />
                </Box>
                {emailError &&
                    <Typography color='error'>Please enter a valid email address!</Typography>
                }
            </Box>
            {emails.length > 0 &&
                <Box>
                    <Box className={classes.headerContainer}>
                        <Typography>{emails.length} emails entered</Typography>
                        <Box marginX={2}>
                            <Button variant='contained' color='primary' onClick={sendInvites}>Add Users</Button>
                        </Box>
                    </Box>
                    <List className={classes.emailList}>
                        {emails.map((email, index) => (
                            <React.Fragment key={index}>
                                {index > 0 && <Divider />}
                                <ListItem>
                                    <ListItemText primary={email} />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => removeEmail(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem> 
                            </React.Fragment>
                        ))}
                    </List>
                </Box>
            }
        </Box>
    )
}