import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { GroupInvitedMember, GroupMemberDetails, SessionClient, GroupMemberRole } from '@shout-sdk/client-sdk';
import { MembersList } from './MembersList';
import { InvitesList } from './InvitesList';

interface IProps {
    invites: GroupInvitedMember[] | null,
    groupId: number
}

export const GroupMemberGrid: React.FC<IProps> = props => {

    const [members, setMembers] = React.useState<GroupMemberDetails[]>([]);
    const { invites, groupId } = props;

    useEffect(() => {
        const fetchMembers = async () => {
            const uasClient = await SessionClient.getUasClient();
            const res = await uasClient.get<any>(`/api/1/groups/${groupId}/members`);
            if (res.ok()) {
                setMembers(res.data.members);
            }
        }
        if (groupId > 0) {
            fetchMembers();
        }
    }, [groupId]);

    const admins = members.filter(member => member.role === GroupMemberRole.Admin);
    const normal = members.filter(member => member.role === GroupMemberRole.Normal);

    return (
        <Box marginTop={10}>
            <Grid spacing={2} container>
                <Grid md={4} xs={12} item>
                    <Typography variant='h6' color='primary'>Group Admins ({admins.length})</Typography>
                    <MembersList members={admins} groupId={groupId} />
                </Grid>
                <Grid md={4} xs={12} item>
                    <Typography variant='h6'>Group Members ({normal.length})</Typography>
                    <MembersList members={normal} groupId={groupId} />
                </Grid>
                {invites && invites.length > 0 &&
                    <Grid md={4} xs={12} item>
                        <Typography variant='h6' color='error'>Pending Invites ({invites.length})</Typography>
                        <InvitesList invites={invites} groupId={groupId} />
                    </Grid>
                }
            </Grid>
        </Box>
    )
}