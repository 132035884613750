import React, { useState } from 'react';
import { Card, Grow, IconButton, CardHeader, CardContent, FormControl, InputLabel, Select, MenuItem, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { SessionClient, Optional } from '@shout-sdk/client-sdk';

export const DeviceCard: React.FC<any> = props => {

    const { device, preferences } = props;
    const [name, setName] = useState(device.name);
    const [showEdit, setShowEdit] = useState(false);

    const changePreference = async (id: string) => {
        const tvsClient = await SessionClient.getTvPreferenceServiceClient();
        const res = await tvsClient.patch(`/api/1/devices/${device.id}`, { name: null, preferenceId: new Optional(id) });
        if (res.ok()) {
            window.location.reload();
        }
    }

    const changeName = async () => {
        const tvsClient = await SessionClient.getTvPreferenceServiceClient();
        const res = await tvsClient.patch(`/api/1/devices/${device.id}`, { name: new Optional(name), preferenceId: null });
        if (res.ok()) {
            window.location.reload();
        }
    }

    return (
        <Grow in>
            <Card>
                <CardHeader
                    action={
                        <IconButton onClick={() => setShowEdit(true)}>
                            <EditIcon fontSize='small' />
                        </IconButton>
                    }
                    title={device.name ? device.name : `Device no. ${device.id}`}
                />
                <CardContent>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel>
                            Select preference
                        </InputLabel>
                        <Select labelWidth={130} value={device.tvPreferences ? device.tvPreferences.id : null} onChange={(ev: any) => changePreference(ev.target.value)}>
                            {preferences.map((preference: any) => (
                                <MenuItem key={preference.id} value={preference.id}>
                                    {preference.preferences.name ? preference.preferences.name : 'Preference no. ' + preference.id}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </CardContent>
                <Dialog
                    open={showEdit}
                    onClose={() => setShowEdit(false)}
                    fullWidth
                >
                    <DialogTitle>Edit Device Name</DialogTitle>
                    <DialogContent>
                        <TextField label='Edit Name' variant='outlined' value={name}
                            onChange={ev => setName(ev.target.value)} fullWidth />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setShowEdit(false)} color="primary">
                            Cancel
                    </Button>
                        <Button onClick={changeName} color="primary" autoFocus>
                            Save
                    </Button>
                    </DialogActions>
                </Dialog>
            </Card>
        </Grow>
    )
}