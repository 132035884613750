import React from 'react';
import { FormControl, InputLabel, Select, Chip, MenuItem, makeStyles } from '@material-ui/core';
import allLanguages from '../../../app/languages.json';

const useStyles = makeStyles(theme => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    chip: {
        margin: 2
    }
}))

export const MultiChipSelect: React.FC<any> = props => {
    const classes = useStyles();
    const { langs, setLangs } = props;

    const handleDelete = (val: string) => {
        const newArr = langs.filter((lang: string) => lang !== val);
        setLangs(newArr);
    }

    return (
        <FormControl variant="outlined" margin="normal" fullWidth required>
            <InputLabel>Languages</InputLabel>
            <Select
                value={langs}
                onChange={(ev: any) => setLangs(ev.target.value)}
                labelWidth={90}
                renderValue={selected => (
                    <div className={classes.chips}>
                        {(selected as string[]).map(value => (
                            <Chip key={value} label={value.toUpperCase()}
                                className={classes.chip} onDelete={() => handleDelete(value)} />
                        ))}
                    </div>
                )}
                multiple
                error={langs.length === 0}
            >
                {allLanguages.map((lang: any) => (
                    <MenuItem key={lang.LanguageName} value={lang.ISO_639_2}>
                        {lang.LanguageName}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}