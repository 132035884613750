import React, { useState, useEffect } from 'react';
import { Steps } from './Steps';
import { Layout } from './Layout';
import { StatusContent, StatusCategory, StatusOptions } from './steps/index';
import { Button, makeStyles } from '@material-ui/core';
import { AddStatusRequest, LocalContentServiceConstants, SessionClient, AddStatusResponse, LocalContentServiceUrls } from '@shout-sdk/client-sdk';
import { ErrorSnackbar } from 'components/dashboard/common';
import { uploadImage } from 'components/app/helpers';
import { withRouter } from 'react-router';

const useStyles = makeStyles(theme => ({
    nextBtn: {
        float: 'right',
        margin: theme.spacing(2, 0)
    }
}))

const CreateStatus: React.FC<any> = props => {
    const classes = useStyles();
    const [step, setStep] = useState(Steps.Content);
    const [status, setStatus] = useState<AddStatusRequest>(new AddStatusRequest());
    const [imgs, setImages] = useState<File[]>([]);
    const [eventDates, setEventDates] = useState<Date[]>([]);
    const [err, setErr] = useState('');

    useEffect(() => {
        setStatus({ ...status, numberOfAttachedImages: imgs.length });
    }, [imgs])

    const renderStep = () => {
        switch (step) {
            case Steps.Content: return <StatusContent status={status} setStatus={setStatus} setImages={setImages} />
            case Steps.Category: return <StatusCategory status={status} setStatus={setStatus} setEventDates={setEventDates} />
            case Steps.Options: return <StatusOptions status={status} setStatus={setStatus} eventDates={eventDates} />
        }
    }

    const isBtnDisabled = (): boolean => {
        switch (step) {
            case Steps.Content: {
                return !status.translation.message || status.translation.message.length < 4 || status.translation.message.length > LocalContentServiceConstants.maxStatusMessageLength
            }
            case Steps.Category: {
                return status.contentSubcategory === null || status.contentSubcategory === 0;
            }
            case Steps.Options: {
                const { targetAudience } = status;
                if (status.expirationTimeInSeconds < LocalContentServiceConstants.minStatusExpirationSeconds) { return true }
                return !targetAudience.males && !targetAudience.females && !targetAudience.adultContent;
            }
            default: return false;
        }
    }

    const handleClick = () => {
        step < Steps.Options ? setStep(step + 1) : createStatus();
    }

    const createStatus = async () => {
        const lcsClient = await SessionClient.getLcsClient();
        const res = await lcsClient.post<AddStatusResponse>(LocalContentServiceUrls.Status.Add, status);
        if (res.ok()) {
            if (res.data!.imageUploadDetails.length > 0) {
                res.data!.imageUploadDetails.forEach(async (img, index) => {
                    await uploadImage(imgs[index], img, res.data!.statusId!.toString());
                })
            }
            props.history.push('/statuses');
        } else {
            setErr(res.data!.errors![0])
        }
    }

    return (
        <Layout step={step} status={status} imgs={imgs}>
            {renderStep()}
            <div>
                <Button variant="contained" color="primary" className={classes.nextBtn} onClick={handleClick} disabled={isBtnDisabled()}>
                    {step !== Steps.Options ? 'NEXT' : 'PUBLISH'}
                </Button>
            </div>
            {err &&
                <ErrorSnackbar open={err.length > 0} err={err} />
            }
        </Layout>
    )
}

export const CreateStatusView = withRouter(CreateStatus);