import { Store } from './store';
import { OrganizationInformation, SessionClient, UserAccountServiceUrls, UserProfilePrivateInformation, AccountType, HttpAndDataProcessorResult, GetPrivateProfileInformationResponse, LocalContentServiceUrls, GetPublicOrganizationInformationResponse } from '@shout-sdk/client-sdk';

export class CurrentUser {

  public static getProfile(): UserProfilePrivateInformation {
    return Store.get().currentUser!;
  }

  public static getOrganizationInfo(): OrganizationInformation {
    const orgInfo = Store.get().currentOrg;
    if (orgInfo === undefined || orgInfo === null) {
      return new OrganizationInformation();
    }
    else {
      return orgInfo;
    }
  }

  public static getAccountType(): number {
    return this.getProfile().accountType;
  }

  public static isStaff(): boolean {
    return this.getAccountType() === AccountType.Staff;
  }

  public static async set(): Promise<boolean> {
    const profileInfoResponse = await this.getProfileInformation();

    if (!profileInfoResponse.ok()) {
      return false;
    }

    const profileInfo = profileInfoResponse.data!.profileInformation!;
    Store.update({
      currentUser: profileInfo,
    });

    if (profileInfo.organizationId) {
      const currentOrg = await this.getOrganiazationInformation(profileInfo.organizationId);
      Store.update({
        currentOrg: currentOrg.data!.organizationInformation
      })
    }

    return true;
  }

  private static async getProfileInformation(): Promise<HttpAndDataProcessorResult<GetPrivateProfileInformationResponse>> {
    const uasClient = await SessionClient.getUasClient();
    return await uasClient.get<GetPrivateProfileInformationResponse>(UserAccountServiceUrls.UserProfile.GetPrivateInformation);
  }

  private static async getOrganiazationInformation(organizationId: number): Promise<HttpAndDataProcessorResult<GetPublicOrganizationInformationResponse>> {
    const lcsClient = await SessionClient.getLcsClient();
    return await lcsClient.get<GetPublicOrganizationInformationResponse>(LocalContentServiceUrls.Organization.GetOrganization(organizationId));
  }
}
