import React, { useState } from 'react';
import { Container, Box, Typography, TextField, FormControlLabel, Checkbox, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { CreateGroupRequest, GroupTranslation, SessionClient } from '@shout-sdk/client-sdk';

const initialState = new CreateGroupRequest();
const translation = new GroupTranslation();
translation.language = 'eng';
initialState.translations = [translation];

const CreateGroup: React.FC<any> = props => {

    const [group, setGroup] = useState<CreateGroupRequest>(initialState);

    const handleChange = (ev: any) => {
        // @ts-ignore
        translation[ev.target.id] = ev.target.value.length === 0 ? null : ev.target.value;
        setGroup({ ...group, translations: [translation] });
    }

    const createGroup = async () => {
        const uasClient = await SessionClient.getUasClient();
        const res = await uasClient.post('/api/1/groups', group);
        if (res.ok()) {
            props.history.push('/groups');
        }
    }

    return (
        <Container maxWidth='lg'>
            <Box marginY={4}>
                <Typography color='primary' variant='h2'>Create New Group</Typography>
                <Box marginY={4}>
                    <TextField id='title' variant='outlined' label='Enter a group name' margin='normal' style={{ display: 'flex', maxWidth: 500 }}
                        value={group.translations[0].title} onChange={handleChange} helperText='Minimum of 2 characters' required />
                    <TextField id='description' variant='outlined' label='Enter a group description (optional)' margin='normal' style={{ display: 'flex', maxWidth: 500 }}
                        value={group.translations[0].description} onChange={handleChange} multiline />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={group.preferences.allowMemberPosts}
                                onChange={ev => setGroup({ ...group, preferences: { allowMemberPosts: ev.target.checked } })}
                                color="primary"
                            />
                        }
                        label="Allow group members to post to the group"
                    />
                </Box>
                <Button variant='contained' color='primary' onClick={createGroup}>
                    Create Group
                </Button>
            </Box>
        </Container>
    )
}

export const CreateGroupView = withRouter(CreateGroup);