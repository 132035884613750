import React from 'react';
import { List, Box, Typography, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router';
import SettingsIcon from '@material-ui/icons/Settings';

const PreferenceListComponent: React.FC<any> = props => {

    const { preferences } = props;

    return (
        <Box marginY={4}>
            {preferences.length > 0 &&
                <React.Fragment>
                    <Typography color='primary' variant='h5' gutterBottom>Your Preferences</Typography>
                    <List style={{ maxWidth: 400 }}>
                        {preferences.map((preference: any) => (
                            <ListItem divider button onClick={() => props.history.push({ pathname: '/devices/edit', state: { preference } })}>
                                <ListItemAvatar>
                                    <Avatar src={preference.backgroundImageUrl}>
                                        {!preference.backgroundImageUrl && <SettingsIcon />}
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={preference.preferences.name ? preference.preferences.name : 'Preference no. ' + preference.id}
                                    secondary={`Number of Geofences: ${preference.preferences.geofenceIds.length}`} />
                            </ListItem>
                        ))}
                    </List>
                </React.Fragment>
            }
        </Box>

    )
}

export const PreferenceList = withRouter(PreferenceListComponent);