import React, { useState, useRef } from 'react';
import { Box, Avatar, Typography, Fab, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { IProps } from './UserProfileProps';
import { useStyles } from './UserHeaderStyles';
import { SessionClient } from '@shout-sdk/client-sdk';
import { uploadImage } from 'components/app/helpers';
import { LoadingButton } from 'components/dashboard/common';
import { CurrentUser } from 'auth';
import PersonIcon from '@material-ui/icons/Person';

export const UserHeader: React.FC<IProps> = props => {
    const classes = useStyles();

    const { user, edit } = props;
    const isOwner = user.userId === CurrentUser.getProfile().userId;
    const [showEdit, setShowEdit] = useState(false);
    const [monoPic, setMono] = useState<File | null>(null);
    const [profilePic, setProfilePic] = useState<File | null>(null);
    const [coverPic, setCover] = useState<File | null>(null);
    const [isLoading, setLoading] = useState(false);
    const monoRef = useRef<any>(null);
    const profileRef = useRef<any>(null);
    const coverRef = useRef<any>(null);

    const saveImage = (files: FileList | null, ref: string) => {
        if (files) {
            const img = files[0];
            switch (ref) {
                case 'mono': setMono(img); return;
                case 'profile': setProfilePic(img); return;
                case 'cover': setCover(img); return;
            }
        }
    }

    const saveChanges = async () => {
        setLoading(true);
        const uasClient = await SessionClient.getUasClient();
        const userIdString = user.userId.toString();
        if (monoPic) {
            const res = isOwner ? await uasClient.get<any>('/api/1/profile/change_monochrome_profile_image') :
                await uasClient.getOnBehalfOf<any>('/api/1/profile/change_monochrome_profile_image', user.userId);
            if (res.ok()) {
                await uploadImage(monoPic, res.data.fileUploadDetails, userIdString, isOwner ? undefined : userIdString);
            }
        }
        if (profilePic) {
            const res = isOwner ? await uasClient.get<any>('/api/1/profile/change_profile_image') :
                await uasClient.getOnBehalfOf<any>('/api/1/profile/change_profile_image', user.userId);
            if (res.ok()) {
                await uploadImage(profilePic, res.data.fileUploadDetails, userIdString, isOwner ? undefined : userIdString);
            }
        }
        if (coverPic) {
            const res = isOwner ? await uasClient.get<any>('/api/1/profile/change_cover_image') :
                await uasClient.getOnBehalfOf<any>('/api/1/profile/change_cover_image', user.userId);
            if (res.ok()) {
                await uploadImage(coverPic, res.data.fileUploadDetails, userIdString, isOwner ? undefined : userIdString);
            }
        }
        await CurrentUser.set();
        window.location.reload();
    }

    return (
        <Box className={classes.container} style={{ backgroundImage: `url(${user.coverImageUrl})` }}>
            <Box className={classes.bgOverlay} />
            <Avatar className={classes.avatar} src={user.thumbnailUrl!}>
                {!user.thumbnailUrl && <PersonIcon fontSize='inherit' />}
            </Avatar>
            <Typography variant='h5'>{user.name}</Typography>
            {edit &&
                <Fab color='primary' className={classes.fab} onClick={() => setShowEdit(true)}>
                    <EditIcon />
                </Fab>
            }
            <Dialog
                open={showEdit}
                onClose={() => setShowEdit(false)}
                fullWidth
            >
                <DialogTitle>Edit Images</DialogTitle>
                <DialogContent>
                    <Box display='flex' justifyContent='space-evenly'>
                        <Box textAlign='center'>
                            <Avatar className={classes.avatar} src={monoPic ? URL.createObjectURL(monoPic) : user.monochromeProfileImageUrl!}>
                                {!monoPic && !user.monochromeProfileImageUrl && <PersonIcon fontSize='inherit' />}
                            </Avatar>
                            <Button variant='outlined' onClick={() => monoRef.current.click()}>Change Monochrome Image</Button>
                        </Box>
                        <Box textAlign='center'>
                            <Avatar className={classes.avatar} src={profilePic ? URL.createObjectURL(profilePic) : user.profileImageUrl!}>
                                {!profilePic && !user.profileImageUrl && <PersonIcon fontSize='inherit' />}
                            </Avatar>
                            <Button variant='outlined' color='primary' onClick={() => profileRef.current.click()}>Change Colour Image</Button>
                        </Box>
                    </Box>
                    <Box textAlign='center' marginY={4}>
                        <img className={classes.coverImgPreview} src={coverPic ? URL.createObjectURL(coverPic) : user.coverImageUrl!} />
                        <Button variant='outlined' color='primary' onClick={() => coverRef.current.click()}>Change Cover Image</Button>
                    </Box>
                    <input type="file" accept="image/svg+xml" ref={monoRef} style={{ display: 'none' }}
                        onChange={ev => saveImage(ev.target.files, 'mono')} />
                    <input type="file" accept="image/png, image/jpeg, image/svg+xml" ref={profileRef} style={{ display: 'none' }}
                        onChange={ev => saveImage(ev.target.files, 'profile')} />
                    <input type="file" accept="image/png, image/jpeg" ref={coverRef} style={{ display: 'none' }}
                        onChange={ev => saveImage(ev.target.files, 'cover')} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowEdit(false)} color="primary">
                        Cancel
                    </Button>
                    <LoadingButton onClick={saveChanges} color="primary" loading={isLoading}>
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </Box>
    )
}