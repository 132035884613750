import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, InputAdornment, TextField, Grid, List, ListItem, ListItemAvatar, Avatar, ListItemText } from '@material-ui/core';
import { StaffSearchResultDetails, StaffSearchRequest, ShoutClient, SessionClient, StaffSearchPageResponse, AccountType } from '@shout-sdk/client-sdk';
import SearchIcon from '@material-ui/icons/Search';
import { withRouter } from 'react-router';

let uasClient: ShoutClient;

const UserSearch: React.FC<any> = props => {

    const [searchText, setText] = useState('');
    const [results, setResults] = useState<StaffSearchResultDetails[]>([]);
    let searchObj = new StaffSearchRequest();

    useEffect(() => {
        SessionClient.getUasClient().then(client => uasClient = client);
    }, [])

    useEffect(() => {
        if (uasClient && searchText) {
            search();
        }
    }, [searchText]);

    const search = async () => {
        searchObj.searchTerm = searchText;
        const res = await uasClient.post<StaffSearchPageResponse>('/api/1/search/all_users', searchObj);
        if (res.ok()) {
            setResults(res.data!.users);
        }
    }

    const users = results.filter(user => user.accountType !== AccountType.Organization);
    const orgs = results.filter(user => user.accountType === AccountType.Organization);

    return (
        <Container maxWidth='lg'>
            <Box marginY={4}>
                <Typography color='primary' variant='h2'>User Search</Typography>
                <Box marginY={4}>
                    <TextField
                        variant='outlined'
                        label="Search for users"
                        style={{ display: 'flex', maxWidth: 500 }}
                        value={searchText}
                        onChange={ev => setText(ev.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color='disabled' />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
                {searchText.length > 0 &&
                    <Grid container>
                        <Grid md={6} xs={12} item>
                            <Typography variant='h5' color='primary'>Organizations</Typography>
                            <List>
                                {orgs.map(org => (
                                    <React.Fragment key={org.userId}>
                                        <ListItem button divider onClick={() => props.history.push({ pathname: '/profile', state: { userId: org.userId } })}>
                                            <ListItemAvatar>
                                                <Avatar src={org.thumbnailUrl!} />
                                            </ListItemAvatar>
                                            <ListItemText primary={org.name} secondary={org.emailAddress} />
                                        </ListItem>
                                    </React.Fragment>
                                ))}
                            </List>
                        </Grid>
                        <Grid md={6} xs={12} item>
                            <Typography variant='h5' color='primary'>Users</Typography>
                            <List>
                                {users.map(user => (
                                    <React.Fragment key={user.userId}>
                                        <ListItem divider>
                                            <ListItemAvatar>
                                                <Avatar src={user.thumbnailUrl!} />
                                            </ListItemAvatar>
                                            <ListItemText primary={user.name} secondary={user.emailAddress} />
                                        </ListItem>
                                    </React.Fragment>
                                ))}
                            </List>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Container>
    )
}

export const UserSearchView = withRouter(UserSearch);