import React, { useState, useEffect } from 'react';
import { Layout } from '../Layout';
import { VerifySms } from './verifySms/VerifySms';
import { IndividualForm } from './individual/IndividualForm';
import { UpgradeToIndividualRequest } from '@shout-sdk/client-sdk';
import { Store, Auth } from 'auth';

enum Steps {
    Initial = 0,
    Sms = 1
}

export const RegisterIndividualView: React.FC<any> = props => {
    const [step, setStep] = useState(Auth.isAuthorized() ? Steps.Sms : Steps.Initial);
    const [upgradeData, setUpgradeData] = React.useState(new UpgradeToIndividualRequest());

    useEffect(() => {
        const { registrationInfo } = Store.get();
        if (registrationInfo) {
            setUpgradeData({ ...upgradeData, emailAddress: registrationInfo.emailAddress });
        }
    }, [])

    return (
        <Layout>
            {step === Steps.Initial &&
                <IndividualForm next={() => setStep(step + 1)}
                    upgradeData={upgradeData} setUpgradeData={setUpgradeData} />
            }
            {step === Steps.Sms &&
                <VerifySms upgradeData={upgradeData} />
            }
        </Layout>
    )
}