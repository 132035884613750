import React, { useRef } from 'react';
import { Grid, TextField, Button, Box, Typography, makeStyles } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { FormSection } from './FormSection';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { GeofenceTranslation, AddEventGeofenceRequest, SelectedContentCategoryDetails } from '@shout-sdk/client-sdk';
import { EventCategorySelect } from './EventCategorySelect';

interface IProps {
    event: AddEventGeofenceRequest,
    setEvent: React.Dispatch<React.SetStateAction<AddEventGeofenceRequest>>,
    image: string,
    setImage: React.Dispatch<React.SetStateAction<File | null>>
}

const useStyles = makeStyles(theme => ({
    imgPreview: {
        width: '100%',
        height: 150,
        objectFit: 'cover',
        borderRadius: theme.shape.borderRadius
    },
    uploadContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    }
}))

const translation = new GeofenceTranslation();
translation.language = 'eng';

export const EventGeneralForm: React.FC<IProps> = props => {
    const classes = useStyles();
    const imgRef = useRef<HTMLInputElement>(null);
    const { event, setEvent, image, setImage } = props;

    const saveImage = (files: any) => {
        if (files) {
            setImage(files[0]);
        }
    }

    const handleTitle = (ev: React.FormEvent<any>) => {
        translation.title = ev.currentTarget.value;
        setEvent({ ...event, translations: [translation] });
    }

    const isInValidDate = (): boolean => {
        return new Date(event.startDate) > new Date(event.endDate);
    }

    const isInvalidTitle = (): boolean => {
        const length = translation.title?.length ?? 0;
        return (length > 0 && length < 4) || length > 80;
    }

    return (
        <FormSection title="General Information">
            <Grid spacing={4} container>
                <Grid md={6} xs={12} item>
                    <TextField
                        variant="outlined"
                        label="Event Name"
                        margin="normal"
                        value={event.translations[0].title}
                        onChange={handleTitle}
                        error={isInvalidTitle()}
                        helperText={isInvalidTitle() && 'Between 4 and 80 characters'}
                        fullWidth />
                    <DateTimePicker
                        label="Start Date/Time"
                        inputVariant="outlined"
                        value={event.startDate ? new Date(event.startDate) : null}
                        onChange={date => setEvent({ ...event, startDate: date!.toISOString() })}
                        margin="normal"
                        ampm={false}
                        minDate={new Date()}
                        error={isInValidDate()}
                        helperText={isInValidDate() && "Start date can't be after end date!"}
                        fullWidth
                    />
                    <DateTimePicker
                        label="End Date/Time"
                        inputVariant="outlined"
                        value={event.endDate ? new Date(event.endDate) : null}
                        onChange={date => setEvent({ ...event, endDate: date!.toISOString() })}
                        margin="normal"
                        ampm={false}
                        minDate={new Date()}
                        error={isInValidDate()}
                        helperText={isInValidDate() && "End date can't be before start date!"}
                        fullWidth
                    />
                </Grid>
                <Grid md={6} xs={12} item>
                    <EventCategorySelect onChange={(contentCategories: SelectedContentCategoryDetails[] | null) => setEvent({ ...event, contentCategories })} />
                </Grid>
                <Grid md={6} xs={12} item>
                    <img src={image} className={classes.imgPreview} alt='' />
                </Grid>
                <Grid className={classes.uploadContainer} md={6} xs={12} item>
                    <Box>
                        <Button variant="outlined" color="primary" startIcon={<CloudUploadIcon />} onClick={() => imgRef.current!.click()}>Set Event Image</Button>
                        <Typography style={{ marginTop: 8 }} color='primary' variant='subtitle2'>Min: 1080x1080, Max: 3000x3000, 3MB</Typography>
                    </Box>
                    <input type="file" accept="image/png, image/jpeg" ref={imgRef} style={{ display: 'none' }}
                        onChange={ev => saveImage(ev.target.files)} />
                </Grid>
            </Grid>
        </FormSection>
    )
}