import React, { useState, useEffect } from 'react';
import { Snackbar, makeStyles, Theme, SnackbarContent, IconButton } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

interface IProps {
    open: boolean,
    err: string
}

const useStyles = makeStyles((theme: Theme) => ({
    error: {
        backgroundColor: theme.palette.error.main,
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export const ErrorSnackbar: React.FC<IProps> = props => {
    const classes = useStyles();
    const { open, err } = props;
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        setHidden(false)
    }, [open, err])

    return (
        <Snackbar open={open && !hidden} autoHideDuration={1000}>
            <SnackbarContent
                className={classes.error}
                message={
                    <span id="client-snackbar" className={classes.message}>
                        <ErrorIcon className={clsx(classes.icon, classes.iconVariant)} />
                        {err}
                    </span>
                }
                action={[
                    <IconButton key={0} color="inherit" onClick={() => setHidden(true)}>
                        <CloseIcon className={classes.icon} />
                    </IconButton>
                ]}
            />
        </Snackbar>
    )
}