import React, { useState, useEffect } from 'react';
import { Select, MenuItem, makeStyles, Divider } from '@material-ui/core';
import { Geofence } from '@shout-sdk/client-sdk';
import { useMyGeofences } from 'components/dashboard/common';

const useStyles = makeStyles(theme => ({
    geofenceTitle: {
        color: theme.palette.primary.main
    },
    select: {
        borderRadius: theme.shape.borderRadius,
        margin: theme.spacing(1, 0),
        maxWidth: '100%',
        fontSize: theme.typography.h5.fontSize,
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',  
        },
    },
    noFocus: {
        '&:focus': {
            background: 'none'
        }
    },
    container: {
        paddingBottom: theme.spacing(2),
        backgroundColor: '#fff',
        width: '100%',
        zIndex: 99,
    }
}));

export const StatusFilterMenu: React.FC<any> = props => {
    const classes = useStyles();
    const [selectedGeofence, setGeofence] = useState(props.navState ? props.navState.geofenceId : 0);
    const { geofences } = useMyGeofences();

    const now = new Date();
    const active = geofences.filter(gf => gf.endDate === null || new Date(gf.endDate) > now);

    // callback for parent component
    useEffect(() => {
        props.onChange(selectedGeofence);
    }, [selectedGeofence])

    return (
        <div className={classes.container}>
            <Select
                value={selectedGeofence}
                onChange={(ev: any) => setGeofence(ev.target.value)}
                className={classes.select}
                classes={{ select: classes.noFocus }}
                disableUnderline
            >
                <MenuItem value={0}>
                    Show only my statuses
                </MenuItem>
                {active.map(geofence => (
                    <MenuItem value={geofence.geofenceNumberId} key={geofence.geofenceNumberId}>
                        Show statuses posted in&nbsp;<span className={classes.geofenceTitle}>{geofence.translations![0].title}</span>
                    </MenuItem>
                ))}
            </Select>
            <Divider style={{ width: '100%' }} />
        </div>
    )
}