import { Route, Redirect } from 'react-router';
import * as React from 'react';
import { Auth, CurrentUser } from '../../auth';
import { Layout } from '../dashboard/layout/Layout';
import { AccountType } from '@shout-sdk/client-sdk';

// https://reacttraining.com/react-router/web/example/auth-workflow
// @ts-ignore
export const PrivateRoute = ({ component, ...rest }) => {
  return <Route {...rest}
    render={props =>
      Auth.isAuthorized() ?
        CurrentUser.getAccountType() !== AccountType.Individual ?
        <Layout viewComponent={component} /> :
        <Redirect to="/download" /> :
        <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    } />
};
