import React, { useState, useEffect } from 'react';
import { Box, Grid, FormControl, InputLabel, Select, MenuItem, Typography, Button, TextField } from '@material-ui/core';
import { Map } from 'components/dashboard/common';
import { Geofence, GeoLocation, SessionClient, GeofencesResponse, GeofenceServiceUrls } from '@shout-sdk/client-sdk';
import { LngLat } from 'mapbox-gl';
import { withRouter } from 'react-router';

const GeofenceSelectComponent: React.FC<any> = props => {

    const [marker, setMarker] = useState<LngLat>(new LngLat(0, 0));
    const [geofences, setGeofences] = useState<Geofence[]>([]);
    const [ownerGeofence, setOwner] = useState(null);
    const [defaultGeofence, setDefault] = useState(null);

    useEffect(() => {
        if (marker.lat !== 0 && marker.lng !== 0) {
            fetchGeofencesInside();
        }
    }, [marker]);

    useEffect(() => {
        props.onChange({ marker: new GeoLocation(marker.lat, marker.lng), ownerGeofence, defaultGeofence });
    }, [marker, ownerGeofence, defaultGeofence]);

    useEffect(() => {
        if(ownerGeofence) {
            Map.displayGeofence(geofences.find(gf => gf.geofenceNumberId === ownerGeofence)!);
        }
    }, [ownerGeofence])

    const fetchGeofencesInside = async () => {
        const gfsClient = await SessionClient.getGfsClient();
        const res = await gfsClient.post<GeofencesResponse>(GeofenceServiceUrls.Geofence.Inside, { location: new GeoLocation(marker.lat, marker.lng) });
        if (res.ok()) {
            setGeofences(res.data!.results!);
        }
    }

    return (
        <Box marginY={4}>
            <Box textAlign='right'>
                <Button variant='outlined' color='primary' onClick={() => props.history.push('/geofences/new')}>Add Geofence</Button>
            </Box>
            {geofences.length > 0 ?
                <Grid spacing={4} container>
                    <Grid md={6} xs={12} item>
                        <FormControl variant="outlined" margin="normal" fullWidth>
                            <InputLabel>
                                Select an Owner Geofence
                            </InputLabel>
                            <Select labelWidth={190} value={ownerGeofence} onChange={(ev: any) => setOwner(ev.target.value)}>
                                {geofences.map(geofence => {
                                    return <MenuItem key={geofence.geofenceNumberId} value={geofence.geofenceNumberId}>{geofence.translations![0].title}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid md={6} xs={12} item>
                        <FormControl variant="outlined" margin="normal" fullWidth>
                            <InputLabel>
                                Select a Default Geofence
                            </InputLabel>
                            <Select labelWidth={185} value={defaultGeofence} onChange={(ev: any) => setDefault(ev.target.value)}>
                                {geofences.map(geofence => {
                                    return <MenuItem key={geofence.geofenceNumberId} value={geofence.geofenceNumberId}>{geofence.translations![0].title}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid> :
                <Typography color='primary' gutterBottom>
                    Please double click on the map to set the business location.
                </Typography>
            }
            <Map lnglat={marker} moveMarker={setMarker} dblClick />
            <Grid spacing={4} container>
                <Grid md={6} xs={12} item>
                    <TextField value={marker.lat} margin='normal' fullWidth />
                </Grid>
                <Grid md={6} xs={12} item>
                    <TextField value={marker.lng} margin='normal' fullWidth />
                </Grid>
            </Grid>
        </Box>
    )
}

export const GeofenceSelect = withRouter(GeofenceSelectComponent);