import * as React from 'react';
import * as mapboxgl from "mapbox-gl";
// @ts-ignore
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { mapConfig } from './MapDrawConfig';
import { HttpClient, Geofence } from '@shout-sdk/client-sdk';
import './Map.css';

const MapboxAccessToken = 'pk.eyJ1Ijoic2hvdXQtcGxhdGZvcm0iLCJhIjoiY2p4bmVib3U0MDFrcTNkcGVoMmt5d3B4MCJ9.vHFfDuJPs1QUQUHsyY4vdQ';
const MapboxStyle = 'mapbox://styles/shout-platform/cjxnvggz400n01ct8lfklqtl9';
// const MapboxStyleId = 'cjxnvggz400n01ct8lfklqtl9';

export class Map extends React.Component<any, any> {
    public static map: mapboxgl.Map;
    public static draw: MapboxDraw;


    public static displayGeofence = (geofence: Geofence) => {
        // wait until loaded
        const waitUntilLoaded = () => {
            if (!Map.map.isStyleLoaded()) {
                setTimeout(waitUntilLoaded, 200);
                return;
            }
        }
        waitUntilLoaded();
        // remove previous geofence
        Map.hideGeofence(geofence.geofenceNumberId.toString());
        if (geofence.geofenceSpread === 0) { Map.map.flyTo({ center: [0, 30], zoom: 0.5, speed: 2 }); return; }
        const httpClient = new HttpClient(geofence.coordinates.url);
        httpClient.get<any>(geofence.coordinates.url).then(res => {
            Map.displayPolygon([res.data.map((c: any) => [c.Longitude, c.Latitude])], geofence.geofenceNumberId.toString());
        });
    }

    public static displayPolygon = (boundaries: any[], id: string) => {
        const layers = Map.map.getStyle().layers;
        Map.hideGeofence(layers![layers!.length - 1].id);
        const coords = boundaries;
        Map.map.addLayer({
            'id': id,
            'type': 'fill',
            'source': {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'Polygon',
                        'coordinates': coords,
                    }
                }
            },
            'paint': {
                'fill-color': '#4dada0',
                'fill-opacity': 0.5,
            }
        });
        const bounds = coords[0].reduce((bounds: any, coord: any) => {
            return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coords[0][0], coords[0][0]));

        Map.map.fitBounds(bounds, {
            padding: 20,
            maxZoom: 17
        })
    }

    public static editGeofence = (geofence: Geofence) => {
        const httpClient = new HttpClient(geofence.coordinates.url);
        httpClient.get<any>(geofence.coordinates.url).then(res => {
            const coords = [res.data.map((c: any) => [c.Longitude, c.Latitude])]
            Map.draw.add({
                'id': geofence.geofenceNumberId.toString(),
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'Polygon',
                    'coordinates': coords
                }
            })
            const bounds = coords[0].reduce((bounds: any, coord: any) => {
                return bounds.extend(coord);
            }, new mapboxgl.LngLatBounds(coords[0][0], coords[0][0]));

            Map.map.fitBounds(bounds, {
                padding: 20,
                maxZoom: 17
            })
        });
    }

    public static hideGeofence = (id: string) => {
        if (Map.map.getLayer(id)) {
            Map.map.removeLayer(id);
        }
        if (Map.map.getSource(id)) {
            Map.map.removeSource(id);
        }
    }

    private mapContainer: HTMLDivElement | null = null;
    private mapMarker: mapboxgl.Marker = new mapboxgl.Marker({ color: "#4dada0" });

    public constructor(props: any) {
        super(props);
    }

    public componentDidMount() {
        // workaround because accessTokes is readonly
        (mapboxgl as any).accessToken = MapboxAccessToken;
        Map.map = new mapboxgl.Map({
            container: this.mapContainer!,
            style: MapboxStyle,
            attributionControl: false,
            interactive: !this.props.readonly
        });
        Map.draw = new MapboxDraw(mapConfig);

        if (this.props.readonly) { return }

        this.mapMarker.setLngLat(this.props.lnglat);
        this.mapMarker.addTo(Map.map);
        Map.map.doubleClickZoom.disable();
        Map.map.on(this.props.dblClick ? "dblclick" : "click", ev => {
            this.props.moveMarker(ev.lngLat);
        });

        // set marker to center
        Map.map.on("draw.create", (e) => {
            this.props.moveMarker(this.polygonCenter(e.features[0].geometry.coordinates[0]));
            this.props.addGeofence(e.features[0].geometry.coordinates[0]);
        });

        Map.map.on("draw.update", (e) => {
            this.props.moveMarker(this.polygonCenter(e.features[0].geometry.coordinates[0]));
            this.props.addGeofence(e.features[0].geometry.coordinates[0]);
        })

        // add draw plugin
        Map.map.addControl(Map.draw, 'top-right');
    }

    public render() {
        if (!this.props.readonly) {
            this.mapMarker.setLngLat(this.props.lnglat);
        }
        return (
            <div className="map-container" style={{ height: this.props.height }} ref={el => this.mapContainer = el}>
                {this.props.enableDraw &&
                    <button className={"btn btn-shout btn-draw " + (this.isDrawing() ? 'btn-drawing' : '')} onClick={this.drawGeofence}>
                        {this.isDrawing() ? 'Click to Draw' : 'Draw Geofence'}
                    </button>
                }
            </div>);
    }

    private isDrawing = (): boolean => {
        return !Map.map || !Map.draw ? false : Map.draw.getMode() === Map.draw.modes.DRAW_POLYGON;
    }

    private drawGeofence = () => {
        // deletes old polygon and forces a re-render for button styling
        Map.draw.deleteAll();
        Map.draw.changeMode(Map.draw.modes.DRAW_POLYGON);
        this.forceUpdate();
    }

    private polygonCenter = (poly: any[]) => {
        const latitudes: any[] = [];
        const longitudes: any[] = [];
        // put all latitudes and longitudes in arrays
        poly.forEach((p: any) => {
            longitudes.push(p[0]);
            latitudes.push(p[1]);
        });
        // sort the arrays low to high
        latitudes.sort();
        longitudes.sort();
        // get the min and max of each
        const lowX = latitudes[0];
        const highX = latitudes[latitudes.length - 1];
        const lowY = longitudes[0];
        const highY = longitudes[latitudes.length - 1];
        // center of the polygon is the starting point plus the midpoint
        const centerX = lowX + ((highX - lowX) / 2);
        const centerY = lowY + ((highY - lowY) / 2);

        return new mapboxgl.LngLat(centerY, centerX);
    }
}