import { CommentWithUserProfileInformation, ImpressionItemWithUserProfileInformation } from "@shout-sdk/client-sdk";

export const getTime = (impression: CommentWithUserProfileInformation | ImpressionItemWithUserProfileInformation): string => {
    // @ts-ignore
    const seconds = Math.abs((new Date() - new Date(impression.timestamp)) / 1000);

    const d = Math.floor(seconds / (3600 * 24));
    if (d > 0) { return d + 'd ago' }
    const h = Math.floor(seconds % (3600 * 24) / 3600);
    if (h > 0) { return h + 'h ago' }
    const m = Math.floor(seconds % 3600 / 60);
    if (m > 0) { return m + 'min ago' }

    return seconds + 's ago';
}