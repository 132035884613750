import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Typography, FormControl, InputLabel, Select, MenuItem, ButtonGroup, Button } from '@material-ui/core';
import { SessionClient, AccountType, ContentCategory } from '@shout-sdk/client-sdk';
import { SubCategoryPicker } from './SubCategoryPicker';
import { ReactComponent as Cat1 } from 'assets/white_category_icon_c1.svg';
import { ReactComponent as Cat2 } from 'assets/white_category_icon_c2.svg';
import { ReactComponent as Cat3 } from 'assets/white_category_icon_c3.svg';
import { ReactComponent as Cat4 } from 'assets/white_category_icon_c4.svg';
import { ReactComponent as Cat5 } from 'assets/white_category_icon_c5.svg';
import { ReactComponent as Cat6 } from 'assets/white_category_icon_c6.svg';
import { ReactComponent as Cat7 } from 'assets/white_category_icon_c7.svg';
import clsx from 'clsx';

const catIcons = [Cat1, Cat2, Cat3, Cat4, Cat5, Cat6, Cat7];

const useStyles = makeStyles(theme => ({
    container: {
        border: '1px solid grey',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.grey[400],
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap',
    },
    title: {
        textAlign: 'left'
    },
    category: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 100,
        width: 90,
        cursor: 'pointer',
        color: theme.palette.grey[400],
        margin: theme.spacing(2, 0),
        transition: '0.5s',
        '& path': {
            fill: theme.palette.grey[300],
            transition: '0.5s',
        }
    },
    active: {
        color: theme.palette.primary.main,
        '& path': {
            fill: theme.palette.primary.main,
        },
    },
    catImg: {
        width: 50,
    }
}))

export const CategoryPicker: React.FC<any> = props => {
    const classes = useStyles();
    const [allCategories, setAllCategories] = useState<ContentCategory[]>([]);
    const [categories, setCategories] = useState<ContentCategory[]>([]);
    const [selectedCat, selectCat] = useState<number | null>(null);

    const { geofence, onChange } = props;

    useEffect(() => {
        const fetchAllCategories = async () => {
            const lcsClient = await SessionClient.getLcsClient();
            const res = await lcsClient.get<any>('/api/1/content_category/get_content_categories');
            if (res.ok()) {
                // filter social content and sessions and agenda
                const all = res.data.data.filter((cat: ContentCategory) => cat.id !== 7 && cat.id !== 5);
                setAllCategories(all);
                setCategories(all);
            }
        }
        fetchAllCategories()
    }, [])

    // update categories every time a geofence is selected
    useEffect(() => {
        if (geofence) {
            // reset category
            selectCat(null);

            // filter Organization categories
            let cats = allCategories.filter((cat: any) => {
                return cat.authorizedAccountTypes.includes(AccountType.Organization);
            });

            // filter allowed categories
            if (geofence.allowedContentCategories) {
                const allowedIds = geofence.allowedContentCategories.map((cat: any) => cat.id);
                cats = cats.filter((cat: any) => {
                    return allowedIds.includes(cat.id);
                })
            }
            setCategories(cats);
        }
    }, [geofence])

    const onSubCatChange = (subCategories: {}) => {
        onChange({ ...subCategories, contentCategory: selectedCat });
    }

    return (
        <div>
            <Box marginY={2}>
                <InputLabel className={classes.title}>Select a Category</InputLabel>
                <Box className={classes.container}>
                    {categories.map(cat => {
                        const Icon = catIcons[cat.id - 1];
                        return (
                            <Box key={cat.id} className={clsx(classes.category, selectedCat === cat.id && classes.active)} onClick={() => selectCat(cat.id)}>
                                <Icon className={classes.catImg} />
                                <Typography variant="body2">{cat.name}</Typography>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            {selectedCat && 
                <SubCategoryPicker category={categories.find(cat => cat.id === selectedCat)!} onChange={onSubCatChange} />
            }
        </div>
    )
}