import React, { useEffect } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { GroupCoreInformation, SessionClient, GroupMemberRole, AccountSubscription } from '@shout-sdk/client-sdk';
import { CurrentUser } from 'auth';
import { GroupCard } from './GroupCard';
import { Loader, EmptyPlaceholder, FloatingFab } from 'components/dashboard/common';
import { withRouter } from 'react-router';
import AddIcon from '@material-ui/icons/Add';

const AllGroups: React.FC<any> = props => {
    const [groups, setGroups] = React.useState<GroupCoreInformation[]>([]);
    const [isLoading, setLoading] = React.useState(true);
    const isEnterprise2 = CurrentUser.getProfile().features!.accountSubscription >= AccountSubscription.Enterprise2;

    useEffect(() => {
        const fetchGroups = async () => {
            const uasClient = await SessionClient.getUasClient();
            const res = await uasClient.get<any>(`/api/1/profile/${CurrentUser.getProfile().userId}/groups`);
            setLoading(false);
            if (res.ok()) {
                setGroups(res.data.groups);
            }
        }
        fetchGroups();
    }, []);

    const admin = groups.filter(group => group.requesterRole === GroupMemberRole.Admin);
    const member = groups.filter(group => group.requesterRole === GroupMemberRole.Normal);

    if (isLoading) {
        return <Loader />
    }

    return (
        <Container maxWidth="lg">
            <Box marginY={4}>
                <Typography variant='h2' color='primary' gutterBottom>Groups</Typography>
                {admin.length > 0 &&
                    <Box marginTop={4}>
                        <Typography variant='h5' gutterBottom>Your Member Groups</Typography>
                        {admin.map(group => (
                            <GroupCard key={group.groupId} group={group} />
                        ))}
                    </Box>
                }
                {member.length > 0 &&
                    <Box marginTop={4}>
                        <Typography variant='h5' gutterBottom>Your Member Groups</Typography>
                        {member.map(group => (
                            <GroupCard key={group.groupId} group={group} />
                        ))}
                    </Box>
                }
                {groups.length === 0 &&
                    <EmptyPlaceholder text={isEnterprise2 ? "You are currently not part of any groups." : "Please upgrade to Enterprise2 for this feature."} />
                }
            </Box>
            <FloatingFab color='primary' variant='extended' onClick={() => props.history.push('/groups/create')}
                disabled={!isEnterprise2}>
                NEW GROUP<br /><AddIcon />
            </FloatingFab>
        </Container>
    )
}

export const AllGroupsView = withRouter(AllGroups);