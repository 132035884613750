import React, { useEffect, useState } from 'react';
import { ReturnedStatus, SessionClient, UserAccountServiceUrls, GetStatusesWithProfileResponse, StatusWithUserProfileInformation } from '@shout-sdk/client-sdk';
import { StatusFilterMenu } from './StatusFilterMenu';
import { Container, Typography, Box } from '@material-ui/core';
import { StatusCard } from './StatusCard';
import { Loader, FloatingFab, EmptyPlaceholder } from 'components/dashboard/common';
import { StatusGrid } from './StatusGrid';
import AddIcon from '@material-ui/icons/Add';
import { useStyles } from './AllStatusesStyles';
import { withRouter } from 'react-router';

const AllStatuses: React.FC<any> = props => {
    const classes = useStyles();
    const [statuses, setStatuses] = useState<ReturnedStatus[]>([]);
    const [statusesWithUser, setStatusesWithUser] = useState<StatusWithUserProfileInformation[]>([]);
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        props.location.state ? fetchStatusesByGeofenceId(props.location.state.geofenceId) : fetchMyStatuses();
    }, [])

    const fetchMyStatuses = async () => {
        setStatusesWithUser([]);
        const uasClient = await SessionClient.getUasClient();
        const res = await uasClient.get<GetStatusesWithProfileResponse>(UserAccountServiceUrls.UserProfile.getStatuses(SessionClient.getInstance().userId!, true, true, true, null));
        if (res.ok() && res.data != null && res.data.statuses != null) {
            let statusesWithUPI: Array<StatusWithUserProfileInformation> = [];
            for (let i = 0; i < res.data.statuses.length; i++)
            {
                if (res.data.statuses[i].contentCategory != 7)
                {
                    let status = new StatusWithUserProfileInformation();
                    status.profileInformation = res.data.userInformation;
                    status.statusInformation = res.data.statuses[i];
                    statusesWithUPI.push(status);
                }
            }
            setStatusesWithUser(statusesWithUPI);
        }
        setLoading(false);
    }

    const fetchStatusesByGeofenceId = async (id: number) => {
        setStatuses([]);
        const lcsClient = await SessionClient.getLcsClient();
        const res = await lcsClient.post<any>('/api/1/status/geofence', {
            geofenceIds: [id],
            contentCategories: [
                { id: 1, subcategories: null },
                { id: 2, subcategories: null },
                { id: 3, subcategories: null },
                { id: 4, subcategories: null },
                { id: 5, subcategories: null },
                { id: 6, subcategories: null },
            ],
            includeContentFromChildGeofences: false,
            allowAdultContent: true
        });
        if (res.ok()) {
            setStatusesWithUser(res.data.statuses);
        }
        setLoading(false);
    }

    // callback for StatusFilterMenu
    const updateStatuses = (id: number) => {
        id === 0 ? fetchMyStatuses() : fetchStatusesByGeofenceId(id);
    }

    // filtering active and expired for own statuses
    const myActiveStatuses = statuses.filter(status => status.ageInSeconds > 0 && status.expirationTimeInSeconds > 0);
    const myExpiredStatuses = statuses.filter(status => status.expirationTimeInSeconds === 0);
    const myScheduledStatuses = statuses.filter(status => status.ageInSeconds === 0);

    if (isLoading) {
        return (
            <Loader />
        )
    }

    return (
        <Container maxWidth="lg">
            <Box marginY={4}>
                <Typography color='primary' variant='h2' gutterBottom>Status Overview</Typography>
                <StatusFilterMenu onChange={updateStatuses} navState={props.location.state} />
                {myActiveStatuses.length > 0 &&
                    <StatusGrid title='Active Statuses' active>
                        {myActiveStatuses.map((status: ReturnedStatus) => {
                            return (
                                <StatusCard key={status.statusId} status={status} />
                            )
                        })}
                    </StatusGrid>
                }
                {myScheduledStatuses.length > 0 &&
                    <StatusGrid title='Scheduled Statuses' scheduled>
                        {myScheduledStatuses.map((status: ReturnedStatus) => {
                            return (
                                <StatusCard key={status.statusId} status={status} />
                            )
                        })}
                    </StatusGrid>
                }
                {myExpiredStatuses.length > 0 &&
                    <StatusGrid title='Expired Statuses' expired>
                        {myExpiredStatuses.map((status: ReturnedStatus) => {
                            return (
                                <StatusCard key={status.statusId} status={status} />
                            )
                        })}
                    </StatusGrid>
                }
                {statusesWithUser.length > 0 &&
                    <StatusGrid title='Active Statuses' active>
                        {statusesWithUser.map((status: StatusWithUserProfileInformation) => {
                            return (
                                <StatusCard key={status.statusInformation!.statusId} status={status.statusInformation} user={status.profileInformation!} />
                            )
                        })}
                    </StatusGrid>
                }
                {statuses.length === 0 && statusesWithUser.length === 0 &&
                    <EmptyPlaceholder text='No statuses found.' />
                }
                <FloatingFab color="primary" variant="extended" onClick={() => props.history.push('/statuses/new')}>
                    NEW <br /><AddIcon />
                </FloatingFab>
            </Box>
        </Container>
    )
}

export const AllStatusesView = withRouter(AllStatuses);