import React from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { Steps } from './Steps';

export const StepHeader: React.FC<any> = props => {
    const { step } = props;

    return (
        <Stepper activeStep={step}>
            <Step completed={step > Steps.Content}>
                <StepLabel>Session Content</StepLabel>
            </Step>
            <Step>
                <StepLabel>Session Options</StepLabel>
            </Step>
        </Stepper>
    )
}