import React, { useState } from 'react';
import { InputAdornment, IconButton, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export const PasswordInput: React.FC<any> = props => {

    const [isVisible, setVisibility] = useState(false);

    return (
        <TextField
            id="outlined-adornment-password"
            variant="outlined"
            type={isVisible ? 'text' : 'password'}
            label="Password"
            value={props.value}
            onChange={props.onChange}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            aria-label="toggle password visibility"
                            onClick={() => setVisibility(!isVisible)}
                        >
                            {isVisible ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            margin="normal"
            fullWidth
            required
        />
    )
}