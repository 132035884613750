import React from 'react';
import { ButtonGroup, Button } from '@material-ui/core';

export const ExclusiveSelect: React.FC<any> = props => {

    const { options, onChange, selected } = props;

    const isActive = (id: number): boolean => {
        return selected === id;
    }

    return (
        <ButtonGroup size="large" fullWidth>
            {options.map((opt: any) => (
                <Button key={opt.id}
                    color={isActive(opt.id) ? 'primary' : 'default'}
                    onClick={() => onChange(opt.id)} variant={isActive(opt.id) ? 'contained' : 'outlined'}>
                    {opt.name}
                </Button>
            ))}
        </ButtonGroup>
    )
} 