import React, { useState } from 'react';
import { Container, Box, Typography, Grid } from '@material-ui/core';
import { withRouter } from 'react-router';
import { StatusCard } from '../all/StatusCard';
import { CommentList } from './CommentList';
import { LikeList } from './LikeList';
import { Geofence, SessionClient, GeofenceServiceUrls } from '@shout-sdk/client-sdk';
import { Map } from 'components/dashboard/common';
import { useEffect } from 'react';

const StatusDetail: React.FC<any> = props => {

    const [geofence, setGeofence] = useState<Geofence>(new Geofence());
    const { status } = props.location.state;

    useEffect(() => {
        const fetchGeofence = async () => {
            const gfsClient = await SessionClient.getGfsClient();
            const res = await gfsClient.get<any>(GeofenceServiceUrls.Geofence.GetGeofence(status.geofenceNumberId));
            if (res.ok()) {
                setGeofence(res.data.geofence);
            }
        }
        fetchGeofence();
    }, []);

    useEffect(() => {
        if (geofence.geofenceNumberId > 0) {
            Map.displayGeofence(geofence);
        }
    }, [geofence])

    return (
        <Container maxWidth='lg'>
            <Box marginY={4}>
                <Typography color='primary' variant='h2'>Status Details</Typography>
                <Box marginY={4}>
                    <Grid spacing={4} container>
                        <Grid md={4} xs={12} item>
                            <StatusCard status={status} disabled />
                        </Grid>
                        <Grid md={5} xs={12} item>
                            <CommentList statusId={status.statusId} />
                        </Grid>
                        <Grid md={3} xs={12} item>
                            <LikeList statusId={status.statusId} />
                        </Grid>
                    </Grid>
                </Box>
                <Map height={300} readonly />
            </Box>
        </Container>
    )
}

export const StatusDetailView = withRouter(StatusDetail);