import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { withRouter } from 'react-router';
import { AddStatusRequest, SessionClient, AddStatusResponse, LocalContentServiceUrls, LocalContentServiceConstants, StatusTranslation } from '@shout-sdk/client-sdk';
import { Steps } from './Steps';
import { Layout } from './Layout';
import { SessionContent } from './steps/SessionContent';
import { SessionOptions } from './steps/SessionOptions';
import { ErrorSnackbar, LoadingButton } from 'components/dashboard/common';
import { uploadImage } from 'components/app/helpers';

const initialState = new AddStatusRequest();
initialState.contentCategory = 7;
// @ts-ignore
initialState.contentSubcategory = null;
initialState.translation = new StatusTranslation();

const CreateSession: React.FC<any> = props => {
    const [session, setSession] = useState<AddStatusRequest>(initialState);
    const [imgs, setImgs] = useState<File[]>([]);
    const [step, setStep] = useState(Steps.Content);
    const [err, setErr] = useState<string>('');
    const [isLoading, setLoading] = useState(false);
    const { geofence } = props.location.state;

    useEffect(() => {
        setSession({ ...session, geofenceNumberId: geofence.geofenceNumberId, location: geofence.coordinates.default });
    }, []);

    useEffect(() => {
        if (imgs.length > 0) {
            setSession({ ...session, numberOfAttachedImages: imgs.length });
        }
    }, [imgs]);

    const renderStep = () => {
        switch (step) {
            case Steps.Content: return <SessionContent session={session} setSession={setSession} setImages={setImgs} />
            case Steps.Options: return <SessionOptions session={session} setSession={setSession} geofence={geofence} />
        }
    }

    const handleClick = () => {
        step < Steps.Options ? setStep(step + 1) : createSession();
    }

    const isBtnDisabled = (): boolean => {
        switch (step) {
            case Steps.Content: {
                if (!session.translation.title) { return true }
                return !session.translation.message || session.translation.message.length < 4 || session.translation.message.length > LocalContentServiceConstants.maxStatusMessageLength
            }
            case Steps.Options: {
                const { targetAudience } = session;
                if (session.expirationTimeInSeconds < LocalContentServiceConstants.minStatusExpirationSeconds) { return true }
                return !targetAudience.males && !targetAudience.females && !targetAudience.adultContent;
            }
            default: return false;
        }
    }

    const createSession = async () => {
        setLoading(true);
        const lcsClient = await SessionClient.getLcsClient();
        const res = await lcsClient.post<AddStatusResponse>(LocalContentServiceUrls.Status.Add, session);
        if (res.ok()) {
            if (res.data!.imageUploadDetails.length > 0) {
                // create array of async promises to use Promise.all
                let promiseArray: any[] = [];
                res.data!.imageUploadDetails.forEach((img, index) => {
                    promiseArray.push(uploadImage(imgs[index], img, res.data!.statusId!.toString()));
                });
                await Promise.all(promiseArray);
            }
            props.history.goBack();
        } else {
            setLoading(false);
            setErr(res.data!.errors![0])
        }
    }

    return (
        <Layout step={step} session={session} imgs={imgs}>
            {renderStep()}
            <Box marginY={4} textAlign='right'> 
                <LoadingButton variant="contained" color="primary" loading={isLoading} onClick={handleClick} disabled={isBtnDisabled()}>
                    {step < Steps.Options ? 'NEXT' : 'ADD SESSION'}
                </LoadingButton>
            </Box>
            <ErrorSnackbar open={err.length > 0} err={err} />
        </Layout>
    )
}

export const CreateSessionView = withRouter(CreateSession);