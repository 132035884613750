import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: 345,
        width: '100%',
        margin: 'auto',
        textAlign: 'left'
    },
    media: {
        height: 'auto',
        paddingTop: '56.25%', // 16:9
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
    },
    stats: {
        color: theme.palette.primary.main,
        margin: theme.spacing(1),
        fontSize: 16
    },
    statIcon: {
        marginRight: theme.spacing(0.5)
    },
    contentText: {
        overflowWrap: 'break-word'
    },
    active: {
        color: theme.palette.primary.main,
    },
    expired: {
        color: theme.palette.error.light,
    },
    scheduled: {
        color: theme.palette.secondary.light
    }
}));