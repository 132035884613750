import React, { useState, useEffect, useRef } from 'react';
import { Container, Box, Typography, Grid, TextField, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { Geofence, ChangeGeofenceCoordinatesRequest, GeoLocation, SessionClient, BooleanResponse, GeofenceTranslation, GeofenceServiceUrls, CdnServiceUrls } from '@shout-sdk/client-sdk';
import { Map, ErrorSnackbar, LoadingButton, useUploadImage } from 'components/dashboard/common';
import { LngLat } from 'mapbox-gl';
import { EventPreview } from '../events/detail/EventPreview';
import { Store } from 'auth';
import { uploadImage } from 'components/app/helpers';

const EditGeofence: React.FC<any> = props => {

    const originalGeofence: Geofence = props.location.state.geofence;

    const [title, setTitle] = useState('');
    const [img, setImg, errorMsg] = useUploadImage(500);
    const [geofence, setGeofence] = useState<ChangeGeofenceCoordinatesRequest>(new ChangeGeofenceCoordinatesRequest());
    const [err, setErr] = useState('');
    const [isLoading, setLoading] = useState(false);
    const imgRef = useRef<any>(null);

    useEffect(() => {
        setTitle(originalGeofence.translations![0].title ?? "");
        setGeofence({ ...geofence, defaultCoordinates: originalGeofence.coordinates.default, geofenceId: originalGeofence.geofenceNumberId });
        // wait for map to load
        Map.map.on('load', () => {
            Map.editGeofence(originalGeofence);
        })
    }, []);

    const saveImage = (files: any) => {
        if (files) {
            setImg(files[0]);
        }
    }

    const handleMarker = (lnglat: LngLat) => {
        setGeofence({ ...geofence, defaultCoordinates: new GeoLocation(lnglat.lat, lnglat.lng) });
    }

    const handleGeofence = (coords: []) => {
        const data: GeoLocation[] = [];
        coords.forEach(point => {
            data.push(new GeoLocation(point[1], point[0]));
        })
        setGeofence({ ...geofence, boundaryPoints: data });
    }

    const saveChanges = async () => {
        const gfsClient = await SessionClient.getGfsClient();
        setLoading(true);
        const updateRes = await gfsClient.post<BooleanResponse>('/api/1/geofence/change_coordinates', geofence);
        if (!updateRes.ok()) { setErr(updateRes.data!.errors![0]); return; }
        if (title !== originalGeofence.translations![0].title) {
            const translation = new GeofenceTranslation();
            translation.language = 'eng';
            translation.title = title;
            // if geofence has an owner, use onBehalfOf header
            originalGeofence.userId ?
                await gfsClient.postOnBehalfOf<any>(GeofenceServiceUrls.Geofence.ChangeTranslation, {
                    geofenceId: originalGeofence.geofenceNumberId,
                    translation
                }, originalGeofence.userId) :
                await gfsClient.post<any>(GeofenceServiceUrls.Geofence.ChangeTranslation, {
                    geofenceId: originalGeofence.geofenceNumberId,
                    translation
                })
        }
        if (img) {
            const imageResponse = await gfsClient.post<any>('/api/1/geofence/change_image', { id: originalGeofence.geofenceNumberId });
            if (imageResponse.ok()) {
                await uploadImage(img, imageResponse.data.imageUploadDetails, geofence.geofenceId.toString());
            }
        }
        props.history.push('/geofences');
    }

    return (
        <Container maxWidth='lg'>
            <Box marginY={4}>
                <Typography variant='h2' color='primary'>Edit Geofence</Typography>
                <Box marginY={2}>
                    <Map height={400}
                        lnglat={[geofence.defaultCoordinates.longitude, geofence.defaultCoordinates.latitude]}
                        moveMarker={handleMarker}
                        addGeofence={handleGeofence}
                        dblClick enableDraw />
                    <Grid spacing={4} container>
                        <Grid md={6} xs={12} item>
                            <TextField value={title} onChange={ev => setTitle(ev.target.value)}
                                label='Change Geofence Name' variant='outlined' margin='normal' fullWidth />
                            <Button variant='outlined' color='primary' onClick={() => imgRef.current.click()}>
                                {originalGeofence.thumbnailImageUrl ? 'Change Image' : 'Add Image'}
                            </Button>
                            <input type="file" accept="image/png, image/jpeg" ref={imgRef} style={{ display: 'none' }}
                                onChange={ev => saveImage(ev.target.files)} />
                        </Grid>
                        <Grid md={4} xs={12} item>
                            <Box marginTop={2}>
                                <EventPreview img={img ? URL.createObjectURL(img) : originalGeofence.thumbnailImageUrl} name={title} />
                            </Box>
                        </Grid>
                    </Grid>
                    <Box marginY={6} textAlign='center'>
                        <LoadingButton variant='contained' color='primary' loading={isLoading} onClick={saveChanges}>
                            Save Changes
                        </LoadingButton>
                    </Box>
                </Box>
                <ErrorSnackbar open={errorMsg.length > 0} err={errorMsg} />
                <ErrorSnackbar open={err.length > 0} err={err} />
            </Box>
        </Container>
    )
}

export const EditGeofenceView = withRouter(EditGeofence);