import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@material-ui/core';
import { SessionClient } from '@shout-sdk/client-sdk';
import { withRouter } from 'react-router';
import MailIcon from '@material-ui/icons/Mail';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { dateOptions } from 'components/app/constants';
import { EmptyPlaceholder } from 'components/dashboard/common';

const AllPending: React.FC<any> = props => {

    const [regs, setRegs] = useState([]);

    useEffect(() => {
        const fetchRegistrations = async () => {
            const uasClient = await SessionClient.getUasClient();
            const res = await uasClient.get<any>('/api/1/registrations/organization');
            if (res.ok()) {
                setRegs(res.data.registrations);
            }
        }
        fetchRegistrations();
    }, [])

    return (
        <Container maxWidth="lg">
            <Box marginY={4}>
                <Typography variant='h2' color='primary'>Pending Registrations</Typography>
                <Box marginY={4}>
                    {regs.length > 0 ?
                        <Paper>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Organization Name</TableCell>
                                        <TableCell>Contact Name</TableCell>
                                        <TableCell>Location</TableCell>
                                        <TableCell>Registration Date</TableCell>
                                        <TableCell>Progress</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {regs.map((reg: any) => (
                                        <TableRow key={reg.userId}
                                            onClick={() => props.history.push({ pathname: '/pending/approve', state: { org: reg } })} hover>
                                            <TableCell>{reg.organizationName}</TableCell>
                                            <TableCell>{reg.contactName}</TableCell>
                                            {reg.organizationAddress && <TableCell>{reg.organizationAddress.city}, {reg.organizationAddress.countryCode}</TableCell>}
                                            <TableCell>{new Date(reg.registrationDate).toLocaleString([], dateOptions)}</TableCell>
                                            <TableCell>
                                                <MailIcon color={reg.invitationLastSent ? 'primary' : 'disabled'} />
                                                <LocationOnIcon color={reg.progress.locationSet ? 'primary' : 'disabled'} />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper> :
                        <EmptyPlaceholder text='No businesses found.' />
                    }
                </Box>
            </Box>
        </Container>
    )
}

export const AllPendingView = withRouter(AllPending);