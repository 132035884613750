import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Container, Box, Typography, Grid, Table, Paper, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { SessionClient, GeofenceSpread, AccountSubscription } from '@shout-sdk/client-sdk';
import { CurrentUser } from 'auth';
import { DeviceCard } from './DeviceCard';
import { FloatingFab, EmptyPlaceholder } from 'components/dashboard/common';
import AddIcon from '@material-ui/icons/Add';
import { PreferenceList } from './PreferenceList';

const AllDevices: React.FC<any> = props => {

    const [devices, setDevices] = useState([]);
    const [preferences, setPreferences] = useState([]);
    const { userId } = CurrentUser.getProfile();
    const isPremium = CurrentUser.getProfile().features!.accountSubscription >= AccountSubscription.Premium;

    useEffect(() => {
        const fetchDevices = async () => {
            const tvsClient = await SessionClient.getTvPreferenceServiceClient();
            const res = await tvsClient.get<any>('/api/1/devices?ownerId=' + userId);
            if (res.ok()) {
                setDevices(res.data.devices);
            }
        }
        const fetchPrefs = async () => {
            const tvsClient = await SessionClient.getTvPreferenceServiceClient();
            const res = await tvsClient.get<any>('/api/1/preferences?ownerId=' + userId);
            if (res.ok()) {
                setPreferences(res.data.preferenceHolders);
            }
        }
        fetchDevices();
        fetchPrefs();
    }, []);

    return (
        <Container maxWidth='lg'>
            <Box marginY={4}>
                <Typography variant='h2' color='primary' gutterBottom>Your Devices</Typography>
                <Box marginY={4}>
                    <Grid spacing={4} container>
                        {devices.map(device => (
                            <Grid md={4} xs={12} item>
                                <DeviceCard device={device} preferences={preferences} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <PreferenceList preferences={preferences} />
                {devices.length === 0 &&
                    <EmptyPlaceholder text={isPremium ? "You don't have any devices." : "Please upgrade to Premium for this feature."} />
                }
            </Box>
            <FloatingFab color="primary" variant="extended" onClick={() => props.history.push('/devices/create')} disabled={!isPremium}>
                New Preference <br /><AddIcon />
            </FloatingFab>
        </Container>
    )
}

export const AllDevicesView = withRouter(AllDevices);