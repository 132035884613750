import React, { useState } from 'react';
import { Box, makeStyles, Avatar, Typography, Fab, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { Group } from '@shout-sdk/client-sdk';
import EditIcon from '@material-ui/icons/Edit';
import { EditGroup } from './EditGroup';
import GroupIcon from '@material-ui/icons/Group';

interface IProps {
    group: Group
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        position: 'relative'
    },
    avatar: {
        height: 96,
        width: 96
    },
    fab: {
        position: 'absolute',
        right: 0
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0, 2)
    }
}));

export const GroupHeader: React.FC<IProps> = props => {
    const classes = useStyles();
    const [showEdit, setShowEdit] = useState(false);
    const { group } = props;

    if (group.groupId === 0) {
        return <div />
    }

    return (
        <Box className={classes.container}>
            <Avatar className={classes.avatar} src={group.imageUrl!}>
                {!group.imageUrl && <GroupIcon fontSize='large' />}
            </Avatar>
            <Box className={classes.title}>
                <Typography variant='h2' color='primary'>{group.translations[0].title}</Typography>
                <Typography color='textSecondary'>{group.translations[0].description}</Typography>
            </Box>
            <Fab className={classes.fab} color='primary' onClick={() => setShowEdit(true)}>
                <EditIcon />
            </Fab>
            <Dialog open={showEdit} onClose={() => setShowEdit(false)} fullWidth>
                <DialogTitle>Edit Group</DialogTitle>
                <DialogContent>
                    <EditGroup group={group} />
                </DialogContent>
            </Dialog>
        </Box>
    )
}