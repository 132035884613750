import React from 'react';
import { Select, FormControl, InputLabel, MenuItem, Switch, FormControlLabel, Box } from '@material-ui/core';
import { CategoryPicker } from './CategoryPicker';
import { CurrentUser } from 'auth';
import { useEffect } from 'react';
import { SessionClient, GeofencesResponse, GeofenceServiceUrls, GetPostableGeofencesRequest, GeoLocation, Geofence, AddStatusRequest } from '@shout-sdk/client-sdk';
import { Map } from 'components/dashboard/common';

interface IProps {
    status: AddStatusRequest,
    setStatus: React.Dispatch<React.SetStateAction<AddStatusRequest>>,
    setEventDates: React.Dispatch<React.SetStateAction<Date[]>>
}

export const StatusCategory: React.FC<IProps> = props => {

    const [postable, setPostable] = React.useState<Geofence[]>([]);
    const { status, setStatus, setEventDates } = props;

    useEffect(() => {
        const fetchPostable = async () => {
            const gfsClient = await SessionClient.getGfsClient();
            const orgLocation = CurrentUser.getOrganizationInfo().location;
            const res = await gfsClient.post<GeofencesResponse>(GeofenceServiceUrls.Geofence.PostableGeofences,
                new GetPostableGeofencesRequest(new GeoLocation(orgLocation.latitude, orgLocation.longitude)));
            if (res.ok()) {
                setPostable(res.data!.results!);
            }
        }
        fetchPostable();
    }, []);

    // set initial geofence after fetchPostable()
    useEffect(() => {
        if (postable.length > 0) {
            const currUser = CurrentUser.getProfile()
            const defaultGeofence = currUser.defaultGeofenceId ? currUser.defaultGeofenceId : currUser.geofencesOwned[0];
            setStatus({ ...status, geofenceNumberId: defaultGeofence });
            if (Map.map) Map.displayGeofence(getGeofenceById(defaultGeofence));
        }
    }, [postable])

    const getGeofenceById = (id: number): Geofence => {
        return postable.find(geofence => geofence.geofenceNumberId === id)!;
    }

    const handleGeofence = (geofenceNumberId: number) => {
        // get geofence, display it and set id and location
        const geofence = getGeofenceById(geofenceNumberId);
        // check if it's future event
        if (geofence.endDate) {
            if (new Date(geofence.startDate) > new Date) {
                setEventDates([new Date(geofence.startDate), new Date(geofence.endDate)]);
            }
        }
        if (Map.map) Map.displayGeofence(geofence);
        const { latitude, longitude } = geofence.coordinates.default;
        setStatus({ ...status, geofenceNumberId, location: new GeoLocation(latitude, longitude) });
    }

    return (
        <div>
            <FormControl variant="outlined" margin="normal" fullWidth>
                <InputLabel>
                    Select a Geofence
                </InputLabel>
                <Select labelWidth={130} value={status.geofenceNumberId} onChange={(ev: any) => handleGeofence(ev.target.value)}>
                    {postable.map(geofence => {
                        return <MenuItem key={geofence.geofenceNumberId} value={geofence.geofenceNumberId}>{geofence.translations![0].title}</MenuItem>
                    })}
                </Select>
                <FormControlLabel
                    control={
                        <Switch
                            checked={status.isGeofenceExclusive}
                            onChange={ev => setStatus({ ...status, isGeofenceExclusive: ev.target.checked })}
                            color="primary"
                        />
                    }
                    label="Status exclusive to this Geofence"
                />
            </FormControl>
            <CategoryPicker geofence={getGeofenceById(status.geofenceNumberId)}
                onChange={(cats: any) => setStatus({ ...status, ...cats })} />
        </div>
    )
}