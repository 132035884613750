import React from 'react';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        height: '95vh',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

export const Loader: React.FC<any> = props => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <CircularProgress />
        </Box>
    )
}