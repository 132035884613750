import React, { useState, useEffect } from 'react';
import { Container, Box, Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@material-ui/core';
import { withRouter } from 'react-router';
import { LngLat } from 'mapbox-gl';
import { Map } from 'components/dashboard/common/Map';
import { Geofence, GeofencesResponse, GeoLocation, SessionClient, GeofenceSpread } from '@shout-sdk/client-sdk';
import { FloatingFab } from '../common';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

const SearchGeofence: React.FC<any> = props => {

    const [lngLat, setlngLat] = useState(new LngLat(0, 0));
    const [geofences, setGeofences] = React.useState<Geofence[] | null>([]);

    useEffect(() => {
        if (lngLat.lat !== 0 && lngLat.lng !== 0) {
            const searchGeofences = async () => {
                const gfsClient = await SessionClient.getGfsClient();
                const res = await gfsClient.post<GeofencesResponse>('/api/1/geofence/nearby', { location: new GeoLocation(lngLat.lat, lngLat.lng) });
                if (res.ok()) {
                    setGeofences(res.data!.results);
                }
            }
            searchGeofences();
        }
    }, [lngLat]);

    return (
        <Container maxWidth="lg">
            <Box marginY={4}>
                <Typography variant='h2' color='primary'>Geofence Search</Typography>
                <Box marginY={4}>
                    <Map lnglat={lngLat} moveMarker={setlngLat} height={300} />
                </Box>
                {geofences && geofences.length > 0 &&
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Spread</TableCell>
                                    <TableCell>Active Statuses</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {geofences.map(geofence => (
                                    <TableRow key={geofence.id} onClick={() => Map.displayGeofence(geofence)} hover>
                                        <TableCell component="th" scope="row">
                                            {geofence.translations![0].title}
                                        </TableCell>
                                        <TableCell>{GeofenceSpread[geofence.geofenceSpread]}</TableCell>
                                        <TableCell>{geofence.activeStatusCount}</TableCell>
                                        <TableCell style={{ padding: 0 }}>
                                            <IconButton onClick={() => props.history.push({ pathname: '/geofences/edit', state: { geofence } })}>
                                                <EditIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                }
            </Box>
            <FloatingFab color="primary" variant="extended" onClick={() => props.history.push('/geofences/new')}>
                NEW <br /><AddIcon />
            </FloatingFab>
        </Container>
    )
}

export const SearchGeofenceView = withRouter(SearchGeofence);