import React from 'react';
import { Box, Typography } from '@material-ui/core';

export const RegisterSuccess: React.FC<any> = props => {

    return (
        <Box marginY={16} textAlign='center'>
            <Typography variant='h6'>
                Thanks for signing up for Shout! &#128588; <br />
                You will be contacted by us in the near future.
            </Typography>
        </Box>
    )
}