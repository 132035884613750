import React, { useState } from 'react';
import { Box, ButtonBase, Typography, makeStyles, Grid, Collapse } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { GeofenceCard } from './GeofenceCard';
import clsx from 'clsx';
import { Geofence } from '@shout-sdk/client-sdk';

const useStyles = makeStyles(theme => ({
    collapseHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    arrowClosed: {
        transform: 'rotate(90deg)'
    },
    margin: {
        marginTop: theme.spacing(2)
    }
}))

export const GeofenceGrid: React.FC<{title: string, geofences: Geofence[], expired?: boolean}> = props => {
    const classes = useStyles();
    const [isOpen, setOpen] = useState(true);

    const { title, geofences, expired } = props;

    return (
        <Box marginY={4}>
            <ButtonBase onClick={() => setOpen(!isOpen)}>
                <Typography color={expired ? 'error' : 'secondary'} className={classes.collapseHeader} variant="h5">
                    {title}
                    <ArrowDropDownIcon className={clsx(!isOpen && classes.arrowClosed)} />
                </Typography>
            </ButtonBase>
            <Collapse in={isOpen}>
                <Grid spacing={2} container>
                    {geofences.map(geofence => {
                        return <Grid key={geofence.geofenceNumberId} md={4} xs={12} className={classes.margin} item>
                            <GeofenceCard geofence={geofence} />
                        </Grid>
                    })}
                </Grid>
            </Collapse>
        </Box>
    )
}