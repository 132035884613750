import React, { useState, useRef } from 'react';
import { Box, TextField, makeStyles, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import clsx from 'clsx';
import { AddStatusRequest, StatusTranslation } from '@shout-sdk/client-sdk';

interface IProps {
    session: AddStatusRequest,
    setSession: React.Dispatch<React.SetStateAction<AddStatusRequest>>,
    setImages: React.Dispatch<React.SetStateAction<File[]>>
}

const useStyles = makeStyles(theme => ({
    input: {
        '& textarea': {
            minHeight: 0,
            transition: 'all 0.2s ease',
        }
    },
    expanded: {
        '& textarea': {
            minHeight: 100,
        }
    },

}));

const translation = new StatusTranslation();
translation.language = 'eng';

export const SessionContent: React.FC<IProps> = props => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const imgRef = useRef<any>(null);
    const { session, setSession, setImages } = props;

    const handleDescription = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
        translation.message = ev.target.value;
        setSession({ ...session, translation });
    }

    const handleTitle = (ev: React.ChangeEvent<HTMLInputElement>) => {
        translation.title = ev.target.value;
        setSession({ ...session, translation });
    }

    const handleRoomName = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const text = ev.target.value
        setSession({ ...session, roomName: text.trim().length > 0 ? text : null });
    }

    const isInvalidDescription = (): boolean => {
        const length = session.translation.message?.length ?? 0;
        return (length > 0 && length < 4) || length > 280;
    }

    const saveImages = (files: FileList | null) => {
        if (files) {
            // get first 4 images
            setImages(Array.from(files).slice(0, 4));
        }
    }

    return (
        <Box>
            <TextField
                label='Session Title'
                variant='outlined'
                margin='normal'
                onChange={handleTitle}
                fullWidth
                required />
            <TextField
                label='Room Name (optional)'
                variant='outlined'
                margin='normal'
                onChange={handleRoomName}
                fullWidth />
            <TextField
                label='Session Description'
                variant='outlined'
                margin='normal'
                onChange={handleDescription}
                helperText={`${session.translation.message?.length ?? 0}/280`}
                InputProps={{
                    className: clsx(expanded && classes.expanded, classes.input)
                }}
                onClick={() => setExpanded(true)}
                error={isInvalidDescription()}
                fullWidth
                multiline
                required />
            <Box textAlign='center'>
                <Button
                    color="primary"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                    size="large"
                    onClick={() => imgRef.current!.click()}
                >
                    ADD IMAGES
                </Button>
            </Box>
            <input type="file" accept="image/png, image/jpeg" ref={imgRef} style={{ display: 'none' }}
                onChange={ev => saveImages(ev.target.files)} multiple />
        </Box>
    )
}