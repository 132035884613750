import React, { useState } from 'react';
import { Typography, ButtonBase, Collapse } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Masonry from 'react-masonry-css';
import { useStyles } from './AllStatusesStyles';
import clsx from 'clsx';

const masonryBreaks = {
    default: 3,
    900: 2,
    700: 1
}

export const StatusGrid: React.FC<any> = props => {
    const classes = useStyles();
    const [isOpen, setOpen] = useState(true);

    return (
        <div>
            <Typography className={clsx(props.active && classes.active,
                props.scheduled && classes.scheduled,
                props.expired && classes.expired,
                classes.collapseHeader)} variant="h5">
                <ButtonBase onClick={() => setOpen(!isOpen)}>
                    {props.title}
                    <ArrowDropDownIcon className={clsx(!isOpen && classes.arrowClosed)} />
                </ButtonBase>
            </Typography>
            <Collapse in={isOpen}>
                <Masonry breakpointCols={masonryBreaks} className={classes.masonry} columnClassName={classes.masonryCol}>
                    {props.children}
                </Masonry>
            </Collapse>
        </div>
    )
}