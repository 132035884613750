import React from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import { GeofenceGrid } from './GeofenceGrid';
import AddIcon from '@material-ui/icons/Add';
import { withRouter } from 'react-router';
import { FloatingFab, useMyGeofences, EmptyPlaceholder, Loader } from 'components/dashboard/common';

const AllEvents: React.FC<any> = props => {
    const now = new Date();
    const { geofences, isLoading } = useMyGeofences();

    if (isLoading) return <Loader />;

    const expired = geofences.filter(gf => gf.endDate && new Date(gf.endDate) < now);
    const events = geofences.filter(gf => gf.endDate && !expired.includes(gf));

    return (
        <Container maxWidth="lg">
            <Box marginY={4}>
                <Typography variant='h2' color='primary' gutterBottom>Events Overview</Typography>
                {events.length > 0 &&
                    <GeofenceGrid geofences={events} title="Your Events" />
                }
                {expired.length > 0 &&
                    <GeofenceGrid geofences={expired} title="Past Events" expired />
                }
                {geofences.length === 0 && 
                    <EmptyPlaceholder text="You currently don't have any events." />
                }
                <FloatingFab color="primary" variant="extended" onClick={() => props.history.push('/events/create')}>
                    NEW EVENT <br /><AddIcon />
                </FloatingFab>
            </Box>
        </Container>
    )
}

export const AllEventsView = withRouter(AllEvents);