import React, { useEffect } from 'react';
import { makeStyles, Box, Typography, TextField, ButtonBase } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        margin: theme.spacing(2, 0)
    },
    pickerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    count: {
        width: 80,
        height: 80,
        fontSize: 32,
        '& input': {
            textAlign: 'center'
        }
    }
}))

export const ExpirationPicker: React.FC<any> = props => {
    const classes = useStyles();
    const [mins, setMins] = React.useState(0);
    const [hours, setHours] = React.useState(22);
    const [days, setDays] = React.useState(1);

    const { expirationTime, onChange } = props;

    useEffect(() => {
        let seconds = expirationTime;
        const days = Math.floor(seconds / (3600 * 24));
        setDays(days);
        seconds -= days * 3600 * 24;
        const hrs = Math.floor(seconds / 3600);
        setHours(hrs);
        seconds -= hrs * 3600;
        setMins(Math.floor(seconds / 60));
    }, [expirationTime])

    const handleDays = (newValue: number) => {
        if (newValue > 27 || newValue < 0) { return }
        onChange(newValue * 86400 + hours * 3600 + mins * 60);
    }

    const handleHours = (newValue: number) => {
        if (newValue > 23 || newValue < 0) { return }
        onChange(days * 86400 + newValue * 3600 + mins * 60);
    }

    const handleMins = (newValue: number) => {
        if (newValue > 59 || newValue < 0) { return }
        onChange(days * 86400 + hours * 3600 + newValue * 60);
    }

    return (
        <Box className={classes.container}>
            <Box className={classes.pickerContainer}>
                <ButtonBase onClick={() => handleDays(days + 1)}>
                    <KeyboardArrowUpIcon color="primary" fontSize="large" />
                </ButtonBase>
                <Box className={classes.count}>
                    <TextField
                        type="number"
                        InputProps={{ className: classes.count }}
                        variant="outlined"
                        value={days}
                        onChange={ev => handleDays(Number(ev.target.value))}
                    />
                </Box>
                <ButtonBase onClick={() => handleDays(days - 1)}>
                    <KeyboardArrowDownIcon color="primary" fontSize="large" />
                </ButtonBase>
                <Typography color="primary">DAYS</Typography>
            </Box>
            <Box className={classes.pickerContainer}>
                <ButtonBase onClick={() => handleHours(hours + 1)}>
                    <KeyboardArrowUpIcon color="primary" fontSize="large" />
                </ButtonBase>
                <Box className={classes.count}>
                    <TextField
                        type="number"
                        InputProps={{ className: classes.count }}
                        variant="outlined"
                        value={hours}
                        onChange={ev => handleHours(Number(ev.target.value))}
                    />
                </Box>
                <ButtonBase onClick={() => handleHours(hours - 1)}>
                    <KeyboardArrowDownIcon color="primary" fontSize="large" />
                </ButtonBase>
                <Typography color="primary">HOURS</Typography>
            </Box>
            <Box className={classes.pickerContainer}>
                <ButtonBase onClick={() => handleMins(mins + 1)}>
                    <KeyboardArrowUpIcon color="primary" fontSize="large" />
                </ButtonBase>
                <Box className={classes.count}>
                    <TextField
                        type="number"
                        InputProps={{ className: classes.count }}
                        variant="outlined"
                        value={mins}
                        onChange={ev => handleMins(Number(ev.target.value))}
                    />
                </Box>
                <ButtonBase onClick={() => handleMins(mins - 1)}>
                    <KeyboardArrowDownIcon color="primary" fontSize="large" />
                </ButtonBase>
                <Typography color="primary">MINUTES</Typography>
            </Box>
        </Box>
    )
}