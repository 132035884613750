import React, { useState } from 'react';
import { Box, makeStyles, Typography, Container, Fab, Dialog, DialogTitle, Slide, DialogContent } from '@material-ui/core';
import { Geofence } from '@shout-sdk/client-sdk';
import { dateOptions, timeOptions } from 'components/app/constants';
import { areDatesOnSameDay } from 'components/app/helpers';
import EditIcon from '@material-ui/icons/Edit';
import { EditEvent } from './EditEvent';

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        height: 240,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0
    },
    bgOverlay: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: theme.palette.secondary.main,
        opacity: 0.36,
        top: 0,
        left: 0
    },
    content: {
        zIndex: 1,
        color: theme.palette.primary.contrastText,
        padding: theme.spacing(4),
        width: '100%'
    },
    title: {
        overflowY: 'hidden'
    },
    fab: {
        zIndex: 1,
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
        container: {
            top: theme.spacing(8)
        },
    },
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
        container: {
            top: theme.spacing(7)
        },
    }
}))

export const EventHeader: React.FC<any> = props => {
    const classes = useStyles();
    const event: Geofence = props.event;
    const [showEdit, setShowEdit] = useState(false);

    const renderDate = () => {
        const start = new Date(event.startDate);
        const end = new Date(event.endDate!);
        const isSameDay = areDatesOnSameDay(start, end);
        return `${start.toLocaleString([], dateOptions)} - 
        ${isSameDay ? end.toLocaleTimeString([], timeOptions) : end.toLocaleString([], dateOptions)}`;
    }

    return (
        <Box className={classes.container} style={{ backgroundImage: `url(${event.imageUrl})` }}>
            <Box className={classes.content}>
                <Container maxWidth="lg">
                    <Typography className={classes.title} variant="h2" gutterBottom>{event.translations![0].title}</Typography>
                    <Typography variant="h5">{renderDate()}</Typography>
                </Container>
            </Box>
            <Fab color='primary' className={classes.fab} onClick={() => setShowEdit(true)}>
                <EditIcon />
            </Fab>
            <Box className={classes.bgOverlay} />
            <Dialog open={showEdit} onClose={() => setShowEdit(false)} fullWidth>
                <DialogTitle>Edit Event</DialogTitle>
                <DialogContent>
                    <EditEvent event={event} />
                </DialogContent>
            </Dialog>
        </Box>
    )
}