import { makeStyles } from '@material-ui/core'; 

export const useStyles = makeStyles(theme => ({
    container: {
        height: 240,
        width: '100%',
        backgroundColor: 'grey',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.secondary.contrastText,

        '& > *': {
            zIndex: 1
        }
    },
    bgOverlay: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: theme.palette.secondary.main,
        opacity: 0.36,
        top: 0,
        left: 0
    },
    avatar: {
        height: 144,
        width: 144,
        backgroundColor: theme.palette.secondary.main,
        marginBottom: theme.spacing(2),
        fontSize: 72,
        display: 'inline-flex'
    },
    fab: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2)
    },
    [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
        container: {
            top: theme.spacing(8)
        },
    },
    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
        container: {
            top: theme.spacing(7)
        },
    },
    coverImgPreview: {
        width: 300,
        height: 150,
        margin: '16px auto',
        display: 'block',
        backgroundColor: theme.palette.secondary.main,
        objectFit: 'cover'
    }
}))