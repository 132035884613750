import React from 'react';
import { TextField, InputAdornment, Select, MenuItem } from '@material-ui/core';
import { PhoneNumberDetails } from '@shout-sdk/client-sdk';
import countries from '../../app/countries.json';

export const PhoneInput: React.FC<any> = props => {

    const { phoneNumber, onChange } = props;

    return (
        <TextField
            label="Phone Number"
            margin="normal"
            type="number"
            variant="outlined"
            value={phoneNumber ? phoneNumber.number : ''}
            onChange={(ev: any) => onChange({ ...phoneNumber, number: ev.target.value, equals: PhoneNumberDetails.prototype.equals })}
            fullWidth
            required
            InputProps={{
                startAdornment:
                    <InputAdornment position="start">
                        <Select
                            disableUnderline
                            renderValue={value => `+${value}`}
                            value={phoneNumber ? phoneNumber.countryCode : ''}
                            onChange={(ev: any) => onChange({ ...phoneNumber, countryCode: ev.target.value, equals: PhoneNumberDetails.prototype.equals })}
                        >
                            {countries.map(country => {
                                return <MenuItem key={country.Name} value={country.PhoneCode}>
                                    {country.Name} +{country.PhoneCode}
                                </MenuItem>
                            })}
                        </Select>
                    </InputAdornment>
            }}
        />
    )
}