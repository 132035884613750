import React from 'react'
import { Box, Typography } from '@material-ui/core';
import { TargetAudiencePicker } from 'components/dashboard/statuses/create/steps/StatusOptions/TargetAudiencePicker';
import { SchedulePicker } from 'components/dashboard/statuses/create/steps/StatusOptions/SchedulePicker';
import { StatusTargetAudience } from '@shout-sdk/client-sdk';
import { dateOptions } from 'components/app/constants';


export const SessionOptions: React.FC<any> = props => {

    const { session, setSession, geofence } = props;

    return (
        <Box>
            <Box textAlign="left" >
                <Typography>Event Start/End: {new Date(geofence.startDate).toLocaleString([], dateOptions)} - {new Date(geofence.endDate).toLocaleString([], dateOptions)}</Typography>
                <SchedulePicker status={session} setStatus={setSession} />
            </Box>
            <Box marginTop={2}>
                <TargetAudiencePicker onChange={(targetAudience: StatusTargetAudience) => setSession({ ...session, targetAudience })} />
            </Box>
        </Box>
    )
}