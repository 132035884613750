import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
    active: {
        color: theme.palette.primary.main,
        margin: theme.spacing(2, 0),
    },
    expired: {
        color: theme.palette.error.light,
        margin: theme.spacing(2, 0),
    },
    scheduled: {
        color: theme.palette.secondary.light,
        margin: theme.spacing(2, 0),
    },
    masonry: {
        display: 'flex',
        marginLeft: -theme.spacing(2)
    },
    masonryCol: {
        paddingLeft: theme.spacing(2),
        backgroundClip: 'padding-box',

        '& > div': {
            marginBottom: theme.spacing(2)
        }
    },
    collapseHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    arrowClosed: {
        transform: 'rotate(90deg)'
    },
    emptyContainer: {
        height: '50vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
}));