import React, { useState, useRef } from 'react';
import { Box, Avatar, TextField, makeStyles, Button } from '@material-ui/core';
import { Group, SessionClient } from '@shout-sdk/client-sdk';
import { LoadingButton } from 'components/dashboard/common';
import { uploadImage } from 'components/app/helpers';
import GroupIcon from '@material-ui/icons/Group';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        height: 96,
        width: 96,
        marginBottom: theme.spacing(2)
    },
    footer: {
        width: '100%',
        margin: theme.spacing(2, 0),
        display: 'flex',
        flexDirection: 'row-reverse'
    }
}))

export const EditGroup: React.FC<any> = props => {
    const classes = useStyles();
    const group: Group = props.group;
    const [img, setImg] = useState<File | null>(null);
    const [title, setTitle] = useState(group.translations[0].title);
    const [description, setDescription] = useState(group.translations[0].description);
    const [isLoading, setLoading] = useState(false);
    const imgRef = useRef<any>(null);

    const saveImage = (files: FileList | null) => {
        if (files) {
            setImg(files[0]);
        }
    }

    const saveChanges = async () => {
        if (isTitleInValid()) { return }
        setLoading(true);
        const uasClient = await SessionClient.getUasClient();
        if (title !== group.translations[0].title || description !== group.translations[0].description) {
            await uasClient.post(`/api/1/groups/${group.groupId}/translations/eng`, { title, description });
        }
        if (img) {
            const ticketRes = await uasClient.get<any>(`/api/1/groups/${group.groupId}/image/upload_ticket`);
            if (ticketRes.ok()) {
                await uploadImage(img, ticketRes.data.fileUploadDetails, group.groupId.toString());
            }

        }
        window.location.reload();
    }

    const isTitleInValid = (): boolean => {
        return title.trim().length < 2;
    }

    return (
        <Box className={classes.container}>
            <Avatar className={classes.avatar} src={img ? URL.createObjectURL(img) : group.imageUrl!}>
                {!img && !group.imageUrl && <GroupIcon fontSize='large' />}
            </Avatar>
            <Button variant='outlined' color='primary' onClick={() => imgRef.current!.click()}>Change Image</Button>
            <input type="file" accept="image/png, image/jpeg" ref={imgRef} style={{ display: 'none' }}
                onChange={ev => saveImage(ev.target.files)} />
            <TextField
                label="Change Group Name"
                variant="outlined"
                margin="normal"
                value={title}
                error={isTitleInValid()}
                helperText={isTitleInValid() && "Minimum of 2 characters!"}
                onChange={ev => setTitle(ev.target.value)}
                fullWidth
                required
            />
            <TextField
                label="Change Group Description"
                variant="outlined"
                margin="normal"
                value={description}
                onChange={ev => setDescription(ev.target.value)}
                fullWidth
                multiline
            />
            <Box className={classes.footer}>
                <LoadingButton onClick={saveChanges} loading={isLoading} disabled={isTitleInValid()} variant='contained' color='primary'>Save Changes</LoadingButton>
            </Box>
        </Box>
    )
}