import React, { useEffect, useState } from 'react';
import { Box, Container, Button, Typography } from '@material-ui/core';
import { withRouter } from 'react-router';
import { Geofence, SessionClient } from '@shout-sdk/client-sdk';
import { EventHeader } from './EventHeader';
import { EventSchedule } from './EventSchedule';
import { EditEvent } from './EditEvent';
import { EventStatuses } from './EventStatuses';
import { Map, FloatingFab } from 'components/dashboard/common';
import AddIcon from '@material-ui/icons/Add';
import { DeleteEventBtn } from './DeleteEventBtn';

const EventDetail: React.FC<any> = props => {
    const originalEvent: Geofence = props.location.state.event;
    const [event, setEvent] = useState<Geofence>(originalEvent);

    useEffect(() => {
        const fetchGeofence = async () => {
            const gfsClient = await SessionClient.getGfsClient();
            const res = await gfsClient.get<any>('/api/1/geofence/id?id=' + originalEvent.geofenceNumberId);
            if (res.ok()) {
                setEvent(res.data.geofence);
            }
        }
        if (Map.map) {
            Map.map.on('load', () => {
                Map.displayGeofence(event);
            })
        }
        fetchGeofence();
    }, [])

    return (
        <Box>
            <EventHeader event={event} />
            <Box height={240} />
            <Container maxWidth="lg">
                <EventSchedule geofenceId={event.geofenceNumberId} defaultImage={event.imageUrl} />
                <EventStatuses geofence={event.geofenceNumberId} />
                <Box marginY={4}>
                    <Map height={300} readonly />
                </Box>
                <DeleteEventBtn eventId={event.geofenceNumberId} />
            </Container>
            <FloatingFab color="primary" variant="extended" onClick={() => props.history.push({ pathname: '/events/session', state: { geofence: event } })}>
                ADD SESSION <br /><AddIcon />
            </FloatingFab>
        </Box>
    )
}

export const EventDetailView = withRouter(EventDetail);