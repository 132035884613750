import React, { SetStateAction, Dispatch, useState, useEffect } from 'react';
import { Grid, TextField, InputAdornment, Select, MenuItem } from '@material-ui/core';
import { RegisterOrganizationRequest, PhoneNumberDetails } from '@shout-sdk/client-sdk';
import { Store } from 'auth';
import countries from '../../../app/countries.json';
import { useStyles } from '../verifySms/VerifySmsStyle';

interface IProps {
    user: RegisterOrganizationRequest,
    setUser: Dispatch<SetStateAction<RegisterOrganizationRequest>>
}

export const AccountInfoForm: React.FC<IProps> = props => {
    const classes = useStyles();
    const [isInvite, setIsInvite] = useState(false);

    const { user, setUser } = props;

    // check if coming from group invitation
    useEffect(() => {
        const { registrationInfo } = Store.get();
        if (registrationInfo) {
            setIsInvite(true);
            setUser({ ...user, emailAddress: registrationInfo.emailAddress, emailInviteId: registrationInfo.emailToken });
        }
    }, [])

    return (
        <div>
            <Grid container spacing={2}>
                <Grid md={6} xs={12} item>
                    <TextField
                        label="Organization Name"
                        helperText="Name of your business"
                        margin="normal"
                        variant="outlined"
                        value={user.organizationName}
                        onChange={ev => setUser({ ...user, organizationName: ev.target.value })}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid md={6} xs={12} item>
                    <TextField
                        label="Contact Name"
                        helperText="Name of a contact person"
                        margin="normal"
                        variant="outlined"
                        value={user.contactName}
                        onChange={ev => setUser({ ...user, contactName: ev.target.value })}
                        fullWidth
                        required
                    />
                </Grid>
            </Grid>
            <TextField
                label="Email Address"
                margin="normal"
                variant="outlined"
                value={user.emailAddress}
                onChange={ev => setUser({ ...user, emailAddress: ev.target.value })}
                disabled={isInvite}
                fullWidth
                required
            />
            <TextField
                label="Phone Number"
                margin="normal"
                type="number"
                variant="outlined"
                value={user.phoneNumber.number}
                onChange={(ev: any) => setUser({ ...user, phoneNumber: { ...user.phoneNumber, number: ev.target.value, equals: PhoneNumberDetails.prototype.equals } })}
                fullWidth
                required
                InputProps={{
                    startAdornment:
                        <InputAdornment position="start">
                            <Select
                                disableUnderline
                                renderValue={value => `+${value}`}
                                value={user.phoneNumber.countryCode}
                                onChange={(ev: any) => setUser({ ...user, phoneNumber: { ...user.phoneNumber, countryCode: ev.target.value, equals: PhoneNumberDetails.prototype.equals } })}
                            >
                                {countries.map(country => {
                                    return <MenuItem key={country.Name} value={country.PhoneCode}>
                                        {country.Name} +{country.PhoneCode}
                                    </MenuItem>
                                })}
                            </Select>
                        </InputAdornment>
                }}
            />
            {/* <PasswordInput value={accData.password} onChange={(ev: any) => setAccData({ ...accData, password: ev.target.value })} /> */}
            {/* <TextField
                select
                label="Choose your subscription plan"
                helperText="You can change this later"
                margin="normal"
                variant="outlined"
                value={accData.accountSubscription}
                onChange={ev => setAccData({ ...accData, accountSubscription: Number(ev.target.value) })}
                fullWidth
                required
            >
                <MenuItem value={AccountSubscription.Lite}>Lite – Free</MenuItem>
                <MenuItem value={AccountSubscription.Standard}>Standard – £19/month</MenuItem>
                <MenuItem value={AccountSubscription.Premium}>Premium – £49/month</MenuItem>
                <MenuItem value={AccountSubscription.Enterprise1}>Enterprise 1 – £129/month</MenuItem>
                <MenuItem value={AccountSubscription.Enterprise2}>Enterprise 2 – £479/month</MenuItem>
                <MenuItem value={AccountSubscription.Enterprise3}>Enterprise 3 – £1390/month</MenuItem>
            </TextField> */}
        </div>
    )
}