import React from 'react';
import { Box, makeStyles, Chip, Slider } from '@material-ui/core';
import { useState } from 'react';
import { StatusTargetAudience } from '@shout-sdk/client-sdk';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        border: '1px solid grey',
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.grey[400],
        padding: theme.spacing(2, 0),
    },
    chip: {
        margin: theme.spacing(1),
        fontSize: theme.typography.body1.fontSize,
        padding: theme.spacing(2, 1)
    },
    slider: {
        margin: theme.spacing(2, 6)
    }
}))

export const TargetAudiencePicker: React.FC<any> = props => {
    const classes = useStyles();
    const initialState = new StatusTargetAudience();
    initialState.males = true;
    initialState.females = true;
    initialState.minAge = 13;
    initialState.maxAge = 65;
    const [targetAudience, setAudience] = useState(initialState);

    useEffect(() => {
        props.onChange({...targetAudience, 
            minAge: targetAudience.minAge === 13 ? null : targetAudience.minAge,
            maxAge: targetAudience.maxAge === 65 ? null : targetAudience.maxAge
        });
    }, [targetAudience])

    return (
        <Box>
            <Box className={classes.container}>
                <Chip color={targetAudience.males ? 'primary' : 'default'} label="Males" className={classes.chip} clickable
                    onClick={() => setAudience({ ...targetAudience, males: !targetAudience.males })} />
                <Chip color={targetAudience.females ? 'primary' : 'default'} label="Females" className={classes.chip} clickable
                    onClick={() => setAudience({ ...targetAudience, females: !targetAudience.females })} />
                <Chip color={targetAudience.adultContent ? 'primary' : 'default'} label="Mature Content" className={classes.chip} clickable
                    onClick={() => setAudience({ ...targetAudience, adultContent: !targetAudience.adultContent })} />
                <Slider className={classes.slider} min={13} max={65} value={[targetAudience.minAge!, targetAudience.maxAge!]} 
                    valueLabelDisplay="auto" marks={[ { value: 13, label: '13 years' }, { value: 65, label: '65+ years' } ]}
                    onChange={(ev, value: any) => setAudience({ ...targetAudience, minAge: value[0], maxAge: value[1] })} />
            </Box>
        </Box>
    )
}