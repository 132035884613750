import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useEffect } from 'react';
import { SessionClient } from '@shout-sdk/client-sdk';
import { ScheduleGrid } from './ScheduleGrid';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import ViewAgendaIcon from '@material-ui/icons/ViewAgenda';
import ListIcon from '@material-ui/icons/List';

enum Display {
    Cards = 0,
    List = 1
}

export const EventSchedule: React.FC<any> = props => {
    // stores sessions in object seperated by date { '2019-10-06': [...items], '2019-10-07': [...items] }
    const [sessions, setSessions] = useState<any>({});
    const [display, setDisplay] = useState<Display>(Display.Cards);
    const { geofenceId, defaultImage } = props;

    useEffect(() => {
        const fetchSessions = async () => {
            const lcsClient = await SessionClient.getLcsClient();
            const res = await lcsClient.get<any>(`/api/1/event/${geofenceId}/sessions`);
            if (res.ok()) {
                let sessObj: any = {}
                res.data.statuses.forEach((session: any) => {
                    // slice away time from dateString
                    const publicationTime = session.statusInformation.publicationTime.slice(0, 10);
                    // add sessions to objects
                    sessObj.hasOwnProperty(publicationTime) ?
                        sessObj[publicationTime].push(session) :
                        sessObj[publicationTime] = [session];
                })
                setSessions(sessObj);
            }
        }
        fetchSessions();
    }, [])

    return (
        <Box>
            <Box paddingY={4}>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography variant="h4">
                        Event Schedule
                    </Typography>
                    {Object.entries(sessions).length > 0 &&
                        <ToggleButtonGroup size="medium" value={display} exclusive onChange={(ev, value) => setDisplay(value)}>
                            <ToggleButton value={Display.Cards}>
                                <ViewAgendaIcon />
                            </ToggleButton>
                            <ToggleButton value={Display.List}>
                                <ListIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    }
                </Box>

                {Object.entries(sessions).length > 0 ?
                    Object.keys(sessions).map(date => (
                        <ScheduleGrid key={date} day={date} sessions={sessions[date]} defaultImage={defaultImage} display={display} />
                    )) :
                    <Box textAlign='center' marginTop={2}>
                        <Typography color='textSecondary' variant='h5'>This event doesn't have any Sessions.</Typography>
                    </Box>
                }
            </Box>
        </Box>
    )
}