import React, { useState } from 'react';
import { Table, TableCell, TableBody, TableRow, Typography, Paper, IconButton, Grow, Menu, MenuItem, makeStyles } from '@material-ui/core';
import { ReturnedStatus, SessionClient } from '@shout-sdk/client-sdk';
import { areDatesOnSameDay } from 'components/app/helpers';
import { timeOptions, dateOptions } from 'components/app/constants';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => ({
    time: {
        minWidth: 100
    },
    title: {
        fontWeight: 'bold'
    },
    options: {
        width: 30
    },
    description: {
        maxWidth: 400
    }
}))

export const ScheduleTable: React.FC<any> = props => {
    const classes = useStyles();
    const [showOptions, setShowOptions] = useState(0);
    const sessions: ReturnedStatus[] = props.sessions;

    const renderTime = (session: ReturnedStatus) => {
        const start = new Date(session.publicationTime ?? "");
        // @ts-ignore
        const end = new Date(session.expirationTime);
        const isSameDay = areDatesOnSameDay(start, end);
        return isSameDay ?
            `${start.toLocaleTimeString([], timeOptions)} - ${end.toLocaleTimeString([], timeOptions)}` :
            `${start.toLocaleString([], dateOptions)} - ${end.toLocaleString([], dateOptions)}`;
    }

    const deleteSession = async (statusId: number) => {
        const lcsClient = await SessionClient.getLcsClient();
        const res = await lcsClient.post<any>('/api/1/status/delete', { statusId });
        if (res.ok()) { window.location.reload() }
    }

    return (
        <Grow in>
            <Paper>
                <Table>
                    <TableBody>
                        {sessions.map((session: any) => {
                            const statusInformation: ReturnedStatus = session.statusInformation;
                            const { statusId } = statusInformation;
                            return (
                                <TableRow key={statusId}>
                                    <TableCell>
                                        <Typography color='primary'>
                                            {renderTime(statusInformation)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.title}>{statusInformation.translations![0].title}</TableCell>
                                    <TableCell className={classes.description}>{statusInformation.translations![0].title}</TableCell>
                                    <TableCell>{statusInformation.roomName}</TableCell>
                                    <TableCell className={classes.options}>
                                        <IconButton id={`${statusId}`} onClick={() => setShowOptions(statusId)}><MoreVertIcon /></IconButton>
                                    </TableCell>
                                    <Menu
                                        anchorEl={document.getElementById(`${statusId}`)}
                                        keepMounted
                                        open={showOptions === statusId}
                                        onClose={() => setShowOptions(0)}
                                    >
                                        <MenuItem onClick={() => deleteSession(statusId)}>
                                            Delete Session
                                        </MenuItem>
                                    </Menu>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </Paper>
        </Grow>
    )
}