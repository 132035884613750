import React from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { Steps } from './Steps';

export const StepHeader: React.FC<any> = props => {

    const { step } = props;

    return (
        <Stepper activeStep={step}>
            <Step completed={step > Steps.Content}>
                <StepLabel>Content</StepLabel>
            </Step>
            <Step completed={step > Steps.Category}>
                <StepLabel>Location and Category</StepLabel>
            </Step>
            <Step>
                <StepLabel>Status Options</StepLabel>
            </Step>
        </Stepper>
    )
}