import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden'
import { Sidebar } from './Sidebar/Sidebar';
import { useMediaQuery } from '@material-ui/core';
import { theme } from 'components/app/Theme';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
        position: 'relative'
    },
    container: {
        paddingTop: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    }
}));

export const Layout: React.FC<any> = props => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const isDektop = useMediaQuery(theme.breakpoints.up('lg'));
    const handleDrawer = () => {
        setOpen(!open);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Hidden lgUp>
                <AppBar position="absolute" className={classes.appBar}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawer}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Hidden>

            <Sidebar open={open || isDektop} close={() => setOpen(false)} />
            
            <main className={classes.content}>
                <Hidden lgUp>
                    <div className={classes.appBarSpacer} />
                </Hidden>
                <Container maxWidth="lg" className={classes.container}>
                    {props.viewComponent}
                </Container>
            </main>
        </div>
    )
}