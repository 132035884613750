import React from 'react';
import { Card, Box, makeStyles, Avatar, Typography } from '@material-ui/core';
import { OrganizationInformation } from '@shout-sdk/client-sdk';
import { CurrentUser } from 'auth';
import BusinessIcon from '@material-ui/icons/Business';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(3),
        alignItems: 'center'
    },
    avatar: {
        height: 96,
        width: 96,
        backgroundColor: theme.palette.primary.main
    },
    icon: {
        fontSize: 56
    }
}))

export const EventOwnerCard: React.FC<any> = props => {
    const classes = useStyles();
    const organization: OrganizationInformation = props.org ? props.org : CurrentUser.getOrganizationInfo();

    return (
        <Card>
            <Box className={classes.container}>
                <Avatar className={classes.avatar} src={organization.thumbnailUrl!}>
                    {organization.thumbnailUrl === null && <BusinessIcon className={classes.icon} />}
                </Avatar>
                <Box marginX={2}>
                    <Typography>{organization.name}</Typography>
                    <Typography color='textSecondary'>{organization.address.addressLineOne}</Typography>
                    <Typography color='textSecondary'>{organization.address.addressLineTwo}</Typography>
                    <Typography color='textSecondary'>{organization.address.postCode}</Typography>
                    <Typography color='textSecondary'>{organization.address.city}</Typography>
                </Box>
            </Box>
        </Card>
    )
}