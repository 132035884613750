import React, { useState, useEffect } from 'react';
import { Box, Grid, Container, Button } from '@material-ui/core';
import { withRouter } from 'react-router';
import { SessionClient, UserProfilePrivateInformation, OrganizationInformation, GetPublicOrganizationInformationResponse, LocalContentServiceUrls, UserAccountServiceUrls } from '@shout-sdk/client-sdk';
import { CurrentUser } from 'auth';
import { UserHeader } from './UserHeader';
import { UserDescription } from './UserDescription';
import { UserAddress } from './UserAddress';
import { UserContact } from './UserContact';
import { UserOpeningTimes } from './UserOpeningTimes';
import { UserAdminSection } from './UserAdminSection';

const UserProfile: React.FC<any> = props => {

    const [user, setUser] = useState<UserProfilePrivateInformation>(new UserProfilePrivateInformation());
    const [orgInfo, setOrgInfo] = useState<OrganizationInformation>(new OrganizationInformation());
    const { userId, invite } = props.location.state;
    const isOwner = props.location.state.userId === CurrentUser.getProfile().userId;
    const isStaff = CurrentUser.isStaff();
    const canEdit = isOwner || isStaff;

    useEffect(() => {
        const fetchUser = async () => {
            if (isOwner) {
                setUser(CurrentUser.getProfile());
            } else {
                const uasClient = await SessionClient.getUasClient();
                const res = await uasClient.getOnBehalfOf<any>(UserAccountServiceUrls.UserProfile.GetPrivateInformation, userId);
                if (res.ok()) {
                    setUser(res.data.profileInformation)
                }
            }

        }
        fetchUser();
    }, [])

    useEffect(() => {
        if (user.userId > 0) {
            fetchOrgInfo(user.organizationId!);
        }
    }, [user])

    const fetchOrgInfo = async (organizationId: number) => {
        const lcsClient = await SessionClient.getLcsClient();
        const res = await lcsClient.get<GetPublicOrganizationInformationResponse>(LocalContentServiceUrls.Organization.GetOrganization(organizationId));
        if (res.ok()) {
            setOrgInfo(res.data!.organizationInformation!);
        }
    }

    return (
        <Box>
            <UserHeader user={user} edit={canEdit} />
            <Box height={240} />
            <Container maxWidth='lg'>
                {user.userId > 0 &&
                    <Box marginY={4}>
                        <Grid spacing={2} container>
                            <Grid md={6} xs={12} item>
                                <UserDescription user={user} edit={canEdit} />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <UserContact user={user} edit={canEdit} />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <UserAddress org={orgInfo} edit={isStaff} />
                            </Grid>
                            <Grid md={6} xs={12} item>
                                <UserOpeningTimes org={orgInfo} edit={canEdit} />
                            </Grid>
                        </Grid>
                    </Box>
                }
                {isStaff && !isOwner &&
                    <UserAdminSection user={user} lastInvite={invite} />
                }
            </Container>
        </Box>
    )
}

export const UserProfileView = withRouter(UserProfile);