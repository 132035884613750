import * as React from 'react';
import { Carousel, CarouselItem, CarouselIndicators } from 'reactstrap';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    img: {
        height: 195,
        width: '100%',
        objectFit: 'cover'
    }
}))

export const StatusCardCarousel: React.FC<any> = (props) => {
    const classes = useStyles();
    const [index, setIndex] = React.useState(0);

    const items = props.imgs.map((img: any, index: number) => {
        return {
            key: index,
            src: img
        }
    })

    const renderImgs = () => {
        return props.imgs.map((img: any, index: number) => {
            return (
                <CarouselItem key={index}>
                    <img src={img} style={props.style} className={classes.img} />
                </CarouselItem>
            )
        })
    }

    return (
        // wrapper div prevents that click on carousel redirects to StatusDetailView
        <div>
            <Carousel activeIndex={index} next={() => setIndex(index + 1)} previous={() => setIndex(index - 1)}
                autoPlay={false} interval={false} keyboard={false}>
                {items.length > 1 &&
                    <CarouselIndicators items={items} activeIndex={index} onClickHandler={(newIndex: number) => setIndex(newIndex)} />
                }
                {renderImgs()}
            </Carousel>
        </div>
    )
}