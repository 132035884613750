import DashboardIcon from '@material-ui/icons/Dashboard';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import EventIcon from '@material-ui/icons/Event';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import GroupIcon from '@material-ui/icons/Group';
import PublicIcon from '@material-ui/icons/Public';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import SearchIcon from '@material-ui/icons/Search';
import TvIcon from '@material-ui/icons/Tv';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

interface INavItem {
    icon: (props: SvgIconProps) => JSX.Element,
    path: string,
    title: string
}

export const NavItems: Array<INavItem> = [
    // {
    //     icon: DashboardIcon,
    //     path: '/home',
    //     title: 'Dashboard',
    // },
    {
        icon: DynamicFeedIcon,
        path: '/statuses',
        title: 'Statuses'
    },
    {
        icon: EventIcon,
        path: '/events',
        title: 'Events'
    },
    {
        icon: LocationOnIcon,
        path: '/locations',
        title: 'Locations'
    },
    {
        icon: GroupIcon,
        path: '/groups',
        title: 'Groups'
    },
    {
        icon: TvIcon,
        path: '/devices',
        title: 'My Devices'
    }
]

export const StaffItems: Array<INavItem> = [
    {
        icon: PublicIcon,
        path: '/geofences',
        title: 'Geofences'
    },
    {
        icon: AllInboxIcon,
        path: '/pending',
        title: 'Registrations'
    },
    {
        icon: SearchIcon,
        path: '/search',
        title: 'User Search'
    }
]