import React, { useState } from 'react';
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText, Dialog, DialogTitle, DialogContent, DialogActions, TextField, InputAdornment } from '@material-ui/core';
import { IProps } from './UserProfileProps';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import { PhoneNumberDetails, SessionClient, UpdateProfileRequest, PublicContactInformation, Optional } from '@shout-sdk/client-sdk';
import { PhoneInput, ErrorSnackbar } from 'components/dashboard/common';
import { CurrentUser } from 'auth';

export const UserContact: React.FC<IProps> = props => {

    const { user, edit } = props;
    const [showEdit, setShowEdit] = useState(false);
    const [website, setWebsite] = useState(user.contactInformation!.websiteUrl);
    const [phoneNumber, setPhoneNumber] = useState<PhoneNumberDetails | null>(user.contactInformation!.phoneNumber);
    const [email, setEmail] = useState(user.contactInformation!.emailAddress);
    const [error, setError] = useState('');

    const contactDetails = [
        {
            value: user.contactInformation!.websiteUrl,
            icon: <LanguageIcon />
        },
        {
            value: user.contactInformation!.phoneNumber ? 
                `+${user.contactInformation!.phoneNumber.number}` : null,
            icon: <PhoneIcon />
        },
        {
            value: user.contactInformation!.emailAddress,
            icon: <EmailIcon />
        }
    ];

    const saveChanges = async () => {
        const uasClient = await SessionClient.getUasClient();
        const request = new UpdateProfileRequest();
        const websiteUrl = website ? website.startsWith('http://') ? website : 'http://' + website : null;
        const phone = phoneNumber;
        if (phone !== null) {
            phone.number = phoneNumber!.countryCode + phoneNumber!.number;
        }
        request.publicContactInformation = new Optional(new PublicContactInformation(email, phone, websiteUrl));
        const res = await uasClient.patch<any>(`/api/1/profile/${user.userId}`, request);
        if (res.ok()) {
            await CurrentUser.set();
            window.location.reload();
        } else {
            setError(res.data!.errors[0]);
        }
    }

    const handlePhoneInput = (phoneDetails: PhoneNumberDetails) => {
        phoneDetails.number ? setPhoneNumber(phoneDetails) : setPhoneNumber(null);
    }

    return (
        <Box>
            <Typography variant='h5'>Contact
                {edit && <Button color='primary' onClick={() => setShowEdit(true)}>EDIT</Button>}
            </Typography>
            <List disablePadding>
                {contactDetails.map(contact => (
                    <ListItem disableGutters>
                        <ListItemIcon>
                            {contact.icon}
                        </ListItemIcon>
                        <ListItemText primary={
                            <Typography color='textSecondary'>{contact.value ? contact.value : 'No data provided'}</Typography>
                        } />
                    </ListItem>
                ))}
            </List>
            <Dialog
                open={showEdit}
                onClose={() => setShowEdit(false)}
                fullWidth
            >
                <DialogTitle>Edit Contact Details</DialogTitle>
                <DialogContent>
                    <TextField label='Website URL' margin='normal' variant='outlined'
                        value={website} onChange={ev => setWebsite(ev.target.value)}
                        fullWidth />
                    <PhoneInput phoneNumber={phoneNumber} onChange={handlePhoneInput} />
                    <TextField label='Email Address' margin='normal' variant='outlined'
                        value={email} onChange={ev => setEmail(ev.target.value)} fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowEdit(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={saveChanges} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <ErrorSnackbar open={error.length > 0} err={error} />
        </Box>
    )
}