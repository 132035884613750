import React from 'react';
import { Box, Typography } from '@material-ui/core';

export const BusinessInfo: React.FC<any> = props => {

    const { org } = props;

    return (
        <Box display='flex'>
            <Box>
                <Typography variant='h6'>
                    Contact
                </Typography>
                <Typography>{org.contactName}</Typography>
                <Typography>+{org.phoneNumber.number}</Typography>
                <Typography>{org.emailAddress}</Typography>
            </Box>
            <Box marginX={8}>
                <Typography variant='h6'>
                    Address
                </Typography>
                <Typography>{org.organizationAddress.addressLineOne}</Typography>
                <Typography>{org.organizationAddress.addressLineTwo}</Typography>
                <Typography>{org.organizationAddress.postCode}</Typography>
                <Typography>{org.organizationAddress.city}, {org.organizationAddress.countryCode}</Typography>
            </Box>
        </Box>
    )
}