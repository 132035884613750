import React, { useState } from 'react';
import { Typography, Button } from '@material-ui/core';
import { RegisterOrganizationRequest, SessionClient, StringUtils, AccountType } from '@shout-sdk/client-sdk';
import { AccountInfoForm } from './AccountInfoForm';
import { AddressForm } from './AddressForm';
import { useStyles } from './RegisterFormStyle';
import { ErrorSnackbar } from 'components/dashboard/common';

const initialState = new RegisterOrganizationRequest();
initialState.deviceId = StringUtils.generateUUID();
initialState.phoneNumber.countryCode = 44;

export const RegisterForm: React.FC<any> = props => {
    const classes = useStyles();
    const [user, setUser] = useState<RegisterOrganizationRequest>(initialState);
    const [err, setErr] = useState('');

    const register = async (event: any) => {
        event.preventDefault();
        const uasHttpClient = await SessionClient.getUasHttpClient();
        const reqBody = { ...user };
        reqBody.phoneNumber.number = Number('' + reqBody.phoneNumber.countryCode + Number(reqBody.phoneNumber.number));
        const phoneRes = await uasHttpClient.post<any>('/api/1/phone_number/validate', { accountType: AccountType.Organization, phoneNumber: { ...reqBody.phoneNumber } });
        if (!phoneRes.data.isValid) {
            setErr('Invalid phone number!'); return;
        }
        setErr('');
        const res = await uasHttpClient.post<any>('/api/1/registrations/organization', { ...reqBody });
        if (!res.ok()) {
            setErr(res.data.errors[0]); return;
        }
        props.next(); 
    }

    return (
        <form className={classes.container} onSubmit={register}>
            <div>
                <Typography variant="h6">Account Information</Typography>
                <AccountInfoForm user={user} setUser={setUser} />
                <Typography variant="h6" className={classes.header}>Address</Typography>
                <AddressForm user={user} setUser={setUser} />
            </div>
            <Button variant="contained" color="primary" type="submit" className={classes.button}>
                Sign Up
            </Button>
            <ErrorSnackbar open={err.length > 0} err={err} />
        </form>
    )
}