import React, { useState } from 'react';
import { List, ListItem, ListItemAvatar, Avatar, ListItemText, makeStyles, Divider, ListItemSecondaryAction, IconButton, Menu, MenuItem } from '@material-ui/core';
import { GroupMemberDetails, GroupMemberRole, SessionClient } from '@shout-sdk/client-sdk';
import MoreVertIcon from '@material-ui/icons/MoreVert';

interface IProps {
    members: GroupMemberDetails[],
    groupId: number
}

const useStyles = makeStyles(theme => ({
    container: {
        maxHeight: 400,
        overflow: 'auto'
    }
}))

export const MembersList: React.FC<IProps> = props => {
    const classes = useStyles();
    const [showOptions, setShowOptions] = useState(0);
    const { members, groupId } = props;

    const removeUser = async (id: number) => {
        const uasClient = await SessionClient.getUasClient();
        const res = await uasClient.delete(`/api/1/groups/${groupId}/members/${id}`);
        if (res.ok()) { window.location.reload(); }
    }

    const changeRole = async (member: GroupMemberDetails) => {
        const emailAddress = member.privateEmail;
        const role = isAdmin(member.role) ? GroupMemberRole.Normal : GroupMemberRole.Admin;
        const uasClient = await SessionClient.getUasClient();
        const res = await uasClient.post(`/api/1/groups/${groupId}/members`, { members: [{ emailAddress, role }] });
        if (res.ok()) { window.location.reload(); }
    }

    const isAdmin = (role: GroupMemberRole): boolean => {
        return role === GroupMemberRole.Admin;
    }

    return (
        <List className={classes.container}>
            {members.map((member, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <Divider />}
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar src={member.thumbnailImageUrl!} />
                        </ListItemAvatar>
                        <ListItemText primary={member.name} secondary={member.privateEmail} />
                        <ListItemSecondaryAction>
                            <IconButton id={`${member.userId}`} onClick={() => setShowOptions(member.userId)}>
                                <MoreVertIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Menu
                        anchorEl={document.getElementById(`${member.userId}`)}
                        open={showOptions === member.userId}
                        onClose={() => setShowOptions(0)}
                    >
                        <MenuItem onClick={() => changeRole(member)}>
                            {isAdmin(member.role) ? 'Remove' : 'Give'} Admin Rights
                        </MenuItem>
                        <MenuItem onClick={() => removeUser(member.userId)}>
                            Remove User
                        </MenuItem>
                    </Menu>
                </React.Fragment>
            ))}
        </List>
    )
}