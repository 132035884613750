import React, { useState } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField } from '@material-ui/core';
import { IProps } from './UserProfileProps';
import { SessionClient, UpdateProfileRequest, Optional } from '@shout-sdk/client-sdk';
import { ErrorSnackbar } from 'components/dashboard/common';
import { CurrentUser } from 'auth';

export const UserDescription: React.FC<IProps> = props => {

    const { user, edit } = props;
    const [description, setDescription] = useState(user.description);
    const [showEdit, setShowEdit] = useState(false);
    const [error, setError] = useState('');

    const saveChanges = async () => {
        if (description === user.description) return;
        const uasClient = await SessionClient.getUasClient();
        const request = new UpdateProfileRequest();
        request.description = new Optional(description!);
        const res = await uasClient.patch<any>(`/api/1/profile/${user.userId}`, request);
        if (res.ok()) {
            await CurrentUser.set();
            window.location.reload();
        } else {
            setError(res.data.errors[0]);
        }
    }

    return (
        <Box>
            <Typography variant='h5' gutterBottom>Description
                {edit && <Button color='primary' onClick={() => setShowEdit(true)}>EDIT</Button>}
            </Typography>
            <Typography variant='body1' color='textSecondary'>{user.description}</Typography>
            <Dialog
                open={showEdit}
                onClose={() => setShowEdit(false)}
                fullWidth
            >
                <DialogTitle>Edit Description</DialogTitle>
                <DialogContent>
                    <TextField label='Edit Description' variant='outlined' value={description} 
                        onChange={ev => setDescription(ev.target.value.length === 0 ? null : ev.target.value)} multiline fullWidth />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowEdit(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={saveChanges} color="primary" autoFocus>
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
            <ErrorSnackbar open={error.length > 0} err={error} /> 
        </Box>
    )
}