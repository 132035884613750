import React from 'react';
import { Layout } from '../Layout';
import { Box, Typography, makeStyles, Button } from '@material-ui/core';
import ShopIcon from '@material-ui/icons/Shop';
import AppleIcon from '@material-ui/icons/Apple';

const useStyles = makeStyles(theme => ({
    elem: {
        marginTop: theme.spacing(4)
    }
}))

export const DownloadAppView: React.FC<any> = props => {
    const classes = useStyles();
    return (
        <Layout>
            <Box margin={10} textAlign="center">
                <Typography variant="h5" className={classes.elem}>
                    Thank you for signing up!
                </Typography>
                <Typography variant="subtitle1" className={classes.elem}>
                    If you don't have a business account, you can only use our mobile app for now.
                </Typography>
                <a style={{ textDecoration: 'none' }} rel="noopener noreferrer" 
                    href='https://play.google.com/store/apps/details?id=com.shout_app.shout' target='_blank'>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.elem}
                        startIcon={<ShopIcon />}
                        fullWidth
                    >
                        Google Play Store
                    </Button>
                </a>
                <a style={{ textDecoration: 'none' }} rel="noopener noreferrer" 
                    href='https://apps.apple.com/gb/app/shout-local-discovery-app/id1451730764' target='_blank'>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.elem}
                        startIcon={<AppleIcon />}
                        fullWidth
                    >
                        App Store
                    </Button>
                </a>
                <Typography variant="h5" className={classes.elem}>
                    Download now!
                </Typography>
            </Box>
        </Layout>
    )
}