import React from 'react';
import { Box, Button, CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    wrapper: {
        position: 'relative',
        display: 'inline'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const LoadingButton: React.FC<any> = props => {
    const classes = useStyles();
    const { loading } = props;

    return (
        <Box className={classes.wrapper}>
            <Button
                {...props}
                disabled={loading || props.disabled}
                loading=''
            >
                {props.children}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Box>
    )
}