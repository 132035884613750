import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import bgImg from '../../assets/login_bg.jpg';
import logo from '../../assets/logo_black.gif';

const useStyles = makeStyles(theme => ({
    image: {
        backgroundImage: `url(${bgImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    container: {
        padding: theme.spacing(4),
        overflow: 'auto',
        height: '100vh'
    },
    logo: {
        height: 100,
        marginBottom: theme.spacing(4),
        display: 'block',
        margin: '0 auto'
    }
}));

export const Layout: React.FC<any> = props => {
    const classes = useStyles();

    return (
        <Grid container component="main">
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.container}>
                    <img src={logo} className={classes.logo} alt="" />
                    {props.children}
                </div>
            </Grid>
        </Grid>
    );
}